import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RoleService, Roles } from "../role.service";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator statu
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: string[];
}

interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  roles?: string[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  constructor(private roleService: RoleService) {
    roleService.Roles$.subscribe((Roles: Roles) => {
      let filtered = this.iconMenu
        .filter((item: IMenuItem) => {
          if (item["roles"] && Array.isArray(item["roles"])) {
            return Roles.hasAnyRoles(item["roles"]);
          } else {
            return true;
          }
        })
        .map((item: IMenuItem) => {
          if (item["sub"] && item["sub"].length > 0) {
            item["sub"] = item["sub"].filter((sub) => {
              if (sub["roles"] && Array.isArray(sub["roles"])) {
                return Roles.hasAnyRoles(sub["roles"]);
              } else {
                return true;
              }
            });
          }
          return item;
        });
      this.menuItems.next(filtered);
    });
  }

  defaultMenu: IMenuItem[] = [];
  separatorMenu: IMenuItem[] = [];
  iconMenu: IMenuItem[] = [
    {
      type: "separator",
      name: "Main Items",
    },
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
    },
    {
      name: "Jobs",
      type: "link",
      tooltip: "Jobs",
      icon: "build",
      state: "service",
      roles: ["operation", "administrator"],
    },
    {
      name: "PPM",
      type: "link",
      tooltip: "PPM",
      icon: "access_time",
      state: "ppm",
      roles: ["operation", "administrator"],
    },
    {
      name: "Asset",
      type: "link",
      tooltip: "Asset",
      icon: "location_city",
      state: "asset",
      roles: ["operation", "administrator"],
    },
    {
      name: "Visits",
      type: "link",
      tooltip: "Visits",
      icon: "calendar_today",
      state: "visit",
    },
    {
      name: "Job Calendar",
      type: "link",
      tooltip: "Job Calenda",
      icon: "today",
      state: "planner",
    },
    {
      name: "Scheduler",
      type: "link",
      tooltip: "Scheduler",
      icon: "event_note",
      state: "scheduler",
    },
    {
      name: "Map",
      type: "link",
      tooltip: "Map",
      icon: "location_on",
      state: "map",
    },
    {
      name: "Certificate",
      type: "link",
      tooltip: "Paper",
      icon: "list_alt",
      state: "paper",
    },
    {
      name: "Quotations",
      type: "link",
      tooltip: "Quotations",
      icon: "assignment",
      state: "quotation",
      roles: ["surveyor", "administrator"],
    },
    {
      name: "Purchase Orders",
      type: "link",
      tooltip: "Purchase Orders",
      icon: "list_alt",
      state: "purchase-order",
      roles: ["surveyor", "administrator"],
    },
    {
      name: "Inventory",
      type: "link",
      tooltip: "Inventory",
      icon: "store",
      state: "inventory",
    },
    {
      name: "Finance",
      type: "dropDown",
      tooltip: "Finance",
      icon: "description",
      state: "",
      sub: [
        { name: "Awaiting Costs", state: "finance/awaiting-costs" },
        { name: "Pending Submission", state: "finance/pending-submission" },
        { name: "Application", state: "application" },
        { name: "Certificates", state: "invoice" },
      ],
      roles: ["financier", "administrator"],
    },
    {
      name: "Timesheet",
      type: "link",
      tooltip: "Timesheet",
      icon: "timelapse",
      state: "timesheet",
    },
    {
      name: "Report",
      type: "link",
      tooltip: "Report",
      icon: "slow_motion_video",
      state: "report",
    },
    {
      name: "SETTINGS",
      type: "dropDown",
      tooltip: "",
      icon: "settings",
      state: "",
      sub: [
        { name: "General", state: "settings/general" },
        { name: "Import", state: "settings/import" },
        // { name: "Asset", state: "settings/asset" },
        { name: "Company", state: "settings/company" },
        { name: "User", state: "settings/user" },
        { name: "Building", state: "settings/building" },
        { name: "Discipline", state: "settings/discipline" },
        { name: "Translation", state: "settings/translation", roles: ["administrator"],},
      ],
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon statu item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can destroy this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user statu.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.defaultMenu);
    }
  }
}
