import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ServiceCustomizeService } from "@app/shared/components/common/service-customize.service";
import { CompanyService } from "@shared/services/api/company.service";
import { ModelAttributeOptionService } from "@shared/services/api/model-attribute-option.service";
import { PopupService } from "@shared/services/popup.service";
import { tableConfigs } from "@shared/config";
import { ServiceService } from "@shared/services/api/service.service";
import { ServiceModel } from "@shared/models/service.model";
import * as _ from "lodash";
import { BuildingModel } from "@shared/models/building.model";
import { BuildingService } from "@shared/services/api/building.service";
import { SimpleReuseStrategy } from "@app/simple-reuse-strategy";
import { addHours, addDays, addMonths } from "date-fns";
import { ServiceExtraService } from "@app/shared/components/common/service-extra.service";
import { ActivatedRoute } from "@angular/router";
import { AppLoaderService } from "@app/shared/services/system/app-loader/app-loader.service";
import { AppConfirmService } from "@app/shared/services/system/app-confirm/app-confirm.service";
import { NotifyService } from "@app/shared/services/notify.service";
import { tap, map } from "rxjs/operators";
import { TimeConvertService } from "@shared/services/time-convert.service";
import { SettingsService } from "@shared/services/system/settings.service";
import { PpmService } from "@app/shared/services/api/ppm.service";
import { AssetService } from "@app/shared/services/api/asset.service";

@Component({
  selector: "app-service-create",
  templateUrl: "./service-create.component.html",
  providers: [
    SimpleReuseStrategy,
    ServiceCustomizeService,
    ServiceExtraService,
  ],
})
export class ServiceCreateComponent implements OnInit, OnDestroy {
  tableConfig = tableConfigs.service;
  _ = _;
  @Input() service_type = null;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public secondFormGroup: FormGroup = new FormGroup({
    previous_service_id: new FormControl(""),
  });
  public formGroup: FormGroup = new FormGroup({
    client_id: new FormControl(null, Validators.required),
    building_id: new FormControl(null, Validators.required),
    subject: new FormControl(""),
    problem: new FormControl("", Validators.required),
    budget: new FormControl(0),
    service_type: new FormControl(null, Validators.required),
    discipline: new FormControl(null, Validators.required),
    severity: new FormControl(""),
    supplier_company_ids: new FormControl([]),
    engineers: new FormControl([]),
    status: new FormControl("pending", Validators.required),
    ppm_id: new FormControl(null),
    asset_ids: new FormControl(null),
    expect_start_time: new FormControl("", Validators.required),
    quote_required_service_id: new FormControl(0),
    data: new FormGroup({
      caller: new FormControl(""),
      caller_phone: new FormControl(""),
      nominal_code: new FormControl(),
      client: new FormGroup({
        client_reference: new FormControl(""),
        service_reference: new FormControl(""),
      }),
      room_id: new FormControl(null),
    }),
  });

  public suppliers = [];
  public rooms = [];
  public engineers = [];
  public ppms = [];
  public assets = [];
  public filterServices: ServiceModel[] = [];
  public filterServicesTemp: ServiceModel[] = [];
  public building: BuildingModel;
  loadingPreviousService = false;
  public buildings$;
  public loadingBuildings = false;
  public countBuildings = 0;

  public get contractors() {
    return this.companyService.contractors;
  }

  public loadingContractors = false;
  public assignCompanies = [];

  constructor(
    public customizeService: ServiceCustomizeService,
    public companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    public popup: PopupService,
    public serviceService: ServiceService,
    public ppmService: PpmService,
    public assetService: AssetService,
    public buildingService: BuildingService,
    public extraService: ServiceExtraService,
    private route: ActivatedRoute,
    private loader: AppLoaderService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private timeConvertService: TimeConvertService,
    private settings: SettingsService
  ) {}

  ngOnInit() {
    this.loadingContractors = true;
    this.buildItemForm();
    this.getRouteParam();
  }

  getRouteParam() {
    let paramMap = this.route.snapshot.paramMap;
    let id = +paramMap.get("id");
    let type = paramMap.get("type");
    if (type === "create_quote_request" && id) {
      this.formGroup.patchValue({
        quote_required_service_id: id,
        service_type: "quote_request",
        client_id: +paramMap.get("client_id"),
        building_id: +paramMap.get("building_id"),
        subject: paramMap.get("subject"),
        problem: paramMap.get("problem"),
        discipline: paramMap.get("discipline"),
        supplier_company_ids: paramMap
          .get("supplier_company_ids")
          .split(",")
          .map((i) => parseInt(i)),
      });
    }
  }

  ngOnDestroy(): void {}

  buildItemForm() {
    if (this.service_type) {
      this.formGroup.patchValue({
        service_type: this.service_type,
      });
    }
    this.formGroup.get("building_id").valueChanges.subscribe((building_id) => {
      if (building_id) {
        if (!this.formGroup.value.quote_required_service_id) {
          this.getServicesByBuildingId(building_id);
        }
        this.getBuilding(building_id);
      }
      if (this.formGroup.value.service_type !== "ppm") {
        this.getAssets(building_id);
      } else {
        this.getPPMs(building_id);
      }
    });
    this.formGroup
      .get("supplier_company_ids")
      .valueChanges.subscribe((supplier_company_ids) => {
        if (supplier_company_ids.length > 0) {
          this.getEngineers(supplier_company_ids);
        }
      });
    this.formGroup
      .get("service_type")
      .valueChanges.subscribe((service_type) => {
        if (service_type === "reactive") {
          this.getDefaultBudget(this.formGroup.value.client_id);
        }

        if (service_type === "ppm") {
          this.getPPMs(this.formGroup.value.building_id);
        } else {
          this.getAssets(this.formGroup.value.building_id);
        }
      });
    this.formGroup
      .get("severity")
      .valueChanges.subscribe((severity: string) => {
        let now = new Date();
        let retDate;
        if (severity.indexOf("hours") !== -1) {
          retDate = addHours(
            now,
            parseInt(severity.slice(0, severity.length - 6))
          );
        }
        if (severity.indexOf("days") !== -1) {
          retDate = addDays(
            now,
            parseInt(severity.slice(0, severity.length - 5))
          );
        }
        if (severity.indexOf("months") !== -1) {
          retDate = addMonths(
            now,
            parseInt(severity.slice(0, severity.length - 7))
          );
        }
        if (retDate) {
          this.formGroup.patchValue({
            expect_start_time: retDate.toISOString(),
          });
        }
      });
    this.formGroup.get("client_id").valueChanges.subscribe((client_id: any) => {
      if (client_id) {
        if (+client_id !== +this.formGroup.value.client_id) {
          this.formGroup.patchValue({ building_id: "" });
        }
        this.loadingBuildings = true;
        this.buildings$ = this.serviceService.buildingsByClient(client_id).pipe(
          map((items: any) => {
            return items.filter((item) => item.status === "active");
          }),
          tap((items: any) => {
            this.loadingBuildings = false;
            this.countBuildings = items.length;
          })
        );
      }
    });
    this.formGroup.valueChanges.subscribe((service) => {
      if (
        service["discipline"] &&
        service["service_type"] &&
        service["client_id"]
      ) {
        const clientId = service["client_id"];
        const discipline = service["discipline"];
        const serviceType = service["service_type"];
        let codeSetting = _.get(
          this.settings.settings,
          "discipline_nominal_code"
        );
        if (codeSetting && codeSetting["company_id"] === clientId) {
          if (
            codeSetting["data"][discipline] &&
            codeSetting["data"][discipline][serviceType]
          ) {
            this.formGroup
              .get("data.nominal_code")
              .patchValue(codeSetting["data"][discipline][serviceType], {
                emitEvent: false,
              });
          }
        }
      }
    });
  }

  getBuilding(buildingId) {
    let loader = this.loader.open();
    this.buildingService
      .show(buildingId)
      .finally(() => loader.close())
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, "data.rooms", []);
      });
  }

  getPPMs(building_id) {
    if (building_id) {
      let loader = this.loader.open();
      this.ppmService
        .readByBuilding(building_id)
        .finally(() => loader.close())
        .subscribe((data) => {
          this.ppms = _.get(data, "ppms", []);
        });
    } else {
      this.ppms = [];
    }
  }

  getAssets(building_id) {
    if (building_id) {
      let loader = this.loader.open();
      this.assetService
        .byBuildingId(building_id)
        .finally(() => loader.close())
        .subscribe((data: any) => {
          this.assets = data;
        });
    } else {
      this.assets = [];
    }
  }

  getServicesByBuildingId(buildingId) {
    this.loadingPreviousService = true;
    this.serviceService
      .filterServicesByBuilding(buildingId)
      .finally(() => (this.loadingPreviousService = false))
      .subscribe((data: ServiceModel[]) => {
        this.filterServices = data;
        this.filterServicesTemp = data;
      });
  }

  submit() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let formGroupValue = this.formGroup.value;
        formGroupValue["expect_start_time"] = this.timeConvertService.convert(
          this.formGroup.controls["expect_start_time"].value
        );
        formGroupValue["data"]["extra"] = this.extraService.all();
        formGroupValue["data"]["customize"] = this.customizeService.allObject();
        formGroupValue["previous_service_id"] =
          this.secondFormGroup.value.previous_service_id;
        let loader = this.loader.open();
        this.serviceService
          .store(formGroupValue)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.onSubmitted.emit();
            SimpleReuseStrategy.deleteRouteSnapshot("//service/create");
            this.customizeService.clean();
            this.extraService.clear();
            this.serviceService.refresh();
            this.toast.show("Service Added!");
          });
      }
    });
  }

  getDefaultBudget(clientId) {
    this.serviceService.getDefaultBudget(clientId).subscribe((budget) => {
      this.formGroup.patchValue({ budget: budget });
    });
  }

  openEditServicePopup(serviceId) {
    this.popup
      .openServiceEditPage(serviceId)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getServicesByBuildingId(this.formGroup.value.building_id);
        }
      });
  }

  recall(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id,
    });
    this.formGroup.patchValue({
      supplier_company_ids: service.supplier_company_ids,
      subject: service.subject, // string
      problem: service.problem, // string
      budget: service.budget, // number
      service_type: service.service_type, // option
      discipline: service.discipline, // option
      severity: service.severity, // option
      data: {
        client: {
          client_reference: _.get(service, "data.client.client_reference"),
        },
      },
    });
    this.extraService.setFormGroup(_.get(service, "data.extra", {}), {
      recall: true,
    });
    this.customizeService.setByObject(_.get(service, "data.customize", {}));
    stepper.next();
  }


  quote(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id,
    });
    this.formGroup.patchValue({
      supplier_company_ids: service.supplier_company_ids,
      subject: service.subject, // string
      problem: service.problem, // string
      budget: service.budget, // number
      service_type: null, // option
      discipline: service.discipline, // option
      severity: service.severity, // option
      data: {
        client: {
          client_reference: _.get(service, "data.client.client_reference"),
        },
      },
    });
    this.extraService.setFormGroup(_.get(service, "data.extra", {}), {});
    this.customizeService.setByObject(_.get(service, "data.customize", {}));
    stepper.next();
  }

  warranty(service: ServiceModel, stepper) {
    this.secondFormGroup.patchValue({
      previous_service_id: service.id,
    });
    this.formGroup.patchValue({
      supplier_company_ids: service.supplier_company_ids,
      subject: service.subject, // string
      problem: service.problem, // string
      budget: service.budget, // number
      service_type: service.service_type, // option
      discipline: service.discipline, // option
      severity: service.severity, // option
      data: {
        client: {
          client_reference: _.get(service, "data.client.client_reference"),
        },
      },
    });
    this.extraService.setFormGroup(_.get(service, "data.extra", {}), {
      warranty: true,
    });
    this.customizeService.setByObject(_.get(service, "data.customize"));

    stepper.next();
  }

  filter($event) {
    const val = $event.toLowerCase();
    let temp = this.filterServicesTemp;
    if (val) {
      temp = this.filterServicesTemp.filter((item) => {
        let isFind = false;
        [
          "id",
          "service_type",
          "discipline",
          "create_user_name",
          "status",
          "created_at",
        ].forEach((element) => {
          if (item[element].toString().toLowerCase().indexOf(val) !== -1) {
            isFind = true;
          }

          if (
            item[element]
              .toString()
              .toLowerCase()
              .replace("_", " ")
              .indexOf(val) !== -1
          ) {
            isFind = true;
          }
        });
        return isFind;
      });
    }
    this.filterServices = temp;
  }

  searchBuilding(term, item) {
    return (
      item.code
        .toString()
        .toLowerCase()
        .indexOf(term.toString().toLowerCase()) >= 0 ||
      item.name
        .toString()
        .toLowerCase()
        .indexOf(term.toString().toLowerCase()) >= 0
    );
  }

  searchRoom(term, item) {
    return (
      ("" + item.floor + "" + item.name)
        .toString()
        .toLowerCase()
        .indexOf(term.toString().toLowerCase()) >= 0
    );
  }

  changeAssignContractors($event) {
    this.assignCompanies = $event;
  }

  getEngineers(supplierCompanyIds) {
    let loader = this.loader.open();
    this.serviceService
      .readSupplierEngineers(supplierCompanyIds)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.engineers = data;
        const oldAssignEngineers = this.formGroup.get("engineers").value;
        const newAssignEngineers = this.engineers
          .map((engineer) => {
            return oldAssignEngineers.indexOf(engineer.id) > -1
              ? engineer.id
              : false;
          })
          .filter((id) => {
            return id;
          });
        this.formGroup.get("engineers").setValue(newAssignEngineers);
      });
  }

  filterDiscipline(term, item) {
    return (
      _.replace(item.option_value, "_", " ")
        .toString()
        .toLowerCase()
        .indexOf(term.toString().toLowerCase()) >= 0
    );
  }
}
