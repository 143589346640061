import {
  Component,
  EventEmitter,
  Injector,
  Input, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { ServiceService } from '@shared/services/api/service.service';
import { QuotationOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from '@shared/services/api/company.service';
import { PopupService } from '@shared/services/popup.service';
import { QuotationModel } from '@shared/models/quotation.model';
import * as _ from 'lodash';
import { QuotationService } from '@shared/services/api/quotation.service';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { tableConfigs } from '@shared/config';
import { ConfirmWithNoteService } from '@app/shared/services/system/confirm-with-note/confirm-with-note.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { ActivatedRoute } from '@angular/router';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { NotifyService } from '@app/shared/services/notify.service';
import { AppConfirmService } from '@app/shared/services/system/app-confirm/app-confirm.service';
import { isNull } from 'lodash';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html'
})
export class QuotationEditComponent implements OnInit, OnDestroy {
  tableConfig = tableConfigs.quotation;
  tableConfigs = tableConfigs;
  private onRefreshSub;
  public quotationItemDisplayColumns = [
    { display: 'Id', key: 'id', useColor: false },
    { display: 'Service Id', key: 'item_type_id', useColor: false },
    { display: 'Type', key: 'item_type', useColor: false },
    { display: 'Quantity', key: 'item_quantity', useColor: false },
    { display: 'Name', key: 'item_name', useColor: false },
    { display: 'Price', key: 'item_unit_price', useColor: false },
    { display: 'Markup (%)', key: 'item_unit_margin', useColor: false },
    { display: 'Total Price', key: 'item_total_price', useColor: false },
    { display: 'Status', key: 'item_status', useColor: true },
  ];
  public itemForm: FormGroup = new FormGroup({
    type: new FormControl(''),
    vat: new FormControl(0),
    source_company_id: new FormControl('', Validators.required), // supplier company
    status: new FormControl('', Validators.required),
    description: new FormControl(''),
  });
  public dataFormGroup: FormGroup = new FormGroup({
    service_id: new FormControl('', { updateOn: 'blur' }),
    out_of_hours_work_required: new FormControl('no'),
    we_require_another_discipline_contractor_for_these_works: new FormControl('no'),
    special_terms: new FormControl(''),
    client_reference: new FormControl(''),
    lead_in_time: new FormControl(''),
    work_duration: new FormControl(''),
    discipline: new FormControl({ value: '', disabled: true }),
    client_name: new FormControl({ value: '', disabled: true }),
    building_name: new FormControl({ value: '', disabled: true }),
    building_address: new FormControl({ value: '', disabled: true }),
  });
  public quotationOptions: QuotationOptions;

  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public item: QuotationModel;

  public get clients() {
    return this.companyService.clients;
  }

  public get suppliers() {
    return this.companyService.contractors;
  }

  public serviceIdIsValid = false;

  constructor(
    private serviceService: ServiceService,
    private quotationItemService: QuotationItemService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private popup: PopupService,
    public _service: QuotationService,
    private confirmWithNoteService: ConfirmWithNoteService,
    private layout: LayoutService,
    private route: ActivatedRoute,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private confirmService: AppConfirmService,
  ) {
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnInit() {
    if (this.inPopup) {
      //
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.getOptions();
    this.refresh();
    this.dataFormGroup.get('service_id').valueChanges.subscribe(change => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        this.serviceService
          .checkValid(change, 'quotation')
          .subscribe((data: any) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
            } else {
              this.serviceIdIsValid = false;
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  fillItemForm(item) {
    this.itemForm.patchValue(item);
    this.dataFormGroup.patchValue({
      service_id: _.get(item, 'data.service_id', ''),
      discipline: _.get(item, 'data.discipline', ''),
      out_of_hours_work_required: _.get(
        item,
        'data.out_of_hours_work_required',
        ''
      ),
      we_require_another_discipline_contractor_for_these_works: _.get(
        item,
        'data.we_require_another_discipline_contractor_for_these_works',
        ''
      ),
      special_terms: _.get(item, 'data.special_terms', ''),
      client_reference: _.get(item, 'data.client_reference', ''),
      lead_in_time: _.get(item, 'data.lead_in_time', ''),
      work_duration: _.get(item, 'data.work_duration', ''),
      client_name: _.get(item, 'data.client_name', ''),
      building_name: _.get(item, 'data.building_name', ''),
      building_address: _.get(item, 'data.building_address', '')
    });
  }

  getOptions() {
    this.maoService.all().subscribe((data: any) => {
      this.quotationOptions = data.quotation;
    });
  }

  submit() {
    let data = {
      ...this.itemForm.value,
      id: this.id,
      data: this.dataFormGroup.value
    };
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.toast.show('Quotation updated!');
        this.onSubmitted.emit();
        this.refresh();
      });
  }

  openClientReview() {
    this.popup
      .openQuotationClientReview(this.item.target_company_id, 'user')
      .afterClosed()
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .clientReview(this.id, { note: res.note, user_ids: res.userIds })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Create client review success.');
              this.refresh();
            });
        }
      });
  }

  openMailClientReview() {
    this.popup
      .openQuotationClientReview(this.item.target_company_id, 'email')
      .afterClosed()
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .mailClientReview(this.id, { note: res.note, emails: res.emails })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Create client review success.');
              this.refresh();
            });
        }
      });
  }

  approve() {
    this.confirmWithNoteService
      .show({ title: `Confirm approve this quotation?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .approve(this.id, { ...res })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Quotation approve success.');
              this.refresh();
            });
        }
      });
  }

  reject() {
    this.confirmWithNoteService
      .show({ title: `Confirm reject this quotation?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .reject(this.id, { ...res })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Quotation reject success.');
              this.refresh();
            });
        }
      });
  }

  generateService() {
    this.confirmWithNoteService
      .show({ title: `Confirm create service?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .generateService(this.id, { ...res })
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Create service success.');
              this.refresh();
            });
        }
      });
  }

  openEditServicePopUp(row) {
    this.popup
      .openServiceEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
      });
  }

  openQuotationItemCreatePopup() {
    this.popup
      .openQuotationItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refreshItem();
        }
      });
  }

  deleteItem(row) {
    this.confirmService
      .confirm({ message: `Delete ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.quotationItemService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Quotation item ${row.id} deleted!`);
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup
      .openQuotationItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refreshItem();
        }
      });
  }

  refresh() {
    this._service.show(this.id).subscribe((data: QuotationModel) => {
      this.item = data;
      this.fillItemForm(data);
    });
  }

  refreshItem() {
    this._service.show(this.id).subscribe((data: QuotationModel) => {
      this.item = data;
    });
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }

  getTotal(type = null) {
    if (this.item) {
      let items = this.item.quotation_items;
      if (type) {
        items = items.filter(item => item.item_type === type);
      }
      let subtotal = items
        .map(item => {
          let price = item.item_total_price;
          if (typeof price === 'string') {
            price = parseFloat(price);
          }
          return price;
        })
        .reduce((p, c) => {
          return p + c;
        }, 0);
      if (isNull(type)) {
        subtotal += this.item.vat;
      }
      return subtotal;
    } else {
      return null;
    }
  }

  openVatUpdatePopup() {
    this.popup
      .openVatCalculatePopup(this.item.subtotal, this.item.vat)
      .afterClosed()
      .subscribe(vatRes => {
        if (vatRes) {
          this.confirmService
            .confirm({ message: `Update vat ?` })
            .subscribe(confirmRes => {
              if (confirmRes) {
                let loader = this.loader.open();
                this._service
                  .updateVat(this.id, vatRes.vat)
                  .finally(() => loader.close())
                  .subscribe(() => {
                    this.refresh();
                    this.toast.show(`Quotation vat updated!`);
                  });
              }
            });
        }
      });

  }
}
