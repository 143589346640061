import { LaravelPageResponse } from "@shared/models/laravel-page-response.model";
import { AppTableComponent } from "@app/shared/components/system/table/table.component";
import { Paginate } from "./../../../../models/paginate.model";
import { PpmService } from "./../../../../services/api/ppm.service";
import { AssetModel } from "./../../../../models/asset.model";
import { CompanyService } from "@shared/services/api/company.service";
import { ModelAttributeOptions } from "@shared/models/options";
import { ServiceOptions } from "./../../../../models/options";
import { ModelAttributeOptionService } from "./../../../../services/api/model-attribute-option.service";
import { Subscription } from "rxjs";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { LaravelPageRequest } from "@shared/models/laravel-page-request.model";
import { AppLoaderService } from "@app/shared/services/system/app-loader/app-loader.service";
import { AppConfirmService } from "@app/shared/services/system/app-confirm/app-confirm.service";
import { get } from "lodash";
import { ServiceCustomizeService } from "@app/shared/components/common/service-customize.service";
import { ServiceExtraService } from "@app/shared/components/common/service-extra.service";
import { addHours, addDays, addMonths } from "date-fns";
import { TimeConvertService } from "@shared/services/time-convert.service";
import { ServiceService } from "@app/shared/services/api/service.service";
import { NotifyService } from "@app/shared/services/notify.service";

@Component({
  selector: "app-ppm-create",
  templateUrl: "./ppm-create.component.html",
  providers: [ServiceCustomizeService, ServiceExtraService],
})
export class PpmCreateComponent implements OnInit {
  assetTableDisplay = [
    { display: "Id", key: "id", useColor: false },
    { display: "Building Name", key: "building_name", useColor: false },
    { display: "Category", key: "category", useColor: false },
    { display: "Name", key: "name", useColor: false },
    { display: "Description", key: "description", useColor: false },
    { display: "Service Interval", key: "service_interval", useColor: false },
    { display: "Last Service Time", key: "last_service_time", useColor: false },
    { display: "Next Service Time", key: "next_service_time", useColor: false },
    { display: "Status", key: "ppm_status", useColor: true },
  ];
  formGroup: FormGroup = new FormGroup({
    subject: new FormControl(null),
    problem: new FormControl(null),
    severity: new FormControl(null),
    discipline: new FormControl(null),
    asset: new FormControl(null),
    budget: new FormControl(0, Validators.required),
    expect_start_time: new FormControl(null),
    supplier_company: new FormControl([]),
    engineers: new FormControl([]),
  });
  engineers = [];
  template = [];
  table_loading = true;
  public get_item_sub: Subscription;
  public page: Paginate = new Paginate();
  asset_ids = [];
  public client_id = null;
  items: AssetModel[] = [];
  serviceOptions: ServiceOptions;
  assets: AssetModel[] = [];
  ppm: any;

  public get supplierCompanies() {
    return this.companyService.contractors;
  }

  @ViewChild(AppTableComponent) assetTable: AppTableComponent;
  assetTableConfig = {
    loading$: false,
    size: 10,
    keyword: "",
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _service: PpmService,
    public serviceService: ServiceService,
    public loader: AppLoaderService,
    public _confirm: AppConfirmService,
    public dialog_ref: MatDialogRef<PpmCreateComponent>,
    private mao: ModelAttributeOptionService,
    private companyService: CompanyService,
    public customizeService: ServiceCustomizeService,
    public extraService: ServiceExtraService,
    public timeConvertService: TimeConvertService
  ) {
    this.page.size = 10;
    this.page.pageNumber = 1;
    this.ppm = this.data.ppm;
    this.asset_ids = this.data.asset_ids;
    this.client_id = this.data.client_id;
    this.setPage({ offset: 0 });
  }

  ngOnInit() {
    this.getAssets();
    this.getOptions();
    this.formGroup
      .get("severity")
      .valueChanges.subscribe((severity: string) => {
        let now = new Date();
        let retDate;
        if (severity.indexOf("hours") !== -1) {
          retDate = addHours(
            now,
            parseInt(severity.slice(0, severity.length - 6))
          );
        }
        if (severity.indexOf("days") !== -1) {
          retDate = addDays(
            now,
            parseInt(severity.slice(0, severity.length - 5))
          );
        }
        if (severity.indexOf("months") !== -1) {
          retDate = addMonths(
            now,
            parseInt(severity.slice(0, severity.length - 7))
          );
        }
        /*if (retDate) {
        this.formGroup.patchValue({
          expect_start_time: retDate.toISOString()
        })
      }*/
      });

    this.formGroup
      .get("supplier_company")
      .valueChanges.subscribe((supplier_company_ids) => {
        if (supplier_company_ids.length > 0) {
          this.getEngineers(supplier_company_ids);
        }
      });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    const request = new LaravelPageRequest(
      this.page.size,
      this.page.pageNumber + 1,
      {},
      "",
      { asset_ids: this.asset_ids, ppm: this.ppm }
    );

    this.get_item_sub = this._service
      .searchPPMServices(request.request())
      .subscribe((data: any) => {
        this.table_loading = false;
        this.page = {
          size: data.per_page,
          totalElements: data.total,
          totalPages: data.last_page,
          pageNumber: data.current_page - 1,
        };
        this.items = data.data;
      });
  }

  clearTemplate() {
    this.template = [];
  }

  resetForm() {
    this.formGroup.reset();
  }

  save() {
    let formGroupValue = this.formGroup.value;

    this._confirm
      .confirm({ message: `Adding these PPM service,confirm please.` })
      .subscribe((res) => {
        if (res) {
          let loader = this.loader.open();
          // formGroupValue['expect_start_time'] = this.timeConvertService.convert(formGroupValue['expect_start_time']);
          this._service
            .store({
              ...formGroupValue,
              ppm_id: this.ppm.id,
              client_id: this.client_id,
              extra: this.extraService.all(),
              customize: this.customizeService.allObject(),
            })
            .finally(() => loader.close())
            .subscribe((data: any) => {
              this.dialog_ref.close(true);
            });
        }
      });
  }

  getOptions() {
    this.mao.all().subscribe((result: ModelAttributeOptions) => {
      this.serviceOptions = result.service;
    });
  }

  getAssets() {
    let loader = this.loader.open();
    this._service
      .index(
        new LaravelPageRequest(
          this.assetTableConfig.size,
          this.assetTable.paginator.pageNumber + 1,
          {},
          "",
          { ids: this.asset_ids }
        )
      )
      .finally(() => loader.close())
      .subscribe((response: LaravelPageResponse) => {
        this.assets = response.data;
        this.assetTable.setPaginator(
          response.total,
          response.per_page,
          response.current_page - 1
        );
        this.updateBudget(this.assets);
      });
  }

  deleteAsset($event) {
    this.assets = this.assets.filter((item) => item.id !== $event.id);
    this.asset_ids = this.asset_ids.filter((item) => item !== $event.id);
    this.updateBudget(this.assets);
  }

  loadData() {
    this.formGroup.controls["asset"].setValue(this.asset_ids);
    if (this.template.length > 0) {
      const service = this.template[0];
      this.formGroup.controls["subject"].setValue(service.subject);
      this.formGroup.controls["problem"].setValue(service.problem);
      this.formGroup.controls["severity"].setValue(service.severity);
      this.formGroup.controls["discipline"].setValue(service.discipline);
      this.formGroup.controls["budget"].setValue(service.budget);
      this.extraService.setFormGroup(get(service, "data.extra", {}), {
        avoid_group: ["invoice"],
      });
      this.customizeService.setByObject(get(service, "data.customize", {}));
      const company_ids = [];
      service.company_service_relations.map((row: any) => {
        company_ids.push(row.company_id);
      });
      this.formGroup.controls["supplier_company"].setValue(company_ids);
    } else {
      let service_template = get(this.ppm, 'data.service_template', null);
      if (service_template) {
        this.formGroup.controls["subject"].setValue(service_template.subject);
        this.formGroup.controls["problem"].setValue(service_template.problem);
        this.formGroup.controls["severity"].setValue(service_template.severity);
        this.formGroup.controls["engineers"].setValue(service_template.engineers);
        this.formGroup.controls["discipline"].setValue(service_template.discipline);
        this.formGroup.controls["budget"].setValue(service_template.budget);
        this.extraService.setFormGroup({ compliance: service_template.compliance }, {
          avoid_group: ["invoice"],
        });
        this.formGroup.controls["supplier_company"].setValue(service_template.supplier_company_ids);
      }
    }
  }

  close() {
    this.dialog_ref.close(false);
  }

  updateBudget(items) {
    let budget = items.reduce((a, b) => {
      return a + get(b, "data.budget.default", 0);
    }, 0);
    this.formGroup.patchValue({ budget: budget });
    this.formGroup.controls["asset"].setValue(this.asset_ids);
  }

  getEngineers(supplierCompanyIds) {
    let loader = this.loader.open();
    this.serviceService
      .readSupplierEngineers(supplierCompanyIds)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.engineers = data;
        const oldAssignEngineers = this.formGroup.get("engineers").value;
        const newAssignEngineers = this.engineers
          .map((engineer) => {
            return oldAssignEngineers.indexOf(engineer.id) > -1
              ? engineer.id
              : false;
          })
          .filter((id) => {
            return id;
          });
        this.formGroup.get("engineers").setValue(newAssignEngineers);
      });
  }
}
