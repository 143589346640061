import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {AssetModel} from '@shared/models/asset.model';
import {Observable} from 'rxjs';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';
import {AssetOptions, ModelAttributeOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Conditions} from '@shared/models/conditions.model';

@Injectable()
export class AssetService extends ApiBaseService {
  route_prefix = 'assets';
  tableConfig: TableConfigModel = tableConfigs.asset;
  options: AssetOptions;
  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    category: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Category'
    }
  };

  constructor(
    private mao: ModelAttributeOptionService,
    public router: Router,
    public http: HttpClient,
  ) {
    super(http, router);
    this.mao.all().subscribe((data: ModelAttributeOptions) => {
      this.options = data.asset;
      this.conditions.status.items = data.asset.data_groups.service_status;
      this.conditions.category.items = data.asset.category;
    });
  }

  public byBuildingId(buildingId): Observable<[AssetModel] | Object> {
    return this.http.get(`${this.route_prefix}/by-building-id/${buildingId}`);
  }

  public readPPMAssets(ppm): Observable<[AssetModel] | Object> {
    return this.http.get(`${this.route_prefix}/by-ppm`, {params: {ppm_id: ppm.id}});
  }

  public readAssets(buildingId, category): Observable<[AssetModel] | Object> {
    return this.http.get(`${this.route_prefix}/by-building-and-category/${buildingId}/${category}`);
  }

  refreshOptions() {
    this.mao.all(true).subscribe((data: ModelAttributeOptions) => {
      this.options = data.asset;
      this.conditions.category.items = data.asset.category;
    });
  }
}
