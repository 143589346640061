import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-vat-calculate-popup',
  templateUrl: './vat-calculate-popup.component.html',
  styleUrls: ['./vat-calculate-popup.component.scss']
})
export class VatCalculatePopupComponent  implements OnInit {
  percent = new FormControl(20);
  vat = 0;
  total = 0;
  subtotal = 0;

  constructor(
    public dialogRef: MatDialogRef<VatCalculatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { subtotal: number, vat: number },
  ) {
    this.vat = data.vat;
    this.subtotal = data.subtotal;
    if (this.vat == 0) {
      console.log(111);
      this.vat = Math.round(this.subtotal * this.percent.value) / 100; 
    } else {
      console.log(333);
      this.percent.patchValue(null);
    }
    this.total = this.vat + this.subtotal;
    this.percent.valueChanges.subscribe((value) => {
      console.log(222);
      this.vat = Math.round(this.subtotal * value) / 100;
      this.total = this.vat + this.subtotal; 
    })
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close({ vat: this.vat});
  }

}
