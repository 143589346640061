import {GlobalSettingService} from './../global-setting.service';
import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {environment} from '@env/environment';

@Injectable()
export class AuthGuard implements CanActivate {

  private isAuthenticated = false; // Set this value dynamically

  constructor(
    private router: Router,
    private globalSettingService: GlobalSettingService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.getToken();
    if (this.isAuthenticated) {
      return true;
    }
    this.router.navigate(['/sessions/signin']);
    return false;
  }

  isLogin() {
    this.getToken();
    if (this.isAuthenticated) {
      return true;
    }
    return false;
  }

  getToken(): string {
    let authToken = this.globalSettingService.getConfig('token');
    if (authToken) {
      this.isAuthenticated = true;
    }
    return authToken;
  }

  signout() {
    this.isAuthenticated = false;
    this.globalSettingService.clearConfig();
    this.router.navigate(['/sessions/signin']);
  }

  appendHeaders() {
    let headers = {
      'Authorization': 'bearer ' + this.getToken(),
      'Page-Start-Timestamp': this.globalSettingService.getConfig('pageStartTimestamp', '').toString().substring(0, 10),
      'Page-End-Timestamp': this.globalSettingService.getConfig('pageEndTimestamp', '').toString().substring(0, 10),
      'use-company-id': this.globalSettingService.getConfig('useCompanyId'),
      'web-type': environment.type,
      'filter-client': this.globalSettingService.getConfig('client', ''),
      'filter-contractors': this.globalSettingService.getConfig('contractors', []).join()
    };
    Object.keys(headers).map((key) => {
      if (typeof headers[key] === 'number') {
        headers[key] = '' + headers[key];
      }
      if (headers[key] === null) {
        delete headers[key];
      }
    });
    return headers;
  }
}
