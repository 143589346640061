import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import 'rxjs-compat/add/operator/finally';
import {CompanyModel} from '@shared/models/company.model';
import {UserService} from '@shared/services/api/user.service';
import {CompanyService} from '@shared/services/api/company.service';
import {NoteService} from '@shared/services/api/note.service';

@Component({
  selector: 'app-confirm-with-note',
  template: // html
      `
    <h1 mat-dialog-title translate>{{data?.title}}</h1>
    <div mat-dialog-content *ngIf="data?.message">{{ data?.message }}</div>
    <div fxLayout="row wrap" fxLayout.lt-sm="column"  class="p-0" [formGroup]="form">
      <div fxFlex="100">
        <mat-form-field class="full-width">
          <textarea matInput [formControl]="form.controls['note']" placeholder="Note" rows="5"></textarea>
        </mat-form-field>
      </div>
      <!--target company-->
      <div fxFlex="100" class="pr-1">
        <ng-select
          [items]="companies"
          placeholder="Target Companies ({{companies?.length}})"
          formControlName="target_company"
          appendTo="body"
          bindValue="id"
          bindLabel="name"
          [multiple]="true"
          [virtualScroll]="true"
        >
          <ng-template ng-option-tmp let-item="item">
            <span>{{item?.name | translate}}</span><br>
            <span class="text-muted"
                  *ngIf="item?.alias && item?.name !== item?.alias"><b>Alias:</b> {{item?.alias}}</span>
          </ng-template>
        </ng-select>
      </div>
      <!--target user-->
      <div fxFlex="100" class="pr-1">
        <ng-select
          [items]="companies"
          placeholder="User Companies ({{companies?.length}})"
          formControlName="user_company_ids"
          appendTo="body"
          bindValue="id"
          bindLabel="name"
          [multiple]="true"
          [virtualScroll]="true"
        >
          <ng-template ng-option-tmp let-item="item">
            <span>{{item?.name | translate}}</span><br>
            <span class="text-muted"
                  *ngIf="item?.alias && item?.name !== item?.alias"><b>Alias:</b> {{item?.alias}}</span>
          </ng-template>
        </ng-select>
      </div>
      <div fxFlex="100" class="pr-1">
        <ng-select
          [items]="users"
          [loading]="usersLoading"
          placeholder="Target Users ({{users?.length}})"
          formControlName="target_user"
          appendTo="body"
          bindValue="id"
          bindLabel="name"
          [multiple]="true"
          [virtualScroll]="true"
        >
          <ng-template ng-option-tmp let-item="item">
            <span>{{item?.name | translate}}</span><br>
            <span class="text-muted" *ngIf="item?.email"><b>Email:</b> {{item?.email}}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div mat-dialog-actions>
      <div fxFlex></div>
      <button mat-raised-button (click)="dialogRef.close()" color="warn" translate>
        <mat-icon>close</mat-icon>
        Close
      </button>
      <button mat-raised-button (click)="submit()" color="primary" translate>
        <mat-icon>check</mat-icon>
        OK
      </button>
    </div>
  `
})
export class ConfirmWithNoteComponent implements OnInit {

  public users = [];

  public get companies(): CompanyModel[] {
    return this.companyService.managementCompanies;
  };

  public userAllSub;
  public usersLoading = false;

  form = new FormGroup({
    note: new FormControl(''),
    user_company_ids: new FormControl([]),
    target_user: new FormControl([]),
    target_company: new FormControl([])
  });

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithNoteComponent>,
    private _user: UserService,
    private _service: NoteService,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; message?: string; },
  ) {
  }

  ngOnInit(): void {
    this.form.get('user_company_ids').valueChanges.subscribe((user_company_ids) => {
      if (this.userAllSub) {
        this.userAllSub.unsubscribe();
      }
      this.usersLoading = true;
      this.userAllSub = this._user.all({company_ids: user_company_ids})
        .finally(() => this.usersLoading = false)
        .subscribe((data: any) => {
          this.users = data;
        });
    });
  }

  submit() {
    this.dialogRef.close({...this.form.value});
  }
}

