import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { tableConfigs } from '@shared/config';
import { CompanyService } from '@shared/services/api/company.service';
import { InterfaceBaseEdit } from '@shared/interfaces/interface-base-edit';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { FileUploader } from 'ng2-file-upload';
import { UploaderService } from '@shared/services/uploader.service';
import { SettingService } from '@shared/services/api/setting.service';
import { CompanyOptions } from '@app/shared/models/options';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.company;
  @Input() id;

  public regions = [];
  public disciplines = [];
  public service_type = [];
  public response_time = [];
  public working_hours = [];
  public rate_start_points = [];
  public rate_config_types = [];
  public time_units = [];
  public object_units = [];
  public inclusive_types = [];
  public company_document_types = [];
  public documents = [];
  public uploader: FileUploader = this.uploaderService.get({ url: this._service.uploadUrl(), itemAlias: 'file', autoUpload: true });
  public companyOption: CompanyOptions;
  public categories = [
    { option_value: 'product' },
    { option_value: 'service' },
  ];
  public clients: any[] = [];

  constructor(public injector: Injector,
    public mao: ModelAttributeOptionService,
    public _service: CompanyService,
    public uploaderService: UploaderService,
    public _settings: SettingService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.show();
    this.setUploadEvent();
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any[]) => {
        this.item = data;
        this.itemForm.patchValue(data);
      });
  }

  public itemForm: FormGroup = new FormGroup({
    type: new FormControl([], [Validators.required]),
    name: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    alias: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    data: new FormGroup({
      trading_name: new FormControl(''),
      website: new FormControl(''),
      fax: new FormControl(''),
      contact_person: new FormControl(''),
      contact_person_email: new FormControl(''),
      contact_person_mobile: new FormControl(''),
      contact_person_job_title: new FormControl(''),
      contact_person_telephone: new FormControl(''),
      no_of_directly_employed_staff: new FormControl(''),
      ratio_directly_employed_staff: new FormControl(''),
      employers_liability_cover: new FormControl(''),
      employers_liability_renewal_date: new FormControl(''),
      employers_liability_policy_number: new FormControl(''),
      employers_liability_name_of_insurer: new FormControl(''),
      public_liability_cover: new FormControl(''),
      public_liability_cover_renewal_date: new FormControl(''),
      public_liability_cover_policy_number: new FormControl(''),
      public_liability_cover_name_of_insurer: new FormControl(''),
      third_party_liability_cover: new FormControl(''),
      third_party_liability_cover_renewal_date: new FormControl(''),
      third_party_liability_cover_policy_number: new FormControl(''),
      third_party_liability_cover_name_of_insurer: new FormControl(''),
      insurance_additional_comments: new FormControl(''),
      area_geographical_covered: new FormGroup({
        regions: new FormControl([]),
        additional_comments: new FormControl(''),
      }),

      fatality_past_year_1: new FormControl(''),
      major_injury_past_year_1: new FormControl(''),
      minor_incidents_past_year_1: new FormControl(''),
      fatality_past_year_2: new FormControl(''),
      major_injury_past_year_2: new FormControl(''),
      minor_incidents_past_year_2: new FormControl(''),
      fatality_past_year_3: new FormControl(''),
      major_injury_past_year_3: new FormControl(''),
      minor_incidents_past_year_3: new FormControl(''),
      issued_improvement_prohibition_notice: new FormControl(''),

      company_reg_no: new FormControl(''),
      year_commenced_trading: new FormControl(''),
      registered_office_address: new FormControl(''),
      bank_name: new FormControl(''),
      bank_address: new FormControl(''),
      account_no: new FormControl(''),
      sort_code: new FormControl(''),
      utr_no: new FormControl(''),
      ni_number: new FormControl(''),
      vat_registration_no: new FormControl(''),
      finance_additional_comments: new FormControl(''),

      have_written_health_safety_policy: new FormControl(''),
      have_environmental_policy: new FormControl(''),
      provide_formal_health_safety_training_for_your_employee: new FormControl(''),
      have_formal_procedure_for_reporting_and_investigating_accidents: new FormControl(''),
      prepare_risk_assessment: new FormControl(''),
      prepare_method_statement: new FormControl(''),
      have_coshh_assessment: new FormControl(''),
      have_alcohol_drug_policy: new FormControl(''),
      have_equal_opportunity_policy: new FormControl(''),
      have_site_inspection_policy: new FormControl(''),
      health_safety_matters_communicated_to_your_staff: new FormControl(''),
      ensure_compliance_with_the_construction: new FormControl(''),
      have_documented_quality_management_system: new FormControl(''),
      hold_any_safety_accreditation: new FormControl(''),

      reference1_name: new FormControl(''),
      reference1_organisation: new FormControl(''),
      reference1_contact_telephone: new FormControl(''),
      reference1_date_work_carry_out: new FormControl(''),
      reference2_name: new FormControl(''),
      reference2_organisation: new FormControl(''),
      reference2_contact_telephone: new FormControl(''),
      reference2_date_work_carry_out: new FormControl(''),
      category: new FormControl([])
    }),
    client_ids: new FormControl([])
  });


  public documentForm: FormGroup = new FormGroup({
    type: new FormControl(''),
    name: new FormControl(''),
  });

  getOptions() {
    this.mao.all()
      .subscribe((data) => {
        this.regions = data.company.data_groups.regions;
        this.disciplines = data.service.discipline;
        this.service_type = data.service.service_type;
        this.response_time = data.service.data_groups.response_time;
        this.working_hours = data.service.data_groups.working_hour;
        this.rate_config_types = data.company_rate.data_groups.rate_config_type;
        this.rate_start_points = data.company_rate.data_groups.rate_start_point;
        this.rate_config_types = data.company_rate.data_groups.rate_config_type;
        this.time_units = data.company_rate.data_groups.time_unit;
        this.object_units = data.company_rate.data_groups.object_unit;
        this.inclusive_types = data.company_rate.data_groups.inclusive_type;
        this.company_document_types = data.document.type.filter((item) => {
          return item.option_name == 'company_document_type';
        });
        this.companyOption = data.company;
      });

    let loader = this.loader.open();
    this._service.readCompanyDocument(this.id)
      .finally(() => { loader.close() })
      .subscribe((res: any) => {
        this.documents = res;
      });

    this._service.getClients()
      .subscribe((data: any) => {
        this.clients = data;
      });
  }

  changeRegions($event, region) {
    let val = this.itemForm.get('data.area_geographical_covered.regions').value;
    if ($event.checked) {
      val.push(region);
    } else {
      let index = val.indexOf(region);
      val.splice(index, 1);
    }

    this.itemForm.get('data.area_geographical_covered.regions').setValue(val);
  }

  setUploadEvent() {
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = { ...fileItem.formData, company_id: this.id };
    };
    this.uploader.onAfterAddingFile = (fileItem) => {
      fileItem.formData = this.documentForm.value;
      fileItem.withCredentials = false;
    };

    let loader;
    this.uploader.onBeforeUploadItem = (fileItem => {
      loader = this.loader.open('Uploading...');
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Add success!');
        this.documents.push(JSON.parse(response));
        this.uploader.removeFromQueue(item);
      } else {
        this.toast.show('Add failed!');
      }
      loader.close();
    };
    this.uploader.onErrorItem = (item, response, status, header) => {
      console.log(item);
      console.log(response);
      console.log(status);
      console.log(header);
    };
  }

  submit() {
    let loader = this.loader.open();
    this._service.update(this.id, {
      ...this.itemForm.value
    })
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.toast.show('Company data update success!');
      });
  }

  deleteDocument(documentId, index) {
    let loader = this.loader.open();
    this._service.delCompanyDocument(documentId)
      .finally(() => {
        loader.close();
      })
      .subscribe((data: any) => {
        this.toast.show('Document deleted!');
        this.documents.splice(index, 1);
      });
  }

  disabledType(type) {
    return type === 'agent';
  }
}
