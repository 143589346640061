import { FormControl } from '@angular/forms';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html'
})
export class TableFilterComponent implements OnInit {

  keyword: FormControl = new FormControl('');
  @Output() onFilter = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  filter() {
    this.onFilter.emit(this.keyword.value);
  }

  clearFilter() {
    this.keyword.patchValue('');
    this.filter();
  }
}
