import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Conditions} from '@shared/models/conditions.model';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';
import {InvoiceOptions, ModelAttributeOptions} from '@shared/models/options';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {NotifyService} from '@shared/services/notify.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';

@Injectable()
export class InvoiceService {
  route_prefix = 'invoices';
  tableConfig: TableConfigModel = tableConfigs.invoice;
  options: InvoiceOptions;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Type'
    }
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private maoService: ModelAttributeOptionService,
    private toast: NotifyService,
    private loader: AppLoaderService,
    private confirm: AppConfirmService,
  ) {
    this.getOptions();
  }

  sendEmailToSupplier(invoiceId) {
    this.confirm.confirm().subscribe((res) => {
      if (res) {
        const loader = this.loader.open();
        this.http.post('invoices/send-email-to-supplier', {invoice_id: invoiceId})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Send email success');
          });
      }
    });

  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.invoice.status;
        this.conditions.type.items = data.invoice.type;
        this.options = data.invoice;
      });
  }

  cancel(id) {
    return this.http.post(this.route_prefix + '/' + id + '/cancel', {});
  }

  getSales(id) {
    return this.http.get('invoices/' + id + '/sales');
  }

  follow(id, follow) {
    return this.http.post(this.route_prefix + '/' + id + '/follow', {follow: follow});
  }


  index(params: LaravelPageRequest) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  changeConditionValue(key, value) {
    this.conditions[key].value = value;
  }

  gotoTable() {
    this.router.navigate([this.tableConfig.urlPrefix]);
  }
}
