import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Conditions} from '@shared/models/conditions.model';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ModelAttributeOptionService} from './model-attribute-option.service';
import {CompanyOptions, ModelAttributeOptions} from '@app/shared/models/options';
import {CompanyModel} from '@shared/models/company.model';
import {ReplaySubject} from 'rxjs';
import {environment} from '@env/environment';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

@Injectable()
export class CompanyService extends ApiBaseService {
  route_prefix = 'companies';
  tableConfig: TableConfigModel = tableConfigs.company;
  companyOptions: CompanyOptions;
  public managementCompanies: CompanyModel[] = [];
  public managementCompanies$ = new ReplaySubject(1);
  public contractors: CompanyModel[] = [];
  public contractors$ = new ReplaySubject(1);
  public clients: CompanyModel[] = [];
  public clients$ = new ReplaySubject(1);
  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  constructor(
    public http: HttpClient,
    public router: Router,
    public mao: ModelAttributeOptionService,
    private globalSettingService: GlobalSettingService,
  ) {
    super(http, router);
    this.getOptions();
    this.globalSettingService.useCompanyId$.subscribe((id) => {
      if (id) {
        this.indexAll().subscribe((data: any) => {
          this.managementCompanies = data;
          this.managementCompanies$.next(data);
        });
        this.getContractor().subscribe((data: any) => {
          this.contractors = data;
          this.contractors$.next(data);
        });
        this.getClients().subscribe((data: any) => {
          this.clients = data;
          this.clients$.next(data);
        });
      }
    });
  }

  getContractor() {
    return this.http.get('companies/contractors');
  }

  getClients() {
    return this.http.get('companies/clients');
  }

  getClientsRelationWithContractor(supplier_id) {
    return this.http.get('companies/client-ids-relation-with-supplier', {params: {supplier_id: supplier_id}});
  }

  getBuildingsByClientId(client_id) {
    return this.http.post(`companies/${client_id}/buildings`, {company_id: client_id});
  }

  indexAll() {
    return this.http.get(`companies/index-all`);
  }

  self() {
    return this.http.get('companies/self');
  }

  users(companyId) {
    return this.http.get(`companies/${companyId}/users`);
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data && data['company']) {
          this.conditions.status.items = data.company.status;
          this.companyOptions = data.company;
        }
      });
  }

  getCompanyRate(sourceCompanyId, targetCompanyId) {
    return this.http.get(`companies/rates?source_company_id=${sourceCompanyId}&target_company_id=${targetCompanyId}`);
  }

  saveCompanyRate(params) {
    return this.http.post(`companies/rates`, params);
  }

  deleteCompanyRate(params) {
    return this.http.post(`companies/rates`, params);
  }

  uploadUrl() {
    return environment.apiURL + environment.adminPrefix + 'companies/upload-document';
  }

  readCompanyDocument(companyId) {
    return this.http.get(`companies/${companyId}/documents`);
  }

  delCompanyDocument(documentId) {
    return this.http.delete(`companies/documents/${documentId}`);
  }

  createSupplier(data) {
    return this.http.post('companies/create-supplier', data);
  }
}
