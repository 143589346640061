import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-company-simple-create',
  templateUrl: './company-simple-create.component.html',
  styleUrls: ['./company-simple-create.component.scss']
})
export class CompanySimpleCreateComponent implements OnInit {

  public formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    alias: new FormControl(null),
    phone: new FormControl(null),
    email: new FormControl(null, [Validators.email]),
    full_address: new FormControl(null),
    client_id: new FormControl(null)
  });

  constructor(
    public dialogRef: MatDialogRef<CompanySimpleCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string; client_id: number; client_name: string }) {
    this.formGroup.get('client_id').patchValue(data.client_id);
  }

  ngOnInit(): void {
  }

}
