import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthGuard} from '@shared/services/system/auth/auth.guard';
import {DocumentService} from '@shared/services/api/document.service';
import {DocumentUploadData} from '@shared/models/document-upload-data.model';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {UploaderService} from '@shared/services/uploader.service';

@Component({
  selector: 'app-service-certificate',
  templateUrl: './app-service-certificate.component.html',
  styleUrls: ['./app-service-certificate.component.scss']
})
export class AppServiceCertificateComponent implements OnInit {
  public uploader: FileUploader;
  public itemForm: FormGroup;
  @Output() onSubmitted = new EventEmitter();
  @Input() submitFormData: DocumentUploadData;
  @Input() enable = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthGuard,
    private document: DocumentService,
    private toast: NotifyService,
    private loader: AppLoaderService,
    private uploaderService: UploaderService,
  ) {
  }

  ngOnInit() {
    this.setUploadEvent();
    this.buildItem();
  }

  setUploadEvent() {
    this.uploader = this.uploaderService.get({url: this.document.uploadUrl()});
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = {...this.itemForm.value, ...this.submitFormData};
    };
    let loader;
    this.uploader.onBeforeUploadItem = (fileItem => {
      loader = this.loader.open('Uploading...');
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Add success!');
        this.uploader.clearQueue();
        this.onSubmitted.emit();
      } else {
        this.toast.show('Add failed!');
      }
      loader.close();
    };
  }

  buildItem() {
    this.itemForm = this.fb.group({
      description: ['', Validators.required],
    });
  }

  add() {
    this.uploader.uploadAll();
  }
}
