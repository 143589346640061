import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Conditions} from '@shared/models/conditions.model';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';
import {HttpClient} from '@angular/common/http';
import {Router, RouterModule} from '@angular/router';
import {ModelAttributeOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';

@Injectable()
export class BuildingService extends ApiBaseService {
  route_prefix = 'buildings';
  tableConfig: TableConfigModel = tableConfigs.building;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  constructor(
    public http: HttpClient,
    public router: Router,
    private mao: ModelAttributeOptionService,
  ) {
    super(http, router);
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.building.status;
      });
  }
}
