import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {NoteService} from '@shared/services/api/note.service';
import {ModelAttributeOptions, NoteOptions} from '@app/shared/models/options';
import {ModelAttributeOptionService} from '@app/shared/services/api/model-attribute-option.service';
import {UserService} from '@shared/services/api/user.service';
import {CompanyModel} from '@shared/models/company.model';
import {CompanyService} from '@shared/services/api/company.service';

@Component({
  selector: 'app-note-edit-popup',
  templateUrl: './note-edit.component.html'
})
export class NoteEditPopupComponent implements OnInit {
  public id;
  public itemForm = new FormGroup({
    note: new FormControl('', [Validators.required]),
    severity: new FormControl(''),
    target_company: new FormControl([]),
    target_user: new FormControl([])
  });
  public inPopup = true;
  public item;
  public users = [];
  public get companies (): CompanyModel[] {
    return this.companyService.managementCompanies;
  };
  public noteOptions: NoteOptions;
  public usersLoading = false;

  constructor(
    private fb: FormBuilder,
    private _service: NoteService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _user: UserService,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<NoteEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mao: ModelAttributeOptionService
  ) { }

  ngOnInit() {
    this.id = this.data.id;
    this.show(this.id);
    this.mao
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.noteOptions = data.note;
      });
    this.usersLoading = true;
    this._user
      .all()
      .finally(() => this.usersLoading = false)
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  fillForm(item) {
    this.itemForm.patchValue({
      note: item.note,
      severity: item.severity,
      target_company: item.target_company_id,
      target_user: item.target_user_id
    });
  }


  show(id) {
    let loader = this.loader.open();
    this._service
      .show(id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillForm(this.item);
      });
  }

  submit() {
    let data = { ...this.itemForm.value };
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.snack.open('Note updated!', 'OK', { duration: 4000 });
        this.dialogRef.close(true);
      });
  }

  gotoTable() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  severityStyle(value) {
    return this._service.severityStyle(value);
  }
}
