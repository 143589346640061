import {QuotationClientReviewCreate} from './components/business/quotation/quotation-client-review-create.component';
import {PpmListComponent} from './components/business/ppm/ppm-list/ppm-list.component';
import {PpmDashboardComponent} from './components/business/ppm/ppm-dashboard/ppm-dashboard.component';
import {PpmCreateComponent} from '@shared/components/business/ppm/ppm-create/ppm-create.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatNativeDateModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatChipsModule, MatStepperModule, MatTabsModule, MatProgressBarModule, MatSlideToggleModule, MatExpansionModule, MatDividerModule
} from '@angular/material';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import {HeaderSideComponent} from './components/system/header-side/header-side.component';
import {SidebarSideComponent} from './components/system/sidebar-side/sidebar-side.component';

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import {HeaderTopComponent} from './components/system/header-top/header-top.component';
import {SidebarTopComponent} from './components/system/sidebar-top/sidebar-top.component';

// ONLY FOR DEMO (Removable without changing any layout configuration)
import {CustomizerComponent} from './components/system/customizer/customizer.component';

// ALL TIME REQUIRED
import {AdminLayoutComponent} from './components/system/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './components/system/layouts/auth-layout/auth-layout.component';
import {NotificationsComponent} from './components/system/notifications/notifications.component';
import {SidenavComponent} from './components/system/sidenav/sidenav.component';
import {BreadcrumbComponent} from './components/system/breadcrumb/breadcrumb.component';
import {AppComfirmComponent} from './services/system/app-confirm/app-confirm.component';
import {AppLoaderComponent} from './services/system/app-loader/app-loader.component';

// DIRECTIVES
import {FontSizeDirective} from './directives/font-size.directive';
import {ScrollToDirective} from './directives/scroll-to.directive';
import {AppDropdownDirective} from './directives/dropdown.directive';
import {DropdownAnchorDirective} from './directives/dropdown-anchor.directive';
import {DropdownLinkDirective} from './directives/dropdown-link.directive';

// PIPES
import {RelativeTimePipe} from './pipes/relative-time.pipe';
import {ExcerptPipe} from './pipes/excerpt.pipe';

// SERVICES
import {GlobalSettingComponent} from '@shared/components/system/global-setting/global-setting.component';
import {AppTableComponent} from '@shared/components/system/table/table.component';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {
  Md2AutocompleteModule,
  Md2DatepickerModule,
  MdNativeDateModule,
  NoConflictStyleCompatibilityMode
} from 'md2';
import {AppTableConditionComponent} from '@shared/components/system/table/condition/condition.component';
import {ServiceCreateComponent} from '@app/shared/components/business/service/service-create/service-create.component';
import {ServiceEditComponent} from '@shared/components/business/service/service-edit/service-edit.component';
import {PageCardHeaderComponent} from '@shared/components/system/page-card-header/page-card-header.component';
import {InvoiceEditComponent} from '@shared/components/business/invoice/invoice-edit/invoice-edit.component';
import {PurchaseOrderCreateComponent} from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create.component';
import {PurchaseOrderEditComponent} from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit.component';
import {QuotationCreateComponent} from '@shared/components/business/quotation/quotation-create/quotation-create.component';
import {QuotationEditComponent} from '@shared/components/business/quotation/quotation-edit/quotation-edit.component';
import {BuildingCreateComponent} from '@shared/components/business/building/building-create/building-create.component';
import {BuildingEditComponent} from '@shared/components/business/building/building-edit/building-edit.component';
import {CompanyCreateComponent} from '@shared/components/business/company/company-create/company-create.component';
import {CompanyEditComponent} from '@shared/components/business/company/company-edit/company-edit.component';
import {BaseCreateComponent} from '@shared/components/base/base-create.component';
import {QuotationItemCreatePopupComponent} from '@shared/components/business/quotation/quotation-item-create/quotation-item-create-popup.component';
import {ServiceEditPopupComponent} from '@shared/components/business/service/service-edit/service-edit-popup.component';
import {PurchaseOrderItemCreatePopupComponent} from '@shared/components/business/purchase-order/purchase-order-item-create/purchase-order-item-create-popup.component';
import {ApplicationEditComponent} from '@shared/components/business/application/application-edit/application-edit.component';
import {AppServiceCertificateComponent} from '@shared/components/business/app-service-certificate/app-service-certificate.component';
import {FileUploadModule} from 'ng2-file-upload';
import {ServiceCertificateVerifyComponent} from '@shared/components/business/service-certificate-verify/service-certificate-verify.component';
import {ServiceCertificateVerifyPopupComponent} from '@shared/components/business/service-certificate-verify/service-certificate-verify-popup.component';
import {ServiceDashboardComponent} from '@shared/components/business/service/service-dashboard/service-dashboard.component';
import {ServiceTableComponent} from '@shared/components/business/service/service-table/service-table.component';
import {ServiceTablePopupComponent} from '@shared/components/business/service/service-table/service-table-popup.component';
import {InvoiceItemCreatePopupComponent} from '@shared/components/business/invoice/invoice-item-create/invoice-item-create-popup.component';
import {ServiceVisitTableComponent} from '@shared/components/business/service-visit/service-visit-table/service-visit-table.component';
import {ServiceVisitEditPopupComponent} from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';
import {NoteEditPopupComponent} from '@shared/components/business/note/note-edit/note-edit-popup.component';
import {NoteCreatePopupComponent} from '@shared/components/business/note/note-create/note-create-popup.component';
import {NoteTableComponent} from '@shared/components/business/note/note-table/note-table.component';
import {NoteCreateComponent} from '@shared/components/business/note/note-create/note-create.component';
import {DocumentTableComponent} from '@shared/components/business/document/document-table/document-table.component';
import {DocumentEditPopupComponent} from '@shared/components/business/document/document-edit/document-edit-popup.component';
import {DocumentCreateComponent} from '@shared/components/business/document/document-create/document-create.component';
import {DocumentCreatePopupComponent} from '@shared/components/business/document/document-create/document-create-popup.component';
import {InvoiceItemEditPopupComponent} from '@shared/components/business/invoice/invoice-item-edit/invoice-item-edit-popup.component';
import {PurchaseOrderItemEditPopupComponent} from '@shared/components/business/purchase-order/purchase-order-item-edit/purchase-order-item-edit-popup.component';
import {QuotationItemEditPopupComponent} from '@shared/components/business/quotation/quotation-item-edit/quotation-item-edit-popup.component';
import {AssetCreateComponent} from '@shared/components/business/asset/asset-create/asset-create.component';
import {AssetEditComponent} from '@shared/components/business/asset/asset-edit/asset-edit.component';
import {AssetTableComponent} from '@shared/components/business/asset/asset-table/asset-table.component';
import {AssetEditPopupComponent} from '@shared/components/business/asset/asset-edit/asset-edit-popup.component';
import {ProgressComponent} from '@shared/components/system/progress/progress.component';
import {BaseEditComponent} from '@shared/components/base/base-edit.component';
import {AppSelectCompanyComponent} from '@shared/services/system/app-select-company/app-select-company.component';
import {QuotationDashboardComponent} from '@shared/components/business/quotation/quotation-dashboard/quotation-dashboard.component';
import {QuotationTableComponent} from '@shared/components/business/quotation/quotation-table/quotation-table.component';
import {QuotationTablePopupComponent} from '@shared/components/business/quotation/quotation-table/quotation-table-popup.component';
import {AssetCreatePopupComponent} from '@shared/components/business/asset/asset-create/asset-create-popup.component';
import {StrCheckboxComponent} from '@shared/components/system/str-checkbox/str-checkbox.component';
import {BuildingEditPopupComponent} from '@shared/components/business/building/building-edit/building-edit-popup.component';
import {ServiceVisitCreatePopupComponent} from '@shared/components/business/service-visit/service-visit-create/service-visit-create-popup.component';
import {ServiceVisitCreateComponent} from '@shared/components/business/service-visit/service-visit-create/service-visit-create.component';
import {PurchaseOrderEditPopupComponent} from '@shared/components/business/purchase-order/purchase-order-edit/purchase-order-edit-popup.component';
import {QuotationEditPopupComponent} from '@shared/components/business/quotation/quotation-edit/quotation-edit-popup.component';
import {QuotationCreatePopupComponent} from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import {PurchaseOrderCreatePopupComponent} from '@shared/components/business/purchase-order/purchase-order-create/purchase-order-create-popup.component';
import {HtmlPipe} from '@shared/pipes/html.pipe';
import {TableFilterComponent} from './components/system/table-filter/table-filter.component';
import {UpliftComponent} from './components/business/uplift/uplift.component';
import {ConfirmWithNoteComponent} from './services/system/confirm-with-note/confirm-with-note.component';
import {TimesheetVisitDetailComponent} from './services/system/timesheet-visit-detail/timesheet-visit-detail.component';
import {ServiceCustomizeDataCreateComponent} from './components/common/service-customize-data-create/service-customize-data-create.component';
import {ServiceCustomizeDataEditComponent} from './components/common/service-customize-data-edit/service-customize-data-edit.component';
import {ServiceExtraCreateComponent} from './components/common/service-extra-create/service-extra-create.component';
import {ServiceExtraEditComponent} from './components/common/service-extra-edit/service-extra-edit.component';
import {BuildingDetailCardComponent} from './components/common/building-detail-card/building-detail-card.component';
import {BuildingNotesCardComponent} from './components/common/building-notes-card/building-notes-card.component';
import {ApplicationItemCreateComponent} from './components/business/application/application-item-create/application-item-create.component';
import {
  ApplicationItemEditComponent,
  ApplicationItemReviewComponent
} from './components/business/application/application-item-edit/application-item-edit.component';
import {ApplicationItemTableComponent} from './components/business/application/application-item-table/application-item-table.component';
import {InvoiceEditPopupComponent} from './components/business/invoice/invoice-edit/invoice-edit-popup.component';
import {FinanceManagementTableComponent} from './components/business/service/service-dashboard/finance-management-table/finance-management-table.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgSelectModule} from '@ng-select/ng-select';
import {ColorShipComponent} from './components/system/color-ship/color-ship.component';
import {ContractorInfoCardComponent} from './components/business/service/contractor-info-card/contractor-info-card.component';
import {GalleryDialogComponent} from '@shared/components/business/gallery-dialog/gallery-dialog.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  MatDatepickerModule,
  MomentDateAdapter,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  Moment
} from '@coachcare/datepicker';
import {CompanyRateCardComponent} from './components/business/company/company-rate-card/company-rate-card.component';
import {CompanyRateTableComponent} from './components/business/company/company-rate-table/company-rate-table.component';
import {UserEditComponent} from '@shared/components/business/user/user-edit/user-edit.component';
import {UserCreateComponent} from '@shared/components/business/user/user-create/user-create.component';
import {
  ApplicationServiceDetailComponent,
} from '@app/views/application/application-service-detail/application-service-detail.component';
import {KeyValuePipe} from '@shared/pipes/key-value.pipe';
import {KPIItemDetailComponent, ServiceKpiDetailComponent} from './components/business/service-kpi-detail/service-kpi-detail.component';
import {UploaderService} from '@shared/services/uploader.service';
import {AppVisitCardComponent} from './components/business/app-visit-card/app-visit-card.component';
import { NoteItemCardComponent } from './components/base/note-item-card/note-item-card.component';
import { FormErrorPipe } from './pipes/form-error.pipe';
import { CompanySimpleCreateComponent } from './components/business/company-simple-create/company-simple-create.component';
import { DialogHeaderComponent } from './components/common/dialog-header/dialog-header.component';
import { SearchSelectComponent } from './components/system/search-select/search-select.component';
import { ServiceCreatePopupComponent } from './components/business/service/service-create/service-create-popup.component';
import { HolidayCreateComponent } from './components/business/holiday/holiday-create/holiday-create.component';
import { HolidayEditComponent } from './components/business/holiday/holiday-edit/holiday-edit.component';
import { HolidayApprovalComponent } from './components/business/holiday/holiday-approval/holiday-approval.component';
import { BulkPpmCreateComponent } from './components/business/ppm/bulk-ppm-create/bulk-ppm-create.component';
import { DashboardCompany1455Component } from './components/business/dashboard/dashboard-company1455/dashboard-company1455.component';
import { DashboardDefaultComponent } from './components/business/dashboard/dashboard-default/dashboard-default.component';
import {ChartModule} from 'angular-highcharts';
import drilldown from 'highcharts/modules/drilldown.src.js';
import * as Highcharts from 'highcharts';
import { ChangeTimesheetTimeComponent } from './services/system/change-timesheet-time/change-timesheet-time.component';
import { DashboardCompany1412Component } from './components/business/dashboard/dashboard-company1412/dashboard-company1412.component';
import { VatCalculatePopupComponent } from './components/common/vat-calculate-popup/vat-calculate-popup.component';
drilldown(Highcharts);

// import {Logger} from './services/logger.service';
/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  ChangeTimesheetTimeComponent,
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  CustomizerComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  GlobalSettingComponent,
  AppTableComponent,
  BaseTableComponent,
  AppTableConditionComponent,
  ServiceCreateComponent,
  ServiceEditComponent,
  PageCardHeaderComponent,
  InvoiceEditComponent,
  PurchaseOrderCreateComponent,
  PurchaseOrderEditComponent,
  QuotationCreateComponent,
  QuotationEditComponent,
  BuildingCreateComponent,
  BuildingEditComponent,
  CompanyCreateComponent,
  CompanyEditComponent,
  BaseCreateComponent,
  BaseEditComponent,
  ServiceEditPopupComponent,
  ServiceCreatePopupComponent,
  ApplicationEditComponent,
  AppServiceCertificateComponent,
  ServiceCertificateVerifyComponent,
  ServiceDashboardComponent,
  ServiceTableComponent,
  ServiceVisitTableComponent,
  NoteTableComponent,
  NoteCreateComponent,
  DocumentTableComponent,
  DocumentCreateComponent,
  AssetCreateComponent,
  AssetEditComponent,
  AssetTableComponent,
  ProgressComponent,
  QuotationDashboardComponent,
  QuotationTableComponent,
  StrCheckboxComponent,
  ServiceVisitCreateComponent,
  TableFilterComponent,
  UpliftComponent,
  PpmDashboardComponent,
  PpmListComponent,
  ServiceCustomizeDataCreateComponent,
  ServiceCustomizeDataEditComponent,
  ServiceExtraCreateComponent,
  ServiceExtraEditComponent,
  BuildingDetailCardComponent,
  BuildingNotesCardComponent,
  ApplicationItemTableComponent,
  ColorShipComponent,
  ContractorInfoCardComponent,
  GalleryDialogComponent,
  CompanyRateCardComponent,
  CompanyRateTableComponent,
  UserEditComponent,
  UserCreateComponent,
  ServiceKpiDetailComponent,
  AppVisitCardComponent,
  SearchSelectComponent,
  DashboardCompany1455Component,
  DashboardCompany1412Component,
  DashboardDefaultComponent
];

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  HtmlPipe,
  KeyValuePipe,
];

// dialog entryComponents
const popups = [
  AppComfirmComponent,
  AppLoaderComponent,
  QuotationItemCreatePopupComponent,
  ServiceEditPopupComponent,
  ServiceCreatePopupComponent,
  PurchaseOrderItemCreatePopupComponent,
  ServiceCertificateVerifyPopupComponent,
  ServiceTablePopupComponent,
  InvoiceItemCreatePopupComponent,
  ServiceVisitEditPopupComponent,
  NoteEditPopupComponent,
  NoteCreatePopupComponent,
  DocumentCreatePopupComponent,
  DocumentEditPopupComponent,
  InvoiceItemEditPopupComponent,
  PurchaseOrderItemEditPopupComponent,
  QuotationItemEditPopupComponent,
  AssetEditPopupComponent,
  AppSelectCompanyComponent,
  QuotationTablePopupComponent,
  AssetCreatePopupComponent,
  BuildingEditPopupComponent,
  ServiceVisitCreatePopupComponent,
  PurchaseOrderEditPopupComponent,
  QuotationEditPopupComponent,
  QuotationCreatePopupComponent,
  PurchaseOrderCreatePopupComponent,
  PpmCreateComponent,
  VatCalculatePopupComponent,
  QuotationClientReviewCreate,
  ConfirmWithNoteComponent,
  TimesheetVisitDetailComponent,
  ApplicationItemCreateComponent,
  ApplicationItemEditComponent,
  InvoiceEditPopupComponent,
  FinanceManagementTableComponent,
  GalleryDialogComponent,
  ApplicationServiceDetailComponent,
  KPIItemDetailComponent,
  ApplicationItemReviewComponent,
  CompanySimpleCreateComponent,
  HolidayCreateComponent,
  HolidayEditComponent,
  HolidayApprovalComponent,
  BulkPpmCreateComponent,
];

const imports = [
  CommonModule,
  FormsModule,
  RouterModule,
  FlexLayoutModule,
  TranslateModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatDividerModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  NgxDatatableModule,
  MatChipsModule,
  MatStepperModule,
  MatTabsModule,
  Md2DatepickerModule,
  MdNativeDateModule,
  MatProgressBarModule,
  Md2AutocompleteModule,
  FileUploadModule,
  MatSlideToggleModule,
  NoConflictStyleCompatibilityMode,
  MatExpansionModule,
  PerfectScrollbarModule,
  NgSelectModule,
  GalleryModule,
  NgxMatSelectSearchModule,
  ChartModule
];
export const MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    date: ['YYYY-MM-DD', 'YYYY/MM/DD', 'll'],
    datetime: ['YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm', 'll h:mma'],
    time: ['H:mm', 'HH:mm', 'h:mm a', 'hh:mm a']
  },
  display: {
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY HH:mm',
    time: 'HH:mm',
    dateA11yLabel: 'YY',
    monthDayLabel: 'MMM D',
    monthDayA11yLabel: 'MMMM D',
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    timeLabel: 'HH:mm'
  }
};

export class CustomAdapter extends MomentDateAdapter {
  format(date: Moment, displayFormat: string): string {
    date.toISOString = () => {
      return date.toLocaleString();
    };
    return super.format(date, displayFormat);
  }
}

@NgModule({
  imports: imports,
  entryComponents: popups,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: CustomAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    UploaderService,
  ],
  declarations: [
    ...classesToInclude,
    ...pipes,
    ...popups,
    NoteItemCardComponent,
    FormErrorPipe,
    DialogHeaderComponent,
    DashboardCompany1412Component,
    VatCalculatePopupComponent,
  ],
  exports: [
    ...classesToInclude,
    ...pipes,
    ...imports,
    FormErrorPipe
  ]
})
export class SharedModule {
}
