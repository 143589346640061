import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { FileUploader } from 'ng2-file-upload';
import { DocumentService } from '@shared/services/api/document.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { DocumentOptions, ModelAttributeOptions } from '@shared/models/options';
import { NotifyService } from '@app/shared/services/notify.service';
import { UploaderService } from '@shared/services/uploader.service';

@Component({
  selector: 'app-document-create',
  templateUrl: './document-create.component.html'
})
export class DocumentCreateComponent implements OnInit {
  @Input() id;  // type_id
  @Input() type;
  @Input() model;
  @Input() isPopup = false;
  @Output() onCreated = new EventEmitter();
  documentOptions: DocumentOptions;

  public uploader: FileUploader;
  public itemForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _service: DocumentService,
    private loader: AppLoaderService,
    private auth: AuthGuard,
    private toast: NotifyService,
    public mao: ModelAttributeOptionService,
    private uploaderService: UploaderService,
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.setUploadEvent();
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.documentOptions = data.document;
      });
  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required]
    });
  }

  setUploadEvent() {
    this.uploader = this.uploaderService.get({ url: this._service.uploadUrl() });
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = { ...this.itemForm.value, model: this.model, model_id: this.id };
    };
    let loader;
    this.uploader.onBeforeUploadItem = (fileItem => {
      loader = this.loader.open('Uploading...');
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.toast.show('Add success!');
        this.uploader.clearQueue();
        this.onCreated.emit(true);
      } else {
        this.toast.show('Add failed!');
      }
      loader.close();
    };
  }

  add() {
    if (!this.uploader.getNotUploadedItems().length) {
      let loader = this.loader.open('Uploading...');;
      this._service.uploadWithOutDocument({ ...this.itemForm.value, model: this.model, model_id: this.id })
        .finally(() => {
          loader.close();
        }).subscribe(() => {
          this.toast.show('Add success!');
          this.uploader.clearQueue();
          this.onCreated.emit(true);
        }, () => {
          this.toast.show('Add failed!');
        })

    } else {
      this.uploader.uploadAll();
    }

  }
}
