import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ModelAttributeOptions } from "@app/shared/models/options";
import { ModelAttributeOptionService } from "@app/shared/services/api/model-attribute-option.service";
import { UserService } from "@app/shared/services/api/user.service";
import { AppLoaderService } from "@app/shared/services/system/app-loader/app-loader.service";
import { TimeConvertService } from "@app/shared/services/time-convert.service";
import * as _ from "lodash";

@Component({
  selector: "app-holiday-edit",
  templateUrl: "./holiday-edit.component.html",
  styleUrls: ["./holiday-edit.component.scss"],
})
export class HolidayEditComponent implements OnInit {
  public id = null;
  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(null, Validators.required),
    note: new FormControl(""),
    type: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required),
    user_id: new FormControl(null, Validators.required),
    end_time: new FormControl(null, Validators.required),
    start_time: new FormControl(null, Validators.required),
  });
  public users = [];
  public types = [];
  public statuses = [];
  constructor(
    public loader: AppLoaderService,
    public userService: UserService,
    public maoService: ModelAttributeOptionService,
    private timeConvertService: TimeConvertService,
    public dialogRef: MatDialogRef<HolidayEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = this.data.id;
    this.readDetail();
  }

  ngOnInit() {
    this.initOptions();
  }

  initOptions() {
    let loader = this.loader.open();
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.types = data.user_holiday.type;
      this.statuses = data.user_holiday.status;
    });
    this.userService
      .all()
      .finally(() => loader.close())
      .subscribe((data) => {
        this.users = data;
      });
  }

  readDetail() {
    let loader = this.loader.open();
    this.userService
      .readHoliday({ id: this.id })
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.formGroup.patchValue(data.holiday);
      });
  }

  submit() {
    let loader = this.loader.open();
    let formGroupValue = this.formGroup.value;
    formGroupValue["end_time"] = this.timeConvertService.convert(
      this.formGroup.controls["end_time"].value
    );
    formGroupValue["start_time"] = this.timeConvertService.convert(
      this.formGroup.controls["start_time"].value
    );
    this.userService
      .updateHoliday(formGroupValue)
      .finally(() => loader.close())
      .subscribe((data) => {
        this.dialogRef.close(data);
      });
  }

  back() {
    this.dialogRef.close(false);
  }
}
