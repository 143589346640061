import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {KPIItem} from '@shared/services/api/application.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {NotifyService} from '@shared/services/notify.service';
import {ServiceKpiDetailService} from '@shared/components/business/service-kpi-detail/service-kpi-detail.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';

@Component({
  selector: 'app-service-kpi-detail',
  templateUrl: './service-kpi-detail.component.html',
  styleUrls: ['./service-kpi-detail.component.scss']
})
export class ServiceKpiDetailComponent implements OnInit {
  @Input() service_id;
  @Input() kpi: {
    attendance_sla: KPIItem;
    aims_app: KPIItem;
    compliance_certificates: KPIItem;
    emergency_job_with_2_hours_response_time: KPIItem;
    emergency_job_with_4_hours_response_time: KPIItem;
    first_time_completion: KPIItem;
    job_acceptance: KPIItem;
    multiple_visits: KPIItem
    no_use_app: KPIItem;
    ppm_service_sla: KPIItem;
    recall_and_warranty_services: KPIItem;
    service_sla: KPIItem;
  };
  @Output() change = new EventEmitter();

  constructor(
    private confirm: AppConfirmService,
    private toast: NotifyService,
    private serviceKpiDetailService: ServiceKpiDetailService,
    private dialog: MatDialog,
    private loader: AppLoaderService,
  ) {
  }

  applyKpiItem(key: string, row: KPIItem) {
    this.confirm.confirm().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.serviceKpiDetailService.applyKPI({service_id: this.service_id, kpi: {[key]: row}})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Apply KPI item success');
            this.change.emit();
          });
      }
    });
  }

  applyAllKPI() {
    this.confirm.confirm().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.serviceKpiDetailService.applyKPI({service_id: this.service_id, kpi: this.kpi})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Apply all KPI success');
            this.change.emit();
          });
      }
    });
  }

  showKpiItemDetail(key: string, row: KPIItem) {
    this.dialog.open(KPIItemDetailComponent, {
      width: '350px',
      data: {...row},
    }).afterClosed().subscribe((res) => {
      if (res || res === 0) {
        this.applyKpiItem(key, {...row, penalty: res});
      }
    });
  }

  ngOnInit() {
  }

}

@Component({
  selector: 'app-application-kpi-item-detail',
  template: `
    <h1 mat-dialog-title>{{data?.name}} ({{data?.compliance ? 'Compliance' : 'No Compliance'}})</h1>
    <div mat-dialog-content>
      <p>{{data?.description}}</p>
      <ng-container *ngFor="let otherItem of data?.other | keyValue">
        <mat-form-field class="full-width">
          <input matInput [value]="otherItem?.value" [placeholder]="otherItem.key | translate" disabled>
        </mat-form-field>
      </ng-container>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="data.penalty" [placeholder]="'penalty' | translate">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="dialogRef.close(false)">Cancel</button>
      <button mat-button (click)="submit()">Ok</button>
    </div>
  `,
})
export class KPIItemDetailComponent {
  constructor(
    public dialogRef: MatDialogRef<KPIItemDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KPIItem) {
    this.data.penalty = Math.round(this.data.penalty * 100) / 100;
  }

  submit() {
    this.dialogRef.close(this.data.penalty);
  }
}

