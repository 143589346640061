import { Component, OnInit, Inject, Injector, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import 'rxjs-compat/add/operator/startWith';
import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/switchMap';
import { environment } from '@env/environment';
import {AssetOptions, ModelAttributeOptions, ServiceVisitOptions} from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { parseDate } from '@shared/helpers/utils';
import { tableConfigs } from '@shared/config';
import { ServiceVisitService } from '@shared/services/api/service-visit.service';
import * as _ from 'lodash';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { NotifyService } from '@app/shared/services/notify.service';
import { AuditModel } from '@app/shared/models/audit.model';
import {TimeConvertService} from '@shared/services/time-convert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-service-visit-edit-popup',
  templateUrl: './service-visit-edit.component.html'
})
export class ServiceVisitEditPopupComponent implements OnInit, OnDestroy {
  tableConfig = tableConfigs.service_visit;
  tableConfigs = tableConfigs;
  _ = _;
  public id;
  public inPopup = true;
  public itemForm: FormGroup;
  public item;
  public service;
  public options: ServiceVisitOptions;
  public onRefreshSub;
  public sales = [];
  public audit: AuditModel;
  public assetOptions: AssetOptions;
  public assets = [];
  public asset_ids = new FormControl([]);

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ServiceVisitEditPopupComponent>,
    private maoService: ModelAttributeOptionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public injector: Injector,
    public _service: ServiceVisitService,
    public layout: LayoutService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private timeConvertService: TimeConvertService
  ) {
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnInit() {
    this.id = this.data.id;
    this.service = this.data.service;
    this.getOptions();
    this.buildItemForm({});
    this.show();
    this.getSales();
    this.getAudit();
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  getAudit() {
    this._service.audit(this.id).subscribe((audit: AuditModel) => {
      this.audit = audit;
    });
  }

  refresh() {
    this.show();
  }

  getSales() {
    this._service.sales(this.id).subscribe((sales: any) => {
      this.sales = sales;
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.service_visit;
        this.assetOptions = data.asset;
      });
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      check_in_time: [parseDate(item, 'check_in_time')],
      check_out_time: [parseDate(item, 'check_out_time')],
      service_status: [item.service_status || ''],
      note: [item.note || ''],
      observer: [item.observer || ''],
      rate_type: [item.rate_type || ''],
      log_type: [{ value: item.log_type || '', disabled: true }],
      created_at: [item.created_at || ''],
      signature: [item.signature || ''],
      check_in_latitude: [item.check_in_latitude || ''],
      check_in_longitude: [item.check_in_longitude || ''],
      check_out_latitude: [item.check_out_latitude || ''],
      check_out_longitude: [item.check_out_longitude || ''],
      data: [item.data || {}],
    });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.assets = this.item.data.assets ? this.item.data.assets : [];
        let ids = [];
        this.assets.forEach((asset) => {
          ids.push(asset.id);
        });
        this.asset_ids.setValue(ids);
        this.buildItemForm(this.item);
      });
  }

  submit() {
    let itemFormValue = this.itemForm.value;
    itemFormValue['check_in_time'] = this.timeConvertService.convert(itemFormValue['check_in_time']);
    itemFormValue['check_out_time'] = this.timeConvertService.convert(itemFormValue['check_out_time']);
    if (this.service.service_type.toLowerCase() === 'ppm') {
      itemFormValue['data']['assets'] = this.assets.map((asset) => {
        asset.notification_time = this.timeConvertService.convert(asset.notification_time);
        asset.next_service_time = this.timeConvertService.convert(asset.next_service_time);
        asset.last_service_time = this.timeConvertService.convert(asset.last_service_time);
        return asset;
      })
    }
    let data = { ...itemFormValue };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show('Visit updated!');
        this.dialogRef.close(true);
      });
  }

  gotoTable() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  getFullFileUrl(url) {
    return environment.fileUrl + url;
  }

  addAssetsCompliant($event) {
    $event.compliant_status = '';
    this.assets.push($event);
  }

  removeAssetsCompliant($event) {
    let index = 0;
    this.assets.forEach((asset, i) => {
      if (asset.id == $event.value.id) {
        index = i;
      }
    });
    this.assets.splice(index, 1);
  }

  changeCompliant(item, asset) {
    if (item === 'compliant') {
      let service_interval = '1 years';
      if (asset.hasOwnProperty('service_interval')) {
        service_interval = asset.service_interval;
      }
      let notification_period = 30;
      if (!asset.hasOwnProperty('service_interval')) {
        notification_period = 30;
      }

      let num_match = service_interval.match(/^\d+/gi);
      let str_match = service_interval.match(/[a-z|A-Z]+$/gi);
      let num = 1;
      let str = 'year';
      if (num_match.length>0 && str_match.length>0) {
        // num = parseInt(num_match[0]);
        // str = str_match[0];
      }

      asset.next_service_time = moment().add(num, 'year').format('YYYY-MM-DD HH:mm:ss');
      asset.last_service_time = moment().format('YYYY-MM-DD HH:mm:ss');
      asset.notification_time = moment().add(-notification_period, 'days').format('YYYY-MM-DD HH:mm:ss');
    }
  }

  clearAssetCompliant($event) {
    this.assets = [];
  }
}
