import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ServiceService } from '@app/shared/services/api/service.service';
import { NotifyService } from '@app/shared/services/notify.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { TimeConvertService } from '@app/shared/services/time-convert.service';
import { CompanyServiceRelationModel } from '@shared/models/company-service-relation.model';

@Component({
  selector: 'app-contractor-info-card',
  templateUrl: './contractor-info-card.component.html',
  styleUrls: ['./contractor-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractorInfoCardComponent implements OnInit, OnChanges {
  @Input() items: CompanyServiceRelationModel[] = [];
  formatItems = [];
  constructor(
    private loader: AppLoaderService,
    private toast: NotifyService,
    private cdr: ChangeDetectorRef,
    private serviceService: ServiceService,
    private timeConvertService: TimeConvertService,) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.formatItems = this.items.map((item) => {
      if (item.expect_start_time) {
        item.expect_start_time = new Date(item.expect_start_time).toISOString();
      } else {
        item.expect_start_time = '';
      }

      if (item.expect_end_time) {
        item.expect_end_time = new Date(item.expect_end_time).toISOString();
      } else {
        item.expect_end_time = '';
      }
      return item;
    });
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 618 * 2);
  }

  change() {
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 618 * 2);
  }

  update(item) {
    let loader = this.loader.open();
    item.expect_end_time = this.timeConvertService.convert(item.expect_end_time);
    item.expect_start_time = this.timeConvertService.convert(item.expect_start_time);
    this.serviceService.updateCompanyServiceRelation(item)
      .finally(() => {
        loader.close();
      })
      .subscribe(() => {
        this.toast.show('Updated!');
      });
  }
}
