import * as _ from 'lodash';

export function getIndexBy(array: Array<{}>, {name, value}): number {
  for (let i = 0; i < array.length; i++) {
    if (array[i][name] === value) {
      return i;
    }
  }
  return -1;
}

export function parseDate(obj, path, def = '') {
  let date = _.get(obj, path, def);
  if (date) {
    try {
      let dateObj = new Date(date);
      if (dateObj) {
        return dateObj.toISOString();
      }
      return date;
    } catch (e) {
      return date;
    }
  } else {
    return '';
  }
}

