import {Conditions} from '@shared/models/conditions.model';
import {Component, OnInit} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {LayoutService} from '@app/shared/services/system/layout.service';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {PpmService} from '@app/shared/services/api/ppm.service';
import {ThemeService} from '@shared/services/system/theme.service';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';

@Component({
  selector: 'app-ppm-dashboard',
  templateUrl: './ppm-dashboard.component.html',
  animations: egretAnimations
})
export class PpmDashboardComponent implements OnInit {
  public conditions: Conditions = this._service.conditions;
  dashboard = {
    ppm_to_log: null,
    upcoming_ppms: null,
    over_due_ppms_not_logged: null
  };

  constructor(
    private _layout: LayoutService,
    private loader: AppLoaderService,
    public theme: ThemeService,
    private _service: PpmService,
    private globalSettingService: GlobalSettingService,
  ) {
  }

  ngOnInit() {
    this.globalSettingService.config$.subscribe((config: GlobalSettingConfig) => {
      if (config.client && config.useCompanyId) {
        this.refresh();
      }
    });
  }

  toPPMTable(dashboard_type) {
    this.conditions.dashboard_type.value = dashboard_type;
    this._layout.layoutConf.switchTable = 'table';
  }

  initData() {
    let loader = this.loader.open();
    this._service.assetDashboard()
      .finally(() => loader.close())
      .subscribe((res: any) => {
        this.dashboard = res;
      });
  }

  refresh() {
    this.initData();
  }

}
