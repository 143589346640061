import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {LayoutService} from '@shared/services/system/layout.service';
import {GlobalSettingConfig} from '@app/shared/interfaces/global-setting-config';
import {get, set, has} from 'lodash';
import {CompanyModel} from '@app/shared/models/company.model';
import {subYears, addYears} from 'date-fns';
import {UserModel} from '@app/shared/models/user.model';
import {CacheService, CacheKeys} from '../cache.service';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingService {
  private cacheKey = CacheKeys.global_setting;
  // default settings
  public defaultConfig: GlobalSettingConfig = {
    apiUri: environment.apiURL + environment.adminPrefix,
    pageStartTimestamp: subYears(Date.now(), 2).getTime(),
    pageEndTimestamp: addYears(Date.now(), 2).getTime(),
    client: null,
    contractors: [],
    useCompanyId: null,
    useCompany: new CompanyModel,
    companies: [],
    user: new UserModel,
    token: '',
    first_page: 'dashboard',
  };
  public oldConfig: GlobalSettingConfig;
  public firstPageOptions = [];
  public config: GlobalSettingConfig;
  public config$ = new ReplaySubject(1);
  public useCompanyId$ = new ReplaySubject(1);

  constructor(
    private layout: LayoutService,
    private cache: CacheService,
  ) {
    this.initConfigByCache();
    this.config$.subscribe((config: GlobalSettingConfig) => {
      this.saveConfigToCache();
      this.saveConfigToServer();
      if (config['useCompanyId'] && config['useCompanyId'] !== this.oldConfig.useCompanyId) {
        this.useCompanyId$.next(config['useCompanyId']);
      }
    });
  }

  public saveConfigToCache() {
    this.cache.set(this.cacheKey, this.config);
  }

  public saveConfigToServer() {
    // this.publicService.setSetting({settings: this.config}).subscribe();
  }

  public initConfigByCache(config: GlobalSettingConfig = {}) {
    const oldConfig = {...this.config};
    // load config by cache
    if (Object.keys(config).length === 0) {
      this.config = this.cache.get(this.cacheKey, {...this.defaultConfig});
    } else {
      this.config = {...config};
    }
    this.publish(oldConfig);
    this.saveConfigToCache();
  }

  public clearConfig() {
    const oldConfig = {...this.config};
    this.cache.remove(this.cacheKey);
    this.config = {...this.defaultConfig};
    this.publish(oldConfig);
  }

  private publish(oldConfig) {
    this.oldConfig = oldConfig;
    this.config$.next(this.config);
  }

  public setConfig(path, value = null, options: { onEvent?: boolean } = {onEvent: true}) {
    const oldConfig = {...this.config};
    if (has(this.config, path)) {
      set(this.config, path, value);
      if (options.onEvent) {
        this.publish(oldConfig);
      }
    }
  }

  public publishConfig(lc: GlobalSettingConfig, onChange = true) {
    const oldConfig = {...this.config};
    this.config = Object.assign(this.config, lc);
    if (onChange) {
      this.publish(oldConfig);
    }
  }

  public getConfig(path, def: any = '') {
    return get(this.config, path, def);
  }
}
