import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-invoice-edit-popup',
  template: `    
    <div mat-dialog-title>
      <span>{{'Certificate' | translate}} {{id}}</span>
      <div fxFlex></div>
      <a matTooltip="Close">
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
      </a>
    </div>
    <app-invoice-edit mat-dialog-content [id]="id" [inPopup]="true" (onSubmitted)="dialogRef.close(true)" (onBack)="dialogRef.close()"></app-invoice-edit>`
})
export class InvoiceEditPopupComponent implements OnInit {
  public id;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<InvoiceEditPopupComponent>,
  ) {
    if (this.data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
  }
}
