import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationItemService {

  constructor(
    private http: HttpClient
  ) {
  }

  review(id, data: { item_total_price?: number; comment?: string; type: string }) {
    return this.http.post(`application-items/${id}/review`, data);
  }

  store(data) {
    return this.http.post('application-items', data);
  }

  show(id) {
    return this.http.get('application-items' + '/' + id);
  }

  detail(id) {
    return this.http.get(`application-items/${id}/detail`);
  }

  destroy(id) {
    return this.http.delete('application-items' + '/' + id);
  }

  update(id, data) {
    return this.http.put('application-items' + '/' + id, data);
  }

  all(params: { item_type?: string; item_type_id?: string; }) {
    return this.http.get('application-items', {params});
  }

  editSale(saleId, unitPrice, itemId) {
    return this.http.post('application-items/edit-sale', {sale_id: saleId, item_unit_price: unitPrice, application_item_id: itemId});
  }
}
