import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceVisitModel} from '@shared/models/service-visit.model';

@Component({
  selector: 'app-visit-card',
  templateUrl: './app-visit-card.component.html',
  styleUrls: ['./app-visit-card.component.scss']
})
export class AppVisitCardComponent implements OnInit {
  @Input() visit: ServiceVisitModel;
  @Input() useDelete = false;
  @Input() useEdit = false;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  delete() {
    this.onDelete.emit();
  }

  edit() {
    this.onEdit.emit();
  }

}
