import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { ThemeService } from '@shared/services/system/theme.service';
import { egretAnimations } from '@shared/animations/egret-animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import 'rxjs-compat/add/operator/finally';
import { LayoutService } from '@shared/services/system/layout.service';
import { NotifyService } from '@app/shared/services/notify.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-base-edit',
  template: ``,
  animations: egretAnimations
})
export class BaseEditComponent implements OnInit, OnDestroy {
  public id;
  public item;
  public inPopup = false;
  private onRefreshSub;

  // providers
  public theme: ThemeService;
  public router: Router;
  public route: ActivatedRoute;
  public confirmService: AppConfirmService;
  public loader: AppLoaderService;
  public toast: NotifyService;
  public layout: LayoutService;
  public timeConvertService: TimeConvertService;

  constructor(public injector: Injector) { }

  ngOnInit() {
    this.theme = this.injector.get(ThemeService);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.confirmService = this.injector.get(AppConfirmService);
    this.loader = this.injector.get(AppLoaderService);
    this.toast = this.injector.get(NotifyService);
    this.layout = this.injector.get(LayoutService);
    this.timeConvertService = this.injector.get(TimeConvertService);
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  gotoTable() {
    this.router.navigate([`/${this['tableConfig'].urlPrefix}`]);
  }

  show() { }

  refresh() {
    this.show();
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }
}
