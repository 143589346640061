import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LaravelPageRequest} from '@app/shared/models/laravel-page-request.model';
import {GlobalSettingService} from '../system/global-setting.service';
import {ServiceDashboardModel} from '@app/shared/models/service-dashboard.model';
import {set} from 'lodash';
import {dashboardConfigs} from '@app/shared/config';

@Injectable()
export class DashboardService {
  items: any[];
  serviceDashboardLoading = false;
  financeManagement;
  serviceDashboard: ServiceDashboardModel;
  serviceDisplays = dashboardConfigs.service;
  private subServiceDashboardArr = {};
  private subFinanceManagement;

  constructor(
    private http: HttpClient,
    private global: GlobalSettingService,
  ) {
  }

  initServiceDashboard() {
    if (!this.serviceDashboard) {
      this.loadServiceDashboard();
    }
  }

  refreshServiceDashboard() {
    this.loadServiceDashboard();
  }

  loadServiceDashboard() {
    this.serviceDashboard = new ServiceDashboardModel;
    this.serviceDashboardLoading = true;
    for (let group in this.serviceDisplays) {
      if (group) {
        this.serviceDisplays[group].items.map((item) => {
          let type = item.key;
          if (this.subServiceDashboardArr[group + '_' + type]) {
            this.subServiceDashboardArr[group + '_' + type].unsubscribe();
            delete this.subServiceDashboardArr[group + '_' + type];
          }
          this.subServiceDashboardArr[group + '_' + type] = this.getServiceDashboard(group, type)
            .finally(() => this.serviceDashboardLoading = false)
            .subscribe((data: number) => {
              set(this.serviceDashboard, [group, type], data);
            });
        });
      }
    }
    // load financeManagement
    if (this.global.getConfig('client')) {
      this.subFinanceManagement = this.getFinanceManagement()
        .subscribe((data: any) => {
          this.financeManagement = data;
        });
    }
  }

  cancelServiceDashboard() {
    if (this.subFinanceManagement) {
      this.subFinanceManagement.unsubscribe();
    }
    Object.keys(this.subServiceDashboardArr).map(key => {
      this.subServiceDashboardArr[key].unsubscribe();
      delete this.subServiceDashboardArr[key];
    });
  }

  getServiceDashboard(group = '', type = '') {
    return this.http.get('dashboard/service', {params: {group, type}});
  }

  getHomeDashboard() {
    return this.http.get('dashboard/home');
  }

  getQuotationDashboard() {
    return this.http.get('dashboard/quotation', {});
  }

  getFinanceManagement() {
    return this.http.get('dashboard/finance-management');
  }

  getFinanceManagementIndex(params: LaravelPageRequest) {
    return this.http.get('dashboard/finance-management/index', {params: params.request()});
  }
}
