import {UserModel} from '@shared/models/user.model';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyService} from '@shared/services/api/company.service';
import {tableConfigs} from '@shared/config';
import {PurchaseOrderService} from '@shared/services/api/purchase-order.service';
import {ActivatedRoute} from '@angular/router';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {AuthGuard} from '@shared/services/system/auth/auth.guard';
import {PopupService} from '@shared/services/popup.service';
import {ServiceService} from '@shared/services/api/service.service';
import {CompanyModel} from '@app/shared/models/company.model';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';
import {get} from 'lodash';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {NotifyService} from '@shared/services/notify.service';
import {MatDialog} from '@angular/material';
import {CompanySimpleCreateComponent} from '@shared/components/business/company-simple-create/company-simple-create.component';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-purchase-order-create',
  templateUrl: './purchase-order-create.component.html',
  providers: [SimpleReuseStrategy]
})
export class PurchaseOrderCreateComponent implements OnInit {
  tableConfig = tableConfigs.purchase_order;
  tableConfigs = tableConfigs;
  public purchaseOrderItemTableDisplayColumns = [
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Price', key: 'item_unit_price', useColor: false},
    {display: 'Markup (%)', key: 'item_unit_margin', useColor: false},
    {display: 'Total Price', key: 'item_total_price', useColor: false},
  ];
  @Input() inPopup = false;
  @Input() data: { serviceId: number | string; clientId: number | string; } = {serviceId: '', clientId: ''};
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public itemForm = new FormGroup({
    subject: new FormControl(''),
    description: new FormControl(''),
    status: new FormControl('', Validators.required),
    source_company_id: new FormControl(null, Validators.required),  // client
    target_company_id: new FormControl(null, Validators.required),  // supplier
    user_identity_user_id: new FormControl(null, Validators.required),
    service_id: new FormControl(null, { updateOn: 'blur', validators: [Validators.required] }),
    data: new FormGroup({
      date_of_order: new FormControl(new Date(), Validators.required),
      delivery_address: new FormControl(null),
      special_instructions: new FormControl(null),
      delivery_date: new FormControl(new Date()),
    }),
  });
  public itemFormGroup: FormGroup;

  public buildings;

  public purchaseOrderItems = [];

  public total = 0;
  public lineTotal = 0;
  public subtotal = 0;
  public vat = 0;
  public defaultMargin = 0;
  public useMargin = 0;
  public materials = [];

  public get clients() {
    return this.companyService.clients;
  }

  public serviceSuppliers: CompanyModel[] = [];
  public serviceIdIsValid = false;
  public authUser: UserModel;

  constructor(
    private fb: FormBuilder,
    private mao: ModelAttributeOptionService,
    private companyService: CompanyService,
    public purchaseOrderService: PurchaseOrderService,
    public route: ActivatedRoute,
    public auth: AuthGuard,
    public globalSetting: GlobalSettingService,
    public popup: PopupService,
    public serviceService: ServiceService,
    private http: HttpClient,
    private loader: AppLoaderService,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.authUser = this.globalSetting.getConfig('user');

    if (this.inPopup) {
      // in popup
    } else {
      this.data.serviceId = +this.route.snapshot.paramMap.get('serviceId');
    }
  }

  buildItemForm() {
    this.itemFormGroup = this.fb.group({
      product_id: [null],
      item_type: ['', Validators.required],
      item_name: ['', [Validators.required]],
      item_unit_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_quantity: [0, Validators.compose([Validators.required, Validators.min(0)])],
      item_unit_margin: [0, Validators.compose([Validators.required, Validators.min(0)])],
    });
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      this.useMargin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + this.useMargin / 100);
    });
    this.itemFormGroup.get('item_type').valueChanges.subscribe((change) => {
      this.useMargin = change === 'service' ? 0 : this.defaultMargin;
      this.itemFormGroup.patchValue({item_unit_margin: this.useMargin, product_id: null}, {emitEvent: false});
      this.materials = [];
    });
    this.itemForm.get('service_id').valueChanges.subscribe((change) => {
      if (!change) {
        this.serviceIdIsValid = false;
      } else {
        let loader = this.loader.open();
        this.serviceService.checkValid(change)
          .finally(() => loader.close())
          .subscribe((data: { client_id: number; valid: boolean; building_address: string }) => {
            if (data.valid) {
              this.serviceIdIsValid = true;
              this.itemForm.get('source_company_id').patchValue(data['client_id']);
              this.itemForm.get('data.delivery_address').patchValue(data['building_address']);
            } else {
              this.serviceIdIsValid = false;
              this.toast.show('Invalid Service Id');
            }
          });
      }
    });
    this.itemForm.get('source_company_id').valueChanges.subscribe((client_id) => {
      if (client_id) {
        this.getServiceSuppliers(client_id);
      }
    });
    this.itemForm.patchValue({
      service_id: get(this.data, 'serviceId', ''),
      client_id: get(this.data, 'clientId'),
    });


    this.itemFormGroup.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.itemFormGroup.controls['item_type'].value === 'material' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('purchase-order-item/materials', {
          params: {
            keyword: res
          }
        })),
      )
      .subscribe((res: any) => {
        this.materials = res;
      });
  }

  openCreateSupplier() {
    if (this.itemForm.get('source_company_id').value) {
      const clientId = this.itemForm.get('source_company_id').value;
      this.dialog.open(CompanySimpleCreateComponent, {
        data: {
          type: 'Supplier',
          client_id: clientId,
          client_name: this.clients.find(client => client.id === clientId)['name'],
        },
        maxWidth: '500px'
      }).afterClosed().subscribe(res => {
        if (res) {
          const loader = this.loader.open('Creating...');
          this.companyService.createSupplier(res)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Create supplier success');
              this.getServiceSuppliers(clientId);
            });
        }
      });
    }

  }

  submit() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let data = {
          ...this.itemForm.value,
          items: this.purchaseOrderItems,
          vat: this.vat
        };
        let loader = this.loader.open();
        this.purchaseOrderService.store(data)
          .finally(() => loader.close())
          .subscribe(() => {
            this.onSubmitted.emit();
            SimpleReuseStrategy.deleteRouteSnapshot('//purchase-order/create');
            this.toast.show('Purchase Order Added!');
          });
      }
    });
  }

  // Quotation Item Function

  addPurchaseOrderItem() {
    let now = this.purchaseOrderItems;
    let item = this.itemFormGroup.value;
    if (item.item_name.name) {
      item.item_name = item.item_name.name;
    }
    now.push({
      ...item,
      item_total_price: item.item_unit_price * item.item_quantity * (1 + item.item_unit_margin / 100),
    });
    this.purchaseOrderItems = [...this.purchaseOrderItems];
    this.computeTotal();
    this.itemFormGroup.reset();
  }

  removePurchaseOrderItem(row) {
    let index = this.purchaseOrderItems.indexOf(row);
    this.purchaseOrderItems.splice(index, 1);
    this.purchaseOrderItems = [...this.purchaseOrderItems];
    this.computeTotal();
  }

  computeTotal() {
    this.subtotal = this.purchaseOrderItems.reduce((a, b) => {
      return a + parseFloat('' + b.item_total_price);
    }, 0);
    this.total = this.subtotal + this.vat;
  }

  openEditServicePopup(serviceId) {
    this.popup.openServiceEditPage(serviceId);
  }

  getServiceSuppliers(clientId = '') {
    let loader = this.loader.open();
    this.purchaseOrderService.serviceSuppliers(clientId)
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.serviceSuppliers = data;
      });
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.buy_price,
      item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      return item.code + '-' + item.name;
    } else {
      return  '';
    }
  }

  openVatUpdatePopup() {
    this.popup
      .openVatCalculatePopup(this.subtotal, this.vat)
      .afterClosed()
      .subscribe(vatRes => {
        if (vatRes) {
          this.vat = vatRes.vat;
          this.computeTotal();
        }
      });
  }
}
