import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { ConfirmWithNoteComponent } from './confirm-with-note.component';

@Injectable()
export class ConfirmWithNoteService {

  constructor(private dialog: MatDialog) {
  }

  public show(data: { title?: string; message?: string; } = {}): Observable<undefined | { note: string }> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Confirm please.';
    return this.dialog.open(ConfirmWithNoteComponent, {
      width: '380px',
      disableClose: false,
      data: { title: data.title, message: data.message }
    }).afterClosed();
  }
}
