import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceKpiDetailService {

  constructor(
    private  http: HttpClient,
  ) {
  }

  applyKPI(body: { service_id: number; kpi: any }) {
    return this.http.post('applications/apply-kpi', body);
  }
}
