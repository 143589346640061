import {Component, EventEmitter, Inject, Injector, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {ModelAttributeOptions, PurchaseOrderItemOptions, ServiceOptions} from '@shared/models/options';
import {CompanyService} from '@shared/services/api/company.service';
import {BaseCreateComponent} from '@shared/components/base/base-create.component';
import {tableConfigs} from '@shared/config';
import {PurchaseOrderItemService} from '@shared/services/api/purchase-order-item.service';
import {InterfaceBaseCreate} from '@shared/interfaces/interface-base-create';
import {ServiceEditPopupComponent} from '../../service/service-edit/service-edit-popup.component';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-purchase-order-item-create',
  templateUrl: './purchase-order-item-create.component.html'
})
export class PurchaseOrderItemCreatePopupComponent extends BaseCreateComponent implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.purchase_order_item;
  @Input() id;
  @Input() public services: { type: string; id: string }[];
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemFormGroup: FormGroup = new FormGroup({
    product_id: new FormControl(null),
    item_status: new FormControl('', Validators.required),
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required]),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
  });
  public defaultMargin = 0;
  public useMargin = 0;
  public lineTotal = 0;
  public serviceOptions: ServiceOptions;
  public options: PurchaseOrderItemOptions;
  public materials = [];

  public get suppliers() {
    return this.companyService.contractors;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; services: { type: string; id: string }[] },
    public dialogRef: MatDialogRef<PurchaseOrderItemCreatePopupComponent>,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public injector: Injector,
    public _service: PurchaseOrderItemService,
    public dialog: MatDialog,
    public http: HttpClient
  ) {
    super(injector);
    if (data.id) {
      this.id = data.id;
    }
    if (data.services) {
      this.services = data.services;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    this.getOptions();
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      this.useMargin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + this.useMargin / 100);
    });
    this.itemFormGroup.get('item_type').valueChanges.subscribe((change) => {
      this.useMargin = change === 'service' ? 0 : this.defaultMargin;
      this.itemFormGroup.patchValue({ item_unit_margin: this.useMargin, product_id: null}, { emitEvent: false });
      this.materials = [];
    });
    this.itemFormGroup.controls['item_name'].valueChanges
      .pipe(
        filter(text => {
          return this.itemFormGroup.controls['item_type'].value === 'material' && text.length > 2;
        }),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((res) => this.http.get('purchase-order-item/materials', {
          params: {
            keyword: res
          }
        })),
      )
      .subscribe((res: any) => {
        this.materials = res;
      });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceOptions = data.service;
        this.options = data.purchase_order_item;
      });
  }

  submit() {
    let item = this.itemFormGroup.value;
    if (item.item_name.name) {
      item.item_name = item.item_name.name;
    }
    let data = {...item, purchase_order_id: this.id};
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Purchase Order Item Added!');
        if (this.data) {
          this.dialogRef.close(true);
        }
      });
  }

  close() {
    if (this.data) {
      this.dialogRef.close();
    }
  }

  openEditServicePopup(id) {
    this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id}
    }).afterClosed();
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.buy_price,
      item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      return item.code + '-' + item.name;
    } else {
      return  '';
    }
  }
}
