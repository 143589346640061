import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { egretAnimations } from '@app/shared/animations/egret-animations';
import { QuotationService } from '@app/shared/services/api/quotation.service';
import { ServiceService } from '@app/shared/services/api/service.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { GlobalSettingService } from '@app/shared/services/system/global-setting.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-company1412',
  templateUrl: './dashboard-company1412.component.html',
  styleUrls: ['./dashboard-company1412.component.scss'],
  animations: egretAnimations
})
export class DashboardCompany1412Component implements OnInit, OnDestroy {
  public company_id: any;
  public cards: any = {};
  public bottom_cards = [];
  public engineers = [];
  public monthly_revenue_chart: Chart;
  public cumulative_revenue_chart: Chart;
  public task_this_month_chart: Chart;
  public task_logged_per_client_ytd_chart: Chart;
  public quotes_awaiting_approval_per_client_chart: Chart;
  public total_quotes_awaiting_approval_chart: Chart;
  public monthly_spend_by_client_chart: Chart;
  public top_3_displine_approved_value_for_month_chart: Chart;
  public top_spending_clients_chart: Chart;
  public destroy = false;
  public open_compliance_ppms_percent = '50%';
  public open_non_compliance_ppms_percent = '50%';

  constructor(
    private router: Router,
    private http: HttpClient,
    private layout: LayoutService,
    private traslate: TranslateService,
    private serviceService: ServiceService,
    private quotationService: QuotationService,
    private loader: AppLoaderService,
    private global: GlobalSettingService) { }

  ngOnInit() {
    this.global.useCompanyId$.subscribe((id) => {
      this.company_id = id;
      if (this.company_id == 1412 || this.company_id == 837) {
        this.readData();
      }
    });

    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        if (this.company_id == 1412 || this.company_id == 837) {
          this.readData();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }

  readData() {
    let loader = this.loader.open();
    this.http.get('dashboard/read-1412-data')
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.cards = data.cards;
        let total_open_ppms = this.cards.open_compliance_ppms.value + this.cards.open_non_compliance_ppms.value;
        if (total_open_ppms > 0) {
          this.open_compliance_ppms_percent = ((this.cards.open_compliance_ppms.value / total_open_ppms) * 100).toFixed(0) + '%';
          this.open_non_compliance_ppms_percent = ((this.cards.open_non_compliance_ppms.value / total_open_ppms) * 100).toFixed(0) + '%';
        }
        let that = this;
        this.task_this_month_chart = new Chart({
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: ''
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              events: {
                click(event) {
                  if (event.type == 'click') {
                    let dashboard_type = _.get(event, 'point.custom.item');
                    that.clickCard({title: dashboard_type});
                  }
                }
              }
            }
          },
          series: [
            {
              name: 'Tasks',
              data: data.task_this_month_pie.data,
            }
          ]
        });

        this.task_logged_per_client_ytd_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.task_logged_per_client_ytd_chart.date],
            title: {
              text: 'Clients'
            }
          },
          yAxis: {
            title: {
              text: 'Tasks'
            },
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              events: {
                click(event) {
                  if (event.type == 'click') {
                    let dashboard_type = _.get(event, 'point.custom.item');
                    that.clickCard({title: dashboard_type});
                  }
                }
              }
            }
          },
          series: data.task_logged_per_client_ytd_chart.series
        });

        this.quotes_awaiting_approval_per_client_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.quotes_awaiting_approval_per_client_chart.date],
            title: {
              text: ''
            }
          },
          yAxis: {
            categories: ['Now'],
            title: {
              text: 'Tasks'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              events: {
                click(event) {
                  if (event.type == 'click') {
                    let dashboard_type = _.get(event, 'point.custom.item');
                    that.clickCard({title: dashboard_type}, 'quotation');
                  }
                }
              }
            }
          },
          series: data.quotes_awaiting_approval_per_client_chart.series
        });

        this.monthly_spend_by_client_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.monthly_spend_by_client_chart.date],
            title: {
              text: 'Clients'
            }
          },
          yAxis: {
            title: {
              text: 'Spend'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            }
          },
          tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
              let tmp = '';
              let length = this.points.length;
              let rowCount = 10;
              let lineCount = 4;
              tmp += '<table>';
              for (let i = 0; i < rowCount; i++) {
                tmp += '<tr>';
                for (let j = 0; j < lineCount && j * rowCount + i < length; j++) {
                  let it = this.points[j * rowCount + i];
                  tmp += "<td style='padding-right: 20px'><span style='color:" + it.color + "'>● </span>" + it.series.name + ":<span style='font-weight: bold'>￡ " + it.y + "</span></td>";
                }
                tmp += '</tr>';
              }
              tmp += '</table>';
              return tmp;
            }
          },
          series: data.monthly_spend_by_client_chart.series
        });

        this.top_3_displine_approved_value_for_month_chart = new Chart({
          chart: {
            type: 'bar'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.top_3_displine_approved_value_for_month_chart.date],
            title: {
              text: 'Disciplines'
            }
          },
          yAxis: {
            title: {
              text: 'Tasks'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            }
          },
          series: data.top_3_displine_approved_value_for_month_chart.series
        });

        this.top_spending_clients_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.top_spending_clients_chart.date],
            title: {
              text: 'Clients'
            }
          },
          yAxis: {
            title: {
              text: 'Spend'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            }
          },
          tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
              let tmp = '';
              let length = this.points.length;
              let rowCount = 10;
              let lineCount = 4;
              tmp += '<table>';
              for (let i = 0; i < rowCount; i++) {
                tmp += '<tr>';
                for (let j = 0; j < lineCount && j * rowCount + i < length; j++) {
                  let it = this.points[j * rowCount + i];
                  tmp += "<td style='padding-right: 20px'><span style='color:" + it.color + "'>● </span>" + it.series.name + ":<span style='font-weight: bold'>￡ " + it.y + "</span></td>";
                }
                tmp += '</tr>';
              }
              tmp += '</table>';
              return tmp;
            }
          },
          series: data.top_spending_clients_chart.series
        });

        this.monthly_revenue_chart = new Chart({
          chart: {
            type: 'line'
          },
          title: {
            text: 'Monthly Revenue'
          },
          subtitle: {
            text: data.monthly_revenue_chart.x_axis.title
          },
          yAxis: {
            title: {
              text: "Amount"
            }
          },
          tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
              let tmp = '';
              let length = this.points.length;
              let rowCount = 10;
              let lineCount = 4;
              tmp += '<table>';
              for (let i = 0; i < rowCount; i++) {
                tmp += '<tr>';
                for (let j = 0; j < lineCount && j * rowCount + i < length; j++) {
                  let it = this.points[j * rowCount + i];
                  tmp += "<td style='padding-right: 20px'><span style='color:" + it.color + "'>● </span>" + it.series.name + ":<span style='font-weight: bold'>￡ " + it.y + "</span></td>";
                }
                tmp += '</tr>';
              }
              tmp += '</table>';
              return tmp;
            }
          },
          xAxis: data.monthly_revenue_chart.x_axis,
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'top'
          },
          series: data.monthly_revenue_chart.series
        });

        this.cumulative_revenue_chart = new Chart({
          chart: {
            type: 'line'
          },
          title: {
            text: 'CUMULATIVE REVENUE'
          },
          subtitle: {
            text: data.cumulative_revenue_chart.x_axis.title
          },
          yAxis: {
            title: {
              text: "Amount"
            }
          },
          tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
              let tmp = '';
              let length = this.points.length;
              let rowCount = 10;
              let lineCount = 4;
              tmp += '<table>';
              for (let i = 0; i < rowCount; i++) {
                tmp += '<tr>';
                for (let j = 0; j < lineCount && j * rowCount + i < length; j++) {
                  let it = this.points[j * rowCount + i];
                  tmp += "<td style='padding-right: 20px'><span style='color:" + it.color + "'>● </span>" + it.series.name + ":<span style='font-weight: bold'>￡ " + it.y + "</span></td>";
                }
                tmp += '</tr>';
              }
              tmp += '</table>';
              return tmp;
            }
          },
          xAxis: data.cumulative_revenue_chart.x_axis,
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'top'
          },
          series: data.cumulative_revenue_chart.series
        });
      });
  }

  clickCard(item, type = 'service') {
    if (type == 'service') {
      this.serviceService.clickDashboard({ group: 'main_dashboard_card', item: item.title });
      this.router.navigate([`/service`]);
    } else if (type == 'quotation') {
      this.quotationService.clickDashboard({ group: 'main_dashboard_card', item: item.title });
      this.router.navigate([`/quotation`]);
    }
  }

  clickEngineer(row, type) {
    let title = row.engineer_name + ", " + this.traslate.instant(type);
    this.serviceService.clickDashboard({ group: 'main_dashboard_engineer', item: { key: title, engineer_id: row.engineer_id, type: type } });
    this.router.navigate([`/service`]);
  }

  humanize(value) {
    let time = moment.duration(value, "minutes");
    let str = '';
    if (time.days() > 0) {
      str += time.days() +  ' Day';
      if (time.days() > 1) {
        str += 's';
      }
      str += ' ';
    }
    if (time.hours() > 0) {
      str += time.hours() +' Hour';
      if (time.hours() > 1) {
        str += 's';
      }
      str += ' ';
    }
    if (time.minutes() > 0) {
      str += time.minutes() +' Min';
      if (time.minutes() > 1) {
        str += 's';
      }
      str += ' ';
    }

    if (value <= 0) {
      return '0 Min';
    }
    return str;
  }
}
