import { Component, OnInit, Inject, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModelAttributeOptions, QuotationItemOptions } from '@shared/models/options';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { BaseEditComponent } from '@shared/components/base/base-edit.component';
import { QuotationItemService } from '@shared/services/api/quotation-item.service';
import { tableConfigs } from '@shared/config';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-quotation-item-edit-popup',
  templateUrl: './quotation-item-edit.component.html'
})
export class QuotationItemEditPopupComponent extends BaseEditComponent implements OnInit {
  tableConfig = tableConfigs.quotation_item;

  public id;
  public itemFormGroup: FormGroup = new FormGroup({
    product_id: new FormControl(null),
    item_type: new FormControl('', Validators.required),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_margin: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_status: new FormControl('', Validators.required),
  });
  public lineTotal = 0;
  public item;
  public options: QuotationItemOptions;
  public materials = [];
  @Output() onUpdated = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<QuotationItemEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService,
    public injector: Injector,
    public http: HttpClient,
    public _service: QuotationItemService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.id = this.data.id;
    this.buildItemForm();
    this.show();
    this.getOptions();
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  buildItemForm() {
    this.itemFormGroup.valueChanges.subscribe((change) => {
      let item_quantity = change.item_quantity || 0;
      let item_unit_price = change.item_unit_price || 0;
      let item_unit_margin = change.item_unit_margin || 0;
      this.lineTotal = item_quantity * item_unit_price * (1 + item_unit_margin / 100);
    });
  }

  fillForm(item) {
    this.itemFormGroup.patchValue({ ...item });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.fillForm(this.item);
      });
  }

  submit() {
    let item = this.itemFormGroup.value;
    let data = { ...item };
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.onUpdated.emit();
        this.toast.show('Quotation item updated!');
        this.dialogRef.close(true);
      });
  }

  materialAutoComplete($event) {
    const item = $event.option.value;
    this.itemFormGroup.patchValue({
      product_id: item.id,
      item_unit_price: item.sell_price,
      // item_description: item.description
    });
  }

  materialName(item) {
    if (item) {
      return item.code + '-' + item.name;
    } else {
      return '';
    }
  }
}
