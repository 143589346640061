import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValue',
  pure: true
})
export class KeyValuePipe implements PipeTransform {

  transform(input: Object) {
    const ret = [];
    if (!input) {
      return [];
    }
    const keys = Object.keys(input);
    keys.forEach((key: any) => {
      ret.push({ 'key': key, 'value': input[key] });
    });
    return ret;
  }

}
