import { ConfirmWithNoteService } from './../../../services/system/confirm-with-note/confirm-with-note.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { ApprovalModel } from './../../../models/approval.model';
import { UserModel } from '@shared/models/user.model';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { ServiceService } from '@shared/services/api/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-uplift',
  templateUrl: './uplift.component.html'
})
export class UpliftComponent implements OnInit {
  @Output() onApproved = new EventEmitter;
  @Input() serviceId;
  @Input() items: ApprovalModel[] = [];

  public formGroup = new FormGroup({
    user_ids: new FormControl([]),
    budget: new FormControl(0, [Validators.required, Validators.min(0.01)]),
    labourTotal: new FormControl(0),
    materialTotal: new FormControl(0),
    plantTotal: new FormControl(0),
    otherTotal: new FormControl(0),
    comments: new FormControl('')
  });

  constructor(
    private serviceService: ServiceService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private layout: LayoutService,
    private confirmWithNoteService: ConfirmWithNoteService
  ) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(change => {
      this.formGroup.patchValue({ budget: change.labourTotal + change.materialTotal + change.plantTotal + change.otherTotal }, { emitEvent: false });
    });
  }

  decline(upliftId) {
    this.confirmWithNoteService.show().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.serviceService.declineUplift(this.serviceId, upliftId, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Decline uplift success.');
            this.onApproved.emit(true);
          });
      }
    });
  }

  approve(upliftId) {
    this.confirmWithNoteService.show().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.serviceService.approveUplift(this.serviceId, upliftId, { ...res })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Approve uplift success.');
            this.onApproved.emit(true);
          });
      }
    });
  }
}
