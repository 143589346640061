import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoteModel} from '@shared/models/note.model';

@Component({
  selector: 'app-note-item-card',
  templateUrl: './note-item-card.component.html',
  styleUrls: ['./note-item-card.component.scss']
})
export class NoteItemCardComponent implements OnInit {
  @Input() note: NoteModel;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  openEditPage() {
    this.onEdit.emit(this.note);
  }

  deleteItem() {
    this.onDelete.emit(this.note);
  }

  severityStyle(value) {
    let style = {'background-color': '', 'color': ''};
    value = parseInt(value, 10);
    switch (value) {
      case 1:
        style = {'background-color': '#c93756', 'color': 'white'};
        break;
      case 2:
        style = {'background-color': '#9b4400', 'color': 'white'};
        break;
      case 3:
        style = {'background-color': '#ffa631', 'color': 'white'};
        break;
      case 4:
        style = {'background-color': '#bbcdc5', 'color': ''};
        break;
      case 5:
        style = {'background-color': '', 'color': ''};
        break;
      default:
        break;
    }
    return style;
  }

}
