import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompanyService} from '@shared/services/api/company.service';
import {BuildingOptions, ModelAttributeOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {BaseEditComponent} from '@shared/components/base/base-edit.component';
import {InterfaceBaseEdit} from '@shared/interfaces/interface-base-edit';
import {tableConfigs} from '@shared/config';
import {BuildingService} from '@shared/services/api/building.service';

@Component({
  selector: 'app-building-edit',
  templateUrl: './building-edit.component.html',
  styleUrls: ['./building-edit.component.scss']
})
export class BuildingEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.building;
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted = new EventEmitter();

  public itemFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    code: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    country: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    company_id: new FormControl(null),
    status: new FormControl(''),
    data: new FormGroup({
      trading_hours: new FormControl(null),
      site_manager: new FormControl(null),
      area_manager: new FormControl(null)
    })
  });

  public get clients() {
    return this.companyService.clients;
  }

  public item;
  public buildingOption: BuildingOptions;

  constructor(
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    public injector: Injector,
    public _service: BuildingService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.show();
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.itemFormGroup.patchValue(data);
      });
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.buildingOption = data.building;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .update(this.id, this.itemFormGroup.value)
      .finally(() => loader.close())
      .subscribe(() => {
        if (this.inPopup) {
          this.onSubmitted.emit(true);
        } else {
          this.onSubmitted.emit(true);
        }
      });
      this.toast.show('Building Updated!');
  }
}
