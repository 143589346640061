import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AppTableComponent } from '@app/shared/components/system/table/table.component';
import { LaravelPageRequest } from '@app/shared/models/laravel-page-request.model';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { LaravelPageResponse } from '@app/shared/models/laravel-page-response.model';
import { DashboardService } from '@app/shared/services/api/dashboard.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { egretAnimations } from '@app/shared/animations/egret-animations';
import { ServiceEditPopupComponent } from '../../service-edit/service-edit-popup.component';

@Component({
  selector: 'app-finance-management-table',
  templateUrl: './finance-management-table.component.html',
  styleUrls: ['./finance-management-table.component.scss'],
  animations: egretAnimations,
})
export class FinanceManagementTableComponent implements OnInit {
  @ViewChild(AppTableComponent) tableComponent: AppTableComponent;
  items = [];
  keyword = '';
  indexSub;
  displayedColumns = [
    { display: 'Id', key: 'id', width: 100 },
    { display: 'Client', key: 'client_name' },
    { display: 'Building', key: 'building_name' },
    { display: 'Type', key: 'service_type', width: 100 },
    { display: 'Discipline', key: 'discipline' },
    { display: 'Contractors', key: 'contractors', flexGrow: 2 },
    { display: 'Creator', key: 'create_user_name' },
    { display: 'Status', key: 'status', useColor: true, width: 100 },
    { display: 'Priority', key: 'severity' },
  ];
  constructor(
    private loader: AppLoaderService,
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: { type: string; },
    public dialogRef: MatDialogRef<FinanceManagementTableComponent>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const request = new LaravelPageRequest(
      10,
      this.tableComponent.paginator.pageNumber + 1,
      {},
      this.keyword,
      { type: this.data.type }
    );
    if (this.indexSub) {
      this.indexSub.unsubscribe();
    }
    let loader = this.loader.open();
    this.indexSub = this.dashboardService.getFinanceManagementIndex(request)
      .finally(() => loader.close())
      .subscribe((response: LaravelPageResponse) => {
        this.tableComponent.setPaginator(response.total, response.per_page, response.current_page - 1);
        this.items = response.data;
      });
  }

  openEditPage($event) {
    this.dialog.open(ServiceEditPopupComponent, {
      data: { id: $event.id }
    });
  }

  changePage($event) {
    this.tableComponent.setPaginator($event.count, $event.pageSize, $event.offset);
    this.refresh();
  }

  filter($event) {
    this.keyword = $event;
    this.refresh();
  }
}
