import {QuotationModel} from '@shared/models/quotation.model';
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {QuotationItemService} from '@shared/services/api/quotation-item.service';
import {QuotationOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyService} from '@shared/services/api/company.service';
import {PopupService} from '@shared/services/popup.service';
import {TableConfigModel} from '@shared/models/table-config.model';
import {ApplicationService} from '@shared/services/api/application.service';
import {tableConfigs} from '@shared/config';
import {get} from 'lodash';
import {ConfirmWithNoteService} from '@app/shared/services/system/confirm-with-note/confirm-with-note.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {ApplicationItemCreateComponent} from '../application-item-create/application-item-create.component';
import {LayoutService} from '@app/shared/services/system/layout.service';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {AppConfirmService} from '@app/shared/services/system/app-confirm/app-confirm.service';
import {ApplicationItemService} from '@shared/services/api/application-item.service';
import {ApplicationItemModel} from '@shared/models/application-item.model';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html'
})
export class ApplicationEditComponent implements OnInit, OnDestroy {
  tableConfig: TableConfigModel = tableConfigs.application;
  tableConfigs = tableConfigs;
  showActions = false;

  public itemForm: FormGroup = new FormGroup({
    subject: new FormControl(''),
    description: new FormControl(''),
    vat: new FormControl(0),
    target_company_id: new FormControl(''),
    status: new FormControl(''),
  });

  public quotationOptions: QuotationOptions;

  @Input() id;
  public inPopup = false;
  private onRefreshSub;
  public item: QuotationModel;

  public get clients() {
    return this.companyService.clients;
  }

  public get suppliers() {
    return this.companyService.contractors;
  }

  public period: { in_period: boolean; has_any_kpi: boolean; budget: { start_time: string; end_time: string } };

  constructor(
    private quotationItemService: QuotationItemService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private popup: PopupService,
    public applicationService: ApplicationService,
    private applicationItemService: ApplicationItemService,
    private confirmWithNote: ConfirmWithNoteService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private layout: LayoutService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private confirmService: AppConfirmService,
    public router: Router,
  ) {
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getOptions();
    this.refresh();
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  applyApplicationKPI() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.applicationService.createApplicationKPI(this.id)
          .finally(() => loader.close())
          .subscribe(() => {
            this.refresh();
            this.toast.show('Create application KPI success');
          });
      }
    });
  }

  deleteApplicationKPI() {
    this.confirmService.confirm().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.applicationService.deleteApplicationAllKPI(this.id)
          .finally(() => loader.close())
          .subscribe(() => {
            this.refresh();
            this.toast.show('Delete application KPI success');
          });
      }
    });
  }

  refresh() {
    this.show();
    this.applicationService.getApplicationPeriod(this.id).subscribe((data: { in_period: boolean; has_any_kpi: boolean; budget: { start_time, end_time } }) => {
      this.period = data;
    });
  }

  onItemChange($event) {
    console.log($event);
    if ($event && $event['type'] && $event['application_id']) {
      const type = $event['type'];
      const id = $event['application_id'];
      if (type === 'create_item_kpi') {
        this.refreshApplicationItem(id);
      }
      if (type === 'delete_item') {
        this.removeApplicationItem();
      }
      if (type === 'edit_item') {
        this.refreshApplicationItem(id);
      }
      if (type === 'delete_all_kpi') {
        this.refreshApplicationItem(id);
      }
    }
  }

  refreshApplicationItem(applicationItemId) {
    const loader = this.loader.open();
    this.applicationItemService.show(applicationItemId)
      .finally(() => loader.close())
      .subscribe((applicationItem: ApplicationItemModel) => {
        const items = this.item.quotation_items;
        const findIndex = items.findIndex(item => item.id === applicationItemId);
        if (findIndex !== -1) {
          items[findIndex] = applicationItem;
        }
        this.item.quotation_items = [...items];
        Object.assign(this.item, applicationItem['quotation']);
      });
  }

  removeApplicationItem() {
    this.refresh();
  }

  getOptions() {
    this.maoService.all().subscribe((data: any) => {
      this.quotationOptions = data.quotation;
    });
  }

  show() {
    let loader = this.loader.open();
    this.applicationService
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.itemForm.patchValue({
          subject: data.subject || '',
          description: data.description || '',
          vat: data.vat || 0,
          target_company_id: data.target_company_id || '', // client company
          status: data.status || ''
        });
        this.checkShowActions(data);
      });
  }

  checkShowActions(item) {
    if (
      item.status === 'approved' &&
      get(item, 'data.next_action.invoice.action') === 'create'
    ) {
      this.showActions = true;
    } else {
      this.showActions = false;
    }
  }

  submit() {
    let data = {...this.itemForm.value, id: this.id};
    let loader = this.loader.open();
    this.applicationService
      .update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show(`${this.tableConfig.displayName} updated!`);
      });
  }

  openEditServicePopUp(row) {
    this.popup
      .openServiceEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openItemCreate() {
    this.dialog.open(ApplicationItemCreateComponent, {
      data: {id: this.id}
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        this.refresh();
      }
    });
  }

  deleteItem(row) {
    this.confirmService
      .confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.quotationItemService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(
                `${this.tableConfig.displayName} item ${row.id} deleted!`
              );
            });
        }
      });
  }

  approve() {
    this.confirmWithNote.show().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.applicationService
          .approve(this.id, {...res})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Approve success.');
            this.refresh();
          });
      }
    });
  }

  decline() {
    this.confirmWithNote.show().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.applicationService
          .decline(this.id, {...res})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Decline success.');
            this.refresh();
          });
      }
    });
  }

  generateInvoice() {
    this.confirmWithNote.show().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.applicationService
          .generateInvoice(this.id, {...res})
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Generate certificate success.');
            this.refresh();
          });
      }
    });
  }
}
