import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModelAttributeOptions, UserOptions} from '@shared/models/options';
import {MatSnackBar} from '@angular/material';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {Router} from '@angular/router';
import {UserService} from '@shared/services/api/user.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyModel} from '@shared/models/company.model';
import {CompanyService} from '@shared/services/api/company.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class UserCreateComponent implements OnInit {
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public loading$ = false;
  public itemForm: FormGroup;
  public relationForm: FormGroup;
  public userOptions: UserOptions;
  public companies: CompanyModel[] = [];
  public filterCompanies: CompanyModel[] = [];
  public relationTypes = {};
  public usedRelationTypes = [];
  public userCompany = [];
  public roles = [];
  public emailEvents = [];

  constructor(
    private _service: UserService,
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private router: Router,
    private confirm: AppConfirmService,
    private companyService: CompanyService,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.getRoles();
    this.getEmailEvents();
    this.buildItemForm();
    this.getOptions();
    this.getCompanies();

  }

  buildItemForm() {
    this.itemForm = this.fb.group({
      name: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', Validators.required],
      user_identity: [''],
      status: ['active'],
      address1: [''],
      address2: [''],
      town: [''],
      country: ['United Kingdom'],
      city: [''],
      postcode: [''],
      roles: [[]]
    });

    this.relationForm = this.fb.group({
      company: [null, Validators.required],
      type: [[], Validators.required],
      emails: [[]]
    });

    this.relationForm.controls['company'].valueChanges.subscribe((company) => {
      this.usedRelationTypes = [];
      if (company) {
        company.type.forEach((type) => {
          if (this.relationTypes.hasOwnProperty(type)) {
            this.usedRelationTypes = this.usedRelationTypes.concat(this.relationTypes[type]);
          }
        });
        this.usedRelationTypes = _.uniq(this.usedRelationTypes);
      }
    });
  }

  getOptions() {
    this.mao
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.userOptions = data.user;
        data.user_company.type.forEach((type) => {
          if (type.option_name === 'user_company_type') {
            this.relationTypes = type.data;
          }
        });
      });
  }

  getCompanies() {
    this.companyService.managementCompanies$
      .subscribe((data: CompanyModel[]) => {
        this.filterCompanies = this.companies = data;
      });
  }

  getEmailEvents() {
    this._service.readEmailEvents()
      .subscribe((data: any) => {
        this.emailEvents = data.events;
      });
  }

  getRoles() {
    this._service.readRoles()
      .subscribe((data: any) => {
        this.roles = data.roles;
      });
  }

  submit() {
    if (this.itemForm.valid) {
      let loader = this.loader.open();
      this._service.store({
        ...this.itemForm.value,
        user_company: this.userCompany
      })
        .finally(() => loader.close())
        .subscribe(() => {
          this.onSubmitted.emit();
          this.snack.open('User Added!', 'OK', {duration: 4000});
          SimpleReuseStrategy.deleteRouteSnapshot('///settings/user/create');
          // this.gotoTable();
        });
    }
  }

  addRelation() {
    this.userCompany.push({...this.relationForm.value, usedRelationTypes: this.usedRelationTypes, status: 'active'});
    this.relationForm.reset();
    this.refreshFilterCompanies();
  }

  removeRelation(index) {
    this.confirm.confirm().subscribe(res => {
      if (res) {
        this.userCompany.splice(index, 1);
        this.refreshFilterCompanies();
      }
    });
  }

  refreshFilterCompanies() {
    this.filterCompanies = this.companies.filter((company) => {
      return !this.userCompany.some((relation) => {
        return relation.company.id === company.id;
      });
    });
  }

  gotoTable() {
    this.router.navigate(['/settings/user']);
  }

  addEmailSubject(index) {
    this.userCompany[index].emails.push({ email: '', events: []});
  }

  removeEmail(relationIndex, emailIndex) {
    this.userCompany[relationIndex].emails.splice(emailIndex);
  }
}
