import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ServiceCustomizeService} from '@app/shared/components/common/service-customize.service';
import {CompanyService} from '@shared/services/api/company.service';
import * as _ from 'lodash';
import {ServiceOptions} from '@shared/models/options';
import {AssetService} from '@shared/services/api/asset.service';
import {PopupService} from '@shared/services/popup.service';
import {LayoutService} from '@shared/services/system/layout.service';
import {tableConfigs} from '@shared/config';
import {ServiceDetail, ServiceService} from '@shared/services/api/service.service';
import {BuildingModel} from '@shared/models/building.model';
import {BuildingService} from '@shared/services/api/building.service';
import {ServiceExtraService} from '@app/shared/components/common/service-extra.service';
import {MatDialog} from '@angular/material';
import {InvoiceEditPopupComponent} from '../../invoice/invoice-edit/invoice-edit-popup.component';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@shared/services/notify.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {TimeConvertService} from '@shared/services/time-convert.service';
import {Router} from '@angular/router';
import {ServiceVisitService} from '@shared/services/api/service-visit.service';
import {addDays, addHours, addMonths} from 'date-fns';
import {QuotationCreatePopupComponent} from '@shared/components/business/quotation/quotation-create/quotation-create-popup.component';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';
import { TranslateService } from '@ngx-translate/core';
import { AssetEditPopupComponent } from '../../asset/asset-edit/asset-edit-popup.component';
import { PaperService } from '@app/shared/services/api/paper.service';
import { PpmService } from '@app/shared/services/api/ppm.service';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  providers: [
    ServiceCustomizeService,
    ServiceExtraService,
    SimpleReuseStrategy,
  ]
})
export class ServiceEditComponent implements OnInit, OnDestroy {
  _ = _;
  tableConfig = tableConfigs.service;
  tableConfigs = tableConfigs;
  ppms = [];
  rooms = [];
  assets = [];
  engineers = [];
  filter_assets = [];
  selected_assets = [];
  public asset_ctrl: FormControl = new FormControl(null);
  public relationServicesDisplayedColumns = [
    {display: 'Id', key: 'id', useColor: false},
    {display: 'Client', key: 'client_name', useColor: false},
    {display: 'Building', key: 'building_name', useColor: false},
    {display: 'Relation Type', key: 'relation_type', useColor: false},
    {display: 'Type', key: 'service_type', useColor: false},
    {display: 'Discipline', key: 'discipline', useColor: false},
    {display: 'Contractors', key: 'contractors', useColor: false},
    {display: 'Creator', key: 'create_user_name', useColor: false},
    {display: 'Status', key: 'status', useColor: true},
    {display: 'Priority', key: 'severity', useColor: false}
  ];

  public asset_category = new FormControl(null);
  public formGroup: FormGroup = new FormGroup({
    client_id: new FormControl(null, Validators.required),
    building_id: new FormControl(null, Validators.required),
    subject: new FormControl(''),
    problem: new FormControl('', Validators.required),
    budget: new FormControl(0),
    service_type: new FormControl('', Validators.required),
    discipline: new FormControl('', Validators.required),
    severity: new FormControl(''),
    expect_start_time: new FormControl('', Validators.required),
    expect_end_time: new FormControl('', Validators.required),
    supplier_company_ids: new FormControl([]),
    status: new FormControl(null, Validators.required),
    ppm_id: new FormControl(null),
    asset_ids: new FormControl([]),
    engineers: new FormControl([]),
    data: new FormGroup({
      caller: new FormControl(''),
      caller_phone: new FormControl(''),
      nominal_code: new FormControl(),
      client: new FormGroup({
        service_code: new FormControl(),
        client_reference: new FormControl(),
        service_reference: new FormControl(),
      }),
      room_id: new FormControl(null),
    })
  });

  loadingAssets = false;

  @Input() id = null;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();

  public get clients() {
    return this.companyService.clients;
  }

  public get contractors() {
    return this.companyService.contractors;
  }

  public serviceDetail: ServiceDetail;
  public buildings: BuildingModel[] = [];
  public buildingsLoading = false;
  public serviceOptions: ServiceOptions;
  public suppliers;
  public building: BuildingModel;
  public buildingLoading = false;

  public actionLogs = [];
  public showActionLog = false;
  private onRefreshSub;

  constructor(
    private paperService: PaperService,
    private companyService: CompanyService,
    private ppmService: PpmService,
    private assetService: AssetService,
    public popup: PopupService,
    public layout: LayoutService,
    public injector: Injector,
    public serviceService: ServiceService,
    public buildingService: BuildingService,
    public customizeService: ServiceCustomizeService,
    public extraService: ServiceExtraService,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private cdr: ChangeDetectorRef,
    private confirmService: AppConfirmService,
    private timeConvertService: TimeConvertService,
    private serviceVisitService: ServiceVisitService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.refresh({buildForm: true});
    this.onRefreshSub = this.layout.onRefresh.subscribe(() => this.refresh());
  }

  ngOnDestroy(): void {
    if (this.onRefreshSub) {
      this.onRefreshSub.unsubscribe();
    }
  }

  getCompanyServiceRelationStatus(companyId) {
    if (this.serviceDetail['service']) {
      const find = this.serviceDetail.service.company_service_relations.find(item => item.company_id === companyId);
      if (find) {
        return find['status'];
      }
    }
    return null;
  }

  refresh(options = {buildForm: false}) {
    let loader = this.loader.open();
    this.serviceService
      .detail(this.id)
      .finally(() => {
        loader.close()
      })
      .subscribe((response: ServiceDetail) => {
        this.buildings = response.buildings;
        this.building = response.service.building;
        this.serviceDetail = response;
        this.rooms = _.get(this.building, 'data.rooms', []);
        this.fillForm(response.service);
        if (options.buildForm) {
          this.buildForm();
        }
        this.getEngineers(this.formGroup.get('supplier_company_ids').value);
        this.selected_assets = _.get(response, 'service.data.assets', []);
        this.filterAssets();
      });

  }

  getActionLogs() {
    this.serviceService.getActionLogs(this.id).subscribe((data: any) => {
      this.actionLogs = data;
      this.showActionLog = true;
    });
  }

  loadActionLog() {
    if (!this.showActionLog) {
      this.getActionLogs();
    }
  }

  buildForm() {
    this.formGroup.get('severity').valueChanges.subscribe((severity: string) => {
      let created_at = new Date(this.serviceDetail.service.created_at);
      let retDate;
      if (severity.indexOf('hours') !== -1) {
        retDate = addHours(created_at, parseInt(severity.slice(0, severity.length - 6), 10));
      }
      if (severity.indexOf('days') !== -1) {
        retDate = addDays(created_at, parseInt(severity.slice(0, severity.length - 5), 10));
      }
      if (severity.indexOf('months') !== -1) {
        retDate = addMonths(created_at, parseInt(severity.slice(0, severity.length - 7), 10));
      }
      if (retDate) {
        this.formGroup.patchValue({
          expect_start_time: retDate.toISOString(),
          expect_end_time: addHours(retDate, 24),
        });
      }
    });
    this.formGroup.get('supplier_company_ids').valueChanges.subscribe(supplier_company_ids => {
      if (supplier_company_ids.length > 0) {
        this.getEngineers(supplier_company_ids);
      }
    });
    this.formGroup.get('client_id').valueChanges.subscribe((client_id: any) => {
      if (client_id && this.formGroup.value.client_id !== client_id) {
        this.formGroup.get('building_id').reset();
        this.getBuildings(client_id);
      }
    });
    this.formGroup.get('building_id').valueChanges.subscribe(building_id => {
      if (building_id && this.formGroup.value.building_id !== building_id) {
        this.getBuilding(building_id);
        // this.formGroup.get('asset_ids').reset([]);
        if (this.formGroup.value.service_type == 'ppm') {
          this.getPPMs(building_id);
        } else {
          this.getAssets(building_id);
        }
      }
    });

    this.formGroup.get('service_type').valueChanges.subscribe(service_type => {
      if (service_type == 'ppm') {
        this.getPPMs(this.formGroup.value.building_id);
      } else {
        this.getAssets(this.formGroup.value.building_id);
      }
    });

    this.formGroup.get('asset_ids').valueChanges.subscribe((asset_ids) => {
      this.reloadSelectedAssets(asset_ids);
    });

    this.formGroup.get('ppm_id').valueChanges.subscribe((ppm_id) => {
      this.selected_assets = [];
      this.ppms.forEach((ppm) => {
        if (ppm.id == ppm_id) {
          this.selected_assets = ppm.assets;
        }
      });
      this.filterAssets();
    });
  }

  fillForm(item) {
    const engineerIds = item.engineers.map((engineer) => {
      return engineer.id;
    });
    this.formGroup.patchValue({
      client_id: +item['client_id'],
      building_id: +item['building_id'],
      subject: item['subject'], // string
      problem: item['problem'], // string
      budget: parseFloat(item['budget']).toFixed(2) || 0, // number
      service_type: item['service_type'], // option
      discipline: item['discipline'], // option
      severity: item['severity'], // option
      engineers: engineerIds,
      expect_start_time: item['expect_start_time'],
      expect_end_time: item['expect_end_time'],
      supplier_company_ids: item['supplier_company_ids'],
      status: item['status'],
      asset_ids: _.get(item, 'asset_ids', []),
      ppm_id: _.get(item, 'data.ppm.id', []),
      data: item['data'],
    });
    this.extraService.setFormGroup(_.get(item, 'data.extra', {}));
    this.customizeService.setByObject(_.get(item, 'data.customize', {}));
    if (this.formGroup.value.service_type == 'ppm') {
      this.getPPMs(this.formGroup.value.building_id);
    } else {
      this.getAssets(this.formGroup.value.building_id);
    }
  }

  getBuilding(buildingId) {
    this.buildingLoading = true;
    this.buildingService
      .show(buildingId)
      .finally(() => this.buildingLoading = false)
      .subscribe((data: BuildingModel) => {
        this.building = data;
        this.rooms = _.get(this.building, 'data.rooms', []);
      });
  }

  getBuildings(client_id) {
    this.buildingsLoading = true;
    this.serviceService
      .buildingsByClient(client_id)
      .finally(() => this.buildingsLoading = false)
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  submit() {
    this.confirmService.confirm({}).subscribe(res => {
      if (res) {
        let itemFormValue = this.formGroup.value;

        itemFormValue['expect_start_time'] = this.timeConvertService.convert(itemFormValue['expect_start_time']);
        itemFormValue['expect_end_time'] = this.timeConvertService.convert(itemFormValue['expect_end_time']);
        itemFormValue['data']['extra'] = this.extraService.all();
        itemFormValue['data']['customize'] = this.customizeService.allObject();
        itemFormValue['asset_category'] = this.asset_category.value;
        let loader = this.loader.open();
        this.serviceService
          .update(this.id, itemFormValue)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.toast.show('Service Updated!');
            this.refresh();
            this.serviceService.refresh();
            this.onSubmitted.emit();
          });
      }
    });
  }

  gotoTable() {
    this.router.navigate(['/service']);
  }

  openCreateServiceVisitPopup(item) {
    this.popup
      .openCreateServiceVisitPopup({
        item,
        rateType:
          _.get(item, 'data.out_of_hours_call_out', 'no') === 'yes'
            ? 'one_off_rate'
            : 'hourly_rate'
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openQuotationEditPopup($event) {
    this.popup
      .openQuotationEditPage($event.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openQuotationCreatePopup() {
    this.dialog.open(QuotationCreatePopupComponent, {
      disableClose: true,
      data: {
        serviceId: this.serviceDetail.service.id,
        client_reference: _.get(this.serviceDetail.service, 'data.client.client_reference', '')
      }
    })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openPurchaseOrderEditPopup($event) {
    this.popup
      .openPurchaseOrderEditPage($event.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  openInvoiceEditPopup($event) {
    this.dialog.open(InvoiceEditPopupComponent,
      {
        data: {id: $event.id}
      }
    ).afterClosed().subscribe((res) => {
      if (res) {
        this.refresh();
      }
    });
  }

  openPurchaseOrderCreatePopup() {
    this.popup
      .openPurchaseOrderCreatePopup({
        serviceId: this.id,
        clientId: this.formGroup.value.client_id
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  cancel() {
    let loader = this.loader.open();
    this.serviceService
      .cancel(this.id)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Cancel success');
        this.refresh();
        this.serviceService.refresh();
      });
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }

  deleteServiceVisit(row) {
    this.confirmService
      .confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.serviceVisitService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Service visit ${row.id} deleted!`);
            });
        }
      });
  }

  openEditServiceVisit(row) {
    this.popup
      .openServiceVisitEditPage(row.id, this.serviceDetail.service)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  toCreateQuoteRequest() {
    this.confirmService.confirm({title: 'Will leave the current page.', message: 'Will fill the service create page with new data.'}).subscribe((res) => {
      if (res) {
        SimpleReuseStrategy.deleteRouteSnapshot('//service/create');
        this.router.navigate(['/service/create', {
          type: 'create_quote_request',
          id: this.id,
          client_id: this.formGroup.value.client_id,
          building_id: this.formGroup.value.building_id,
          subject: this.formGroup.value.subject,
          problem: this.formGroup.value.problem,
          discipline: this.formGroup.value.discipline,
          supplier_company_ids: this.formGroup.value.supplier_company_ids,
        }]);
        if (this.inPopup) {
          this.onBack.emit();
        }
      }
    });

  }

  getEngineers(supplierCompanyIds) {
    let loader = this.loader.open();
    this.serviceService
      .readSupplierEngineers(supplierCompanyIds)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.engineers = data;
        const oldAssignEngineers = this.formGroup.get('engineers').value;
        const newAssignEngineers = this.engineers.map((engineer) => {
          return oldAssignEngineers.indexOf(engineer.id) > -1 ? engineer.id : false;
        }).filter((id) => {
          return id;
        });
        this.formGroup.get('engineers').setValue(newAssignEngineers);
      });
  }

  searchRoom(term, item) {
    return ("" + item.floor + "" + item.name).toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }

  filterDiscipline(term, item) {
    return _.replace(item.option_value, '_', ' ').toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }

  deleteAsset(row) {
    this.confirmService.confirm({}).subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.serviceService
          .removeAsset(this.id, row.id)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.toast.show('Asset Updated!');
            this.refresh();
            this.serviceService.refresh();
            this.onSubmitted.emit();
          });
      }
    });
  }

  appendAsset(row) {
    this.confirmService.confirm({}).subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.serviceService
          .appendAsset(this.id, row.id)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.toast.show('Asset Updated!');
            this.refresh();
            this.serviceService.refresh();
            this.onSubmitted.emit();
          });
      }
    });
  }


  editAsset(id) {
    this.dialog.open(AssetEditPopupComponent, {
      width: '960px',
      disableClose: false,
      data: {id: id, title: 'Asset Edit(' + id + ')'}
    }).afterClosed()
      .subscribe((data: any) => {
        if (data) {
          this.refresh();
        }
      });
  }

  viewPdfs(row) {
    let loader = this.loader.open();
    this.paperService.viewPdfs(row.id)
      .finally(() => loader.close())
      .subscribe((pdfs: any) => {
        pdfs.map((pdf) => {
          let win = window.open('');
          win.document.write(pdf.html);
        });
      });
  }

  savePdfs(row) {
    let loader = this.loader.open();
    this.paperService.savePdfs(row.id)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Save pdfs success');
        this.refresh();
      });
  }

  openLink(document: any) {
    window.open(document.full_url);
  }


  getPPMs(building_id) {
    if (building_id) {
      let loader = this.loader.open();
      this.ppmService
        .readByBuilding(building_id)
        .finally(() => loader.close())
        .subscribe((data) => {
          this.ppms = _.get(data, "ppms", []);
        });
    } else {
      this.ppms = [];
    }
  }

  getAssets(building_id) {
    if (building_id) {
      let loader = this.loader.open();
      this.assetService
        .byBuildingId(building_id)
        .finally(() => loader.close())
        .subscribe((data: any) => {
          this.assets = data;
        });
    } else {
      this.assets = [];
    }
  }

  filterAssets() {
    this.filter_assets = this.selected_assets
      .filter((asset) => {
        if (this.asset_ctrl.value) {
          let keyword = this.asset_ctrl.value.toLowerCase();
          return (
            asset.category.toLowerCase().indexOf(keyword) >= 0 ||
            asset.name.toLowerCase().indexOf(keyword) >= 0
          );
        }
        return true;
      });
  }

  clearAssetFilter() {
    this.asset_ctrl.patchValue(null);
    this.filterAssets();
  }

  reloadSelectedAssets(asset_ids) {
    this.selected_assets = this.assets.filter(asset => {
      return asset_ids.indexOf(asset.id) > -1;
    });
    this.filterAssets();
  }

  refreshTab($event) {
    setTimeout(() => {
      this.filterAssets();
      this.cdr.markForCheck();
    }, 618)
  }
}
