import {UserModel} from '@shared/models/user.model';
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {PurchaseOrderItemService} from '@shared/services/api/purchase-order-item.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyService} from '@shared/services/api/company.service';
import {PopupService} from '@shared/services/popup.service';
import {PurchaseOrderService} from '@shared/services/api/purchase-order.service';
import {AuthGuard} from '@shared/services/system/auth/auth.guard';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {PurchaseOrderModel} from '@shared/models/purchase-order.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {NotifyService} from '@shared/services/notify.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {tableConfigs} from '@shared/config';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {CompanyModel} from '@shared/models/company.model';

@Component({
  selector: 'app-purchase-order-edit',
  templateUrl: './purchase-order-edit.component.html'
})
export class PurchaseOrderEditComponent implements OnInit {
  public tableConfigs = tableConfigs;
  public itemForm = new FormGroup({
    subject: new FormControl(''),
    description: new FormControl(''),
    vat: new FormControl(0, Validators.required),
    status: new FormControl('', Validators.required),
    source_company_id: new FormControl('', Validators.required),
    target_company_id: new FormControl('', Validators.required),
    user_identity_user_id: new FormControl('', Validators.required),
    service_id: new FormControl(null),
    data: new FormGroup({
      date_of_order: new FormControl('', Validators.required),
      delivery_address: new FormControl(''),
      special_instructions: new FormControl(null),
      delivery_date: new FormControl(''),
    }),
  });

  public get data() {
    return this.itemForm.get('data') as FormGroup;
  }

  public get clients() {
    return this.companyService.clients;
  }

  public serviceSuppliers: CompanyModel[] = [];

  public purchaseOrderItemDisplayColumns = [
    {display: 'Type', key: 'item_type', useColor: false},
    {display: 'Quantity', key: 'item_quantity', useColor: false},
    {display: 'Name', key: 'item_name', useColor: false},
    {display: 'Price', key: 'item_unit_price', useColor: false},
    {display: 'Markup (%)', key: 'item_unit_margin', useColor: false},
    {display: 'Total Price', key: 'item_total_price', useColor: false},
    {display: 'Status', key: 'item_status', useColor: true},
  ];
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public item: PurchaseOrderModel;

  public authUser: UserModel;

  constructor(
    private loader: AppLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmService: AppConfirmService,
    private toast: NotifyService,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private popup: PopupService,
    private purchaseOrderItemService: PurchaseOrderItemService,
    public _service: PurchaseOrderService,
    public purchaseOrderService: PurchaseOrderService,
    public auth: AuthGuard,
    public dialog: MatDialog,
    public globalSetting: GlobalSettingService
  ) {
  }

  ngOnInit() {
    if (!this.inPopup) {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    this.authUser = this.globalSetting.getConfig('user');
    this.buildItemForm();
    this.show();
  }

  buildItemForm() {
    this.itemForm.get('vat').valueChanges.subscribe((change) => {
      this.item.total = this.item.subtotal + change;
    });
    this.itemForm.get('source_company_id').valueChanges.subscribe((client_id) => {
      if (client_id) {
        this.getServiceSuppliers(client_id);
      }
    });
  }

  getServiceSuppliers(clientId = '') {
    let loader = this.loader.open();
    this.purchaseOrderService.serviceSuppliers(clientId)
      .finally(() => loader.close())
      .subscribe((data: CompanyModel[]) => {
        this.serviceSuppliers = data;
      });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        if (data['data'] && data['data']['delivery_date']) {
          data['data']['delivery_date'] = new Date(data['data']['delivery_date']);
        }
        if (data['data'] && data['data']['date_of_order']) {
          data['data']['date_of_order'] = new Date(data['data']['date_of_order']);
        }
        this.item = data;
        this.itemForm.patchValue(data);
      });
  }

  submit() {
    let data = {...this.itemForm.value, id: this.id};
    let loader = this.loader.open();
    this._service.update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show('Purchase Order updated!');
        this.onSubmitted.emit();
      });
  }

  openPurchaseOrderCreateItemPopup() {
    this.popup.openPurchaseOrderItemCreatePage(this.id, this.item.data.relation.service)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.refresh();
        }
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({message: `Delete ${row.id}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.purchaseOrderItemService.destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show(`Purchase order item ${row.id} deleted!`);
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup.openPurchaseOrderItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.show();
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }

  sendEmail() {
    const dialogRef = this.dialog.open(PurchaseOrderEditSendEmail, {
      width: '300px',
      data: {purchaseOrderId: this.id}
    });

    dialogRef.afterClosed().subscribe(emails => {
      if (emails) {
        let loader = this.loader.open('Send email...');
        this._service.sendEmails({
          purchase_order_id: this.id,
          emails: emails
        })
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Send success');
          })
      }
    });
  }

  openVatUpdatePopup() {
    this.popup
      .openVatCalculatePopup(this.item.subtotal, this.item.vat)
      .afterClosed()
      .subscribe(vatRes => {
        if (vatRes) {
          this.confirmService
            .confirm({ message: `Update vat ?` })
            .subscribe(confirmRes => {
              if (confirmRes) {
                let loader = this.loader.open();
                this._service
                  .updateVat(this.id, vatRes.vat)
                  .finally(() => loader.close())
                  .subscribe(() => {
                    this.refresh();
                    this.toast.show(`Quotation vat updated!`);
                  });
              }
            });
        }
      });

  }
}

@Component({
  selector: 'purchase-order-edit-send-email',
  template: `
    <h1 mat-dialog-title>Enter the address where you want to send the message</h1>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <input matInput [formControl]="email" placeholder="Email">
      </mat-form-field>
      <button mat-raised-button class="full-width" (click)="addEmail()" translate [disabled]="email.invalid">ADD</button>
      <mat-list>
        <mat-list-item *ngFor="let email of emails">
          <button mat-icon-button (click)="removeEmail(email)">
            <mat-icon>delete</mat-icon>
          </button>
          {{email}}
        </mat-list-item>
      </mat-list>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="accent" (click)="onNoClick()">CANCEL</button>
      <div fxFlex></div>
      <button mat-raised-button color="primary" (click)="send()" [disabled]="emails.length === 0">SEND</button>
    </div>`
})
export class PurchaseOrderEditSendEmail {
  email = new FormControl('', Validators.email);
  emails = [];

  constructor(
    public dialogRef: MatDialogRef<PurchaseOrderEditSendEmail>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  addEmail() {
    if (this.email.valid) {
      this.emails.push(this.email.value);
      this.email.reset();
    }
  }

  removeEmail(email) {
    this.emails.splice(this.emails.indexOf(email), 1);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  send() {
    this.dialogRef.close(this.emails);
  }


}

