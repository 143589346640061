import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {ModelAttributeOptions} from '@shared/models/options';
import {Subject, ReplaySubject} from 'rxjs';
import {has, get, set, groupBy} from 'lodash';
import {NotifyService} from '../notify.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import 'rxjs-compat/add/operator/map';
import { GlobalSettingService } from '../system/global-setting.service';

export class ModelAttributeOptionRequest {
  models: { model: string }[];
}

@Injectable()
export class ModelAttributeOptionService extends ApiBaseService {
  route_prefix = 'model-attribute-options';
  public options$: ReplaySubject<ModelAttributeOptions | undefined> = new ReplaySubject(1);
  public optionsCache: ModelAttributeOptions = undefined;
  private optionsIsLoading = false;

  constructor(
    public http: HttpClient,
    public router: Router,
    public notify: NotifyService,
    private global: GlobalSettingService
  ) {
    super(http, router);
    this.global.config$
      .subscribe(() => {
        this.all(true).subscribe();
      });
  }

  all(forceRefresh = false): Subject<ModelAttributeOptions> {
    if ((this.optionsCache === undefined && !this.optionsIsLoading) || forceRefresh) {
      this.optionsIsLoading = true;
      this.http
        .get(`model-attribute-options/all?force_refresh=` + forceRefresh)
        .finally(() => (this.optionsIsLoading = false))
        .subscribe((options: any[]) => {
          this.optionsCache = this.formatOptions(options);
          this.notify.debug('mao', this.optionsCache);
          this.options$.next(this.optionsCache);
        });
    }
    return this.options$;
  }

  // get service data default form
  public getServiceDataDefaultFormList() {
    return this.all()
      .map(data => {
        return get(data, 'service.data_groups.default', []).filter(item => {
          return has(item, 'data.type');
        });
      });
  }

  private formatOptions(options: {}[]) {
    let modelGroups = groupBy(options, 'for_model');
    let result = new ModelAttributeOptions();
    for (const model in modelGroups) {
      if (modelGroups.hasOwnProperty(model)) {
        const value = modelGroups[model];
        set(result, model, groupBy(value, 'for_attribute'));
        if (has(result, model + '.data')) {
          set(
            result,
            model + '.data_groups',
            groupBy(result[model]['data'], 'option_name')
          );
        }
      }
    }
    return result;
  }

  serviceSystemDataKeys() {
    return this.http.get(`model-attribute-options/service-system-data-keys`);
  }
}
