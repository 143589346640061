import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSettingConfig } from '@app/shared/interfaces/global-setting-config';
import { DashboardService } from '@app/shared/services/api/dashboard.service';
import { ServiceService } from '@app/shared/services/api/service.service';
import { GlobalSettingService } from '@app/shared/services/system/global-setting.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import {egretAnimations} from '@shared/animations/egret-animations';

@Component({
  selector: 'app-dashboard-default',
  templateUrl: './dashboard-default.component.html',
  styleUrls: ['./dashboard-default.component.scss'],
  animations: egretAnimations
})
export class DashboardDefaultComponent implements OnInit, OnDestroy {
  chart1: Chart;
  chart2: Chart;
  chart3: Chart;
  loading = false;
  destroy = false;

  constructor(
    private dashboardService: DashboardService,
    private service: ServiceService,
    private router: Router,
    private layout: LayoutService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private globalSettingService: GlobalSettingService,
  ) {
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }

  refresh() {
    this.loading = true;
    this.cd.markForCheck();
    this.dashboardService.getHomeDashboard()
      .finally(() => {
        this.loading = false;
        this.cd.markForCheck();
      })
      .takeWhile(() => !this.destroy)
      .subscribe((data: any) => {
        data.service.chart1.drilldown_series = data.service.chart1.drilldown_series.map(item => {
          item.name = this.translate.instant(item.name);
          return item;
        });
        data.service.chart1.series_data = data.service.chart1.series_data.map(item => {
          item.name = this.translate.instant(item.name);
          return item;
        });
        let drilldownSeries = data.service.chart1.drilldown_series;
        drilldownSeries.forEach(item => {
          item.colorByPoint = true;
          let that = this;
          item.events = {
            that: this,
            click: function (event) {
              that.toServiceTable(this.name, event.point.name);
            }
          };
        });
        this.chart1 = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: 'Services total number group by disciplines'
          },
          subtitle: {
            text: 'Click to view type classification'
          },
          xAxis: {
            type: 'category'
          },
          yAxis: {
            title: {
              text: 'service number'
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              // borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}'
              }
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
          },
          series: [{
            name: 'Discipline',
            // @ts-ignore
            colorByPoint: true,
            data: data.service.chart1.series_data
          }],
          drilldown: {
            series: data.service.chart1.drilldown_series
          }
        });
        this.chart2 = new Chart({
          chart: {
            type: 'pie'
          },
          title: {
            text: 'Services total number group by disciplines'
          },
          subtitle: {
            text: 'Click to view type classification'
          },
          xAxis: {
            type: 'category'
          },
          yAxis: {
            title: {
              text: 'service number'
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              // borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.name}: {point.y}'
              }
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
          },
          series: [{
            name: 'Discipline',
            // @ts-ignore
            colorByPoint: true,
            data: data.service.chart1.series_data
          }],
          drilldown: {
            series: data.service.chart1.drilldown_series
          }
        });
        this.chart3 = new Chart({
          chart: {
            type: 'line'
          },
          title: {
            text: 'Services total number group by disciplines'
          },
          credits: {
            enabled: false
          },
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
          },
          series: [{
            name: 'Discipline',
            // @ts-ignore
            colorByPoint: true,
            data: data.service.chart1.series_data
          }],
          drilldown: {
            series: data.service.chart1.drilldown_series
          }
        });
      });
  }

  toServiceTable(discipline, service_type) {
    this.layout.publishLayoutChange({switchTable: 'table'});
    this.service.changeConditionValue('discipline', discipline);
    this.service.changeConditionValue('service_type', service_type);
    this.router.navigate(['service']);
  }
}