import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ModelAttributeOptionService } from '@shared/services/api/model-attribute-option.service';
import { CompanyOptions, ModelAttributeOptions } from '@shared/models/options';
import { BaseCreateComponent } from '@shared/components/base/base-create.component';
import { InterfaceBaseCreate } from '@shared/interfaces/interface-base-create';
import { tableConfigs } from '@shared/config';
import { CompanyService } from '@shared/services/api/company.service';
import { SimpleReuseStrategy } from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class CompanyCreateComponent extends BaseCreateComponent
  implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.company;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemFormGroup: FormGroup = new FormGroup({
    type: new FormControl([], [Validators.required]),
    industry: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    status: new FormControl('active'),
    data: new FormGroup({
      category: new FormControl([])
    }),
    client_ids: new FormControl([0])
  });
  public companyOption: CompanyOptions;
  public categories = [
    { option_value: 'product' },
    { option_value: 'service' },
  ];
  public clients: any[] = [];

  constructor(
    private maoService: ModelAttributeOptionService,
    protected injector: Injector,
    public _service: CompanyService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
  }

  disabledType(type) {
    return type === 'agent';
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.companyOption = data.company;
    });
    this._service.getClients()
    .subscribe((data: any) => {
      data.unshift({ 'id': 0, 'name': '--ALL--' })
      this.clients = data;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .store(this.itemFormGroup.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        SimpleReuseStrategy.deleteRouteSnapshot('///settings/company/create');
        this.toast.show('Company Added!');
      });
  }

  gotoTable() {
    this.router.navigate(['/settings/company']);
  }
}
