import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';


@Injectable({
  providedIn: 'root'
})
export class TimeConvertService {

  constructor() { }

  public convert(time) {
    let convert_time = moment(time).tz('Europe/London').format();
    if (convert_time.toString().toLowerCase() === 'invalid date') {
      return null;
    }
    return convert_time;
  }
}
