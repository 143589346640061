import {ModelAttributeOptions, NoteOptions} from './../../../../models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {NoteService} from '@shared/services/api/note.service';
import {CompanyService} from '@shared/services/api/company.service';
import {CompanyModel} from '@shared/models/company.model';
import {UserService} from '@shared/services/api/user.service';

@Component({
  selector: 'app-note-create',
  templateUrl: './note-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteCreateComponent implements OnInit {
  @Input() id; // type_id
  @Input() type;
  @Input() isPopup = false;
  @Output() onCreated = new EventEmitter();

  public noteOptions: NoteOptions;
  public users = [];

  public get companies(): CompanyModel[] {
    return this.companyService.managementCompanies;
  };

  public userAllSub;
  public usersLoading = false;

  public itemForm: FormGroup = new FormGroup({
    note: new FormControl('', [Validators.required]),
    severity: new FormControl(''),
    target_company: new FormControl([]),
    target_user: new FormControl([])
  });

  constructor(
    private _service: NoteService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _user: UserService,
    private companyService: CompanyService,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.mao.all().subscribe((data: ModelAttributeOptions) => {
      this.noteOptions = data.note;
    });
    this.itemForm.get('target_company').valueChanges.subscribe((target_company) => {
      if (this.userAllSub) {
        this.userAllSub.unsubscribe();
      }
      this.usersLoading = true;
      this.userAllSub = this._user.all({company_ids: target_company})
        .finally(() => this.usersLoading = false)
        .subscribe((data: any) => {
          this.users = data;
          this.users.unshift({id: 0, name: '--ALL--'});
        });
    });
  }

  submit() {
    let data = {...this.itemForm.value, type: this.type, type_id: this.id};
    let loader = this.loader.open();
    this._service
      .store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.snack.open('Note created!', 'OK', {duration: 4000});
        this.onCreated.emit(true);
      });
  }

  severityStyle(value) {
    return this._service.severityStyle(value);
  }
}
