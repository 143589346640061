import {Component, OnInit} from '@angular/core';
import {ServiceExtraService} from '../service-extra.service';
import {find, get, keys} from 'lodash';

@Component({
  selector: 'app-service-extra-edit',
  templateUrl: './service-extra-edit.component.html',
  styleUrls: ['./service-extra-edit.component.scss'],
})
export class ServiceExtraEditComponent implements OnInit {
  public get serviceOptions() {
    return this.serviceExtraService.serviceOptions;
  };

  public keys = keys;

  constructor(
    public serviceExtraService: ServiceExtraService,
  ) {
  }

  ngOnInit() {
  }

  getExtraFormValueData(key) {
    return get(find(this.serviceExtraService.extraForm, ['option_value', key]), 'data');
  }

  showGroup(key) {
    return keys(this.serviceExtraService.formGroup.controls[key].value).length > 0;
  }

}
