import { DocumentModel } from './document.model';

export class ServiceVisitModel {
  id: number;
  user_id: number;
  user_name: number;
  check_in_time: string;
  check_out_time: string;
  service_status: string;
  note: string;
  observer: string;
  signature: string;
  pay_type: string;
  log_type: string;
  log_user_name: string;
  log_user_id: number;
  check_in_latitude: string;
  check_out_longitude: string;
  data: {};
  service_id: {};
  documents: DocumentModel[];
  created_at: string;
}
