import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {AssetOptions, ModelAttributeOptions, ServiceVisitOptions} from '@shared/models/options';
import {ServiceVisitService} from '@shared/services/api/service-visit.service';
import {ServiceService} from '@shared/services/api/service.service';
import {UserModel} from '@shared/models/user.model';
import {NotifyService} from '@app/shared/services/notify.service';
import {TimeConvertService} from '@shared/services/time-convert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-service-visit-create',
  templateUrl: './service-visit-create.component.html'
})
export class ServiceVisitCreateComponent implements OnInit {
  @Input() item;  // service
  @Input() isPopup = false;
  @Input() rateType = 'hourly_rate';
  @Output() onCreated = new EventEmitter();

  public serviceVisitOptions: ServiceVisitOptions;
  public engineers: UserModel[] = [];
  public assetOptions: AssetOptions;
  public assets = [];

  public itemForm: FormGroup = new FormGroup({
    user_id: new FormControl(null, Validators.required),
    check_in_time: new FormControl('', Validators.required),
    check_out_time: new FormControl(''),
    service_status: new FormControl('', Validators.required),
    note: new FormControl('', [Validators.required, Validators.minLength(30)]),
    observer: new FormControl(''),
    rate_type: new FormControl(''),
    check_in_latitude: new FormControl(''),
    check_out_latitude: new FormControl(''),
    check_in_longitude: new FormControl(''),
    check_out_longitude: new FormControl(''),
    data: new FormControl({}),
  });

  constructor(
    private _service: ServiceVisitService,
    private serviceService: ServiceService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private mao: ModelAttributeOptionService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.getServiceEngineers();
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.serviceVisitOptions = data.service_visit;
        this.assetOptions = data.asset;
      });
    this.itemForm.patchValue({rate_type: this.rateType});

  }

  getServiceEngineers() {
    let loader = this.loader.open();
    this.serviceService.engineers(this.item.id)
      .finally(() => loader.close())
      .subscribe((data: UserModel[]) => {
        this.engineers = data;
      });
  }

  submit() {
    let itemFormValue = this.itemForm.value;
    itemFormValue['check_in_time'] = this.timeConvertService.convert(itemFormValue['check_in_time']);
    itemFormValue['check_out_time'] = this.timeConvertService.convert(itemFormValue['check_out_time']);
    if (this.item.service_type.toLowerCase() === 'ppm') {
      itemFormValue['data']['assets'] = this.assets.map((asset) => {
        asset.notification_time = this.timeConvertService.convert(asset.notification_time);
        asset.next_service_time = this.timeConvertService.convert(asset.next_service_time);
        asset.last_service_time = this.timeConvertService.convert(asset.last_service_time);
        return asset;
      });
    }
    let data = {...itemFormValue, service_id: this.item.id};
    let loader = this.loader.open();
    this._service.store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Visit created!');
        this.onCreated.emit(true);
      });
  }

  addAssetsCompliant($event) {
    $event.compliant_status = '';
    this.assets.push($event);
  }

  removeAssetsCompliant($event) {
    let index = 0;
    this.assets.forEach((asset, i) => {
      if (asset.id == $event.value.id) {
        index = i;
      }
    });
    this.assets.splice(index, 1);
  }

  changeCompliant(item, asset) {
    if (item === 'compliant') {
      let service_interval = '1 years';
      if (asset.hasOwnProperty('service_interval')) {
        service_interval = asset.service_interval;
      }
      let notification_period = 30;
      if (!asset.hasOwnProperty('service_interval')) {
        notification_period = 30;
      }

      let num_match = service_interval.match(/^\d+/gi);
      let str_match = service_interval.match(/[a-z|A-Z]+$/gi);
      let num = 1;
      let str = 'year';

      asset.next_service_time = moment().add(num, 'year').format('YYYY-MM-DD HH:mm:ss');
      asset.last_service_time = moment().format('YYYY-MM-DD HH:mm:ss');
      asset.notification_time = moment().add(-notification_period, 'days').format('YYYY-MM-DD HH:mm:ss');
    }
  }

  clearAssetCompliant($event) {
    this.assets = [];
  }
}
