import {Pipe, PipeTransform} from '@angular/core';
import {ValidationErrors} from '@angular/forms';

@Pipe({
  name: 'formError'
})
export class FormErrorPipe implements PipeTransform {

  transform(value: ValidationErrors): any {
    let messages = [];
    if (value['maxlength']) {
      messages.push(`Max length is ${value['maxlength']['requiredLength']}, actual length is ${value['maxlength']['actualLength']}`);
    }
    if (value['minlength']) {
      messages.push(`Min length is ${value['minlength']['requiredLength']}, actual length is ${value['minlength']['actualLength']}`);
    }
    if (value['required']) {
      messages.push(`Value is required`);
    }
    if (value['email']) {
      messages.push(`Invalid email format`);
    }
    return messages.join('; ');
  }

}
