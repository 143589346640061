import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { CompanyService } from '@shared/services/api/company.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validator, Validators } from '@angular/forms';

@Component({
  selector: 'app-quotation-client-review-create',
  template: `
    <h1 mat-dialog-title translate>Client Review</h1>
    <div mat-dialog-content>
      <p translate>You need to fill in some information to create approval</p>
      <div fxLayout="row wrap">
        <div fxFlex="100" class="pr-1" *ngIf="mode == 'user'">
          <ng-select [items]="clientUsers" [placeholder]="'Client Users ('  + clientUsers?.length + ')'"
                     [formControl]="userIds" appendTo="body" bindLabel="name" bindValue="id" [multiple]="true">
            <ng-template ng-option-tmp let-item="item">
              <span>{{item?.name}}</span><br>
              <span class="text-muted" *ngIf="item?.email"><b>Email: </b>{{item?.email}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div fxFlex="100" class="pr-1" *ngIf="mode == 'email'">
          <ng-select [placeholder]="'Emails ('  + emails?.value?.length + ')'"
                     [formControl]="emails" appendTo="body" [multiple]="true" [addTag]="addEmail" >
          </ng-select>
        </div>
        <div fxFlex="100" class="pr-1">
          <mat-form-field class="full-width">
            <textarea rows="5" matInput name="Note" [formControl]="note" placeholder="Note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="submit()" translate>Ok</button>
    </div>
  `
})
export class QuotationClientReviewCreate implements OnInit {
  // name = new FormControl('');
  mode = 'user';
  note = new FormControl('');
  emails = new FormControl([]);
  userIds = new FormControl([]);
  clientUsers = [];

  constructor(
    public dialogRef: MatDialogRef<QuotationClientReviewCreate>,
    @Inject(MAT_DIALOG_DATA) public data: { clientId: string, mode: string },
    private companyService: CompanyService,
    private loader: AppLoaderService
  ) {
    this.mode = data.mode;
  }

  ngOnInit(): void {
    if (this.mode == 'user' && this.data.clientId.length > 0) {
      let loader = this.loader.open();
      this.companyService.users(this.data.clientId)
        .finally(() => loader.close())
        .subscribe((data: any) => {
          this.clientUsers = data;
        });
    }
  }

  submit() {
    this.dialogRef.close({ note: this.note.value, userIds: this.userIds.value, emails: this.emails.value });
  }

  addEmail($event) {
    var pattern = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
    if (pattern.test($event)) {
      return $event;
    } else {
      console.log($event);
    }
  }
}
