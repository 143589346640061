import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModelAttributeOptions } from '@app/shared/models/options';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { UserService } from '@app/shared/services/api/user.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { TimeConvertService } from '@app/shared/services/time-convert.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-holiday-create',
  templateUrl: './holiday-create.component.html',
  styleUrls: ['./holiday-create.component.scss']
})
export class HolidayCreateComponent implements OnInit {
  public formGroup: FormGroup = new FormGroup({
    note: new FormControl(''),
    type: new FormControl(null, Validators.required),
    user_id: new FormControl(null, Validators.required),
    end_time: new FormControl(null, Validators.required),
    start_time: new FormControl(null, Validators.required),
  });
  public users = [];
  public types = [];
  public statuses = [];
  public user_id = null;
  constructor(
    public loader: AppLoaderService,
    public userService: UserService,
    public maoService: ModelAttributeOptionService,
    private timeConvertService: TimeConvertService,
    public dialogRef: MatDialogRef<HolidayCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      if (this.data.user_id) {
        this.user_id = this.data.user_id;
      }
     }

  ngOnInit() {
    this.initOptions();
  }

  initOptions() {
    let loader = this.loader.open();
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.types = data.user_holiday.type;
      this.statuses = data.user_holiday.status;
    });
    this.userService
      .all()
      .finally(() => loader.close())
      .subscribe((data) => {
        this.users = data;
        if (this.user_id) {
          this.formGroup.patchValue({
            user_id: parseInt(this.user_id)
          });
        }
      });
  }

  submit() {
    let loader = this.loader.open();
    let formGroupValue = this.formGroup.value;
    formGroupValue['end_time'] = this.timeConvertService.convert(this.formGroup.controls['end_time'].value);
    formGroupValue['start_time'] = this.timeConvertService.convert(this.formGroup.controls['start_time'].value);
    this.userService
      .createHoliday(formGroupValue)
      .finally(() => loader.close())
      .subscribe((data) => {
        this.dialogRef.close(data);
      });
  }

  back() {
    this.dialogRef.close(false);
  }

}
