import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {environment} from '@env/environment';

import {AuthGuard} from '@app/shared/services/system/auth/auth.guard';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(
    private authGuard: AuthGuard,
    private globalSetting: GlobalSettingService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url[0] !== '.') {
      // api request
      const apiUri = this.globalSetting.getConfig('apiUri', environment.apiURL + environment.adminPrefix);
      request = request.clone({
        url: apiUri + request.url,
        setHeaders: this.authGuard.appendHeaders(),
      });
    }
    return next.handle(request);
  }
}
