import {Injectable} from '@angular/core';
import {ModelAttributeOptionService} from '@app/shared/services/api/model-attribute-option.service';
import {get, groupBy, set} from 'lodash';
import {FormControl, FormGroup} from '@angular/forms';
import {ModelAttributeOptions, ServiceOptions} from '@shared/models/options';

@Injectable({
  providedIn: 'root'
})
export class ServiceExtraService {
  public formGroup: FormGroup = new FormGroup({});
  public extraForm: {}[] = [];
  public serviceOptions: ServiceOptions;

  constructor(
    public maoService: ModelAttributeOptionService
  ) {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.serviceOptions = data.service;
    });
    this.maoService.getServiceDataDefaultFormList().subscribe(data => {
      let form = {};
      let groupData = groupBy(data, 'data.group.value');
      for (const groupName in groupData) {
        if (groupData.hasOwnProperty(groupName)) {
          const groupItem = groupData[groupName];
          let itemForm = {};
          groupItem
            .sort((a, b) => {
              let aOrder = get(a, 'data.order', 0);
              let bOrder = get(b, 'data.order', 0);
              return aOrder - bOrder;
            })
            .map(item => {
              if (item.data['display'] && item.data['display']['fm']) {
                set(itemForm, item.option_value, new FormControl(''));
              }
            });
          if (Object.keys(itemForm).length) {
            set(form, groupName, new FormGroup(itemForm));
          }
        }
      }
      this.formGroup = new FormGroup(form);
      this.extraForm = data;
    });
  }

  public setFormGroup(extra, options: { recall?: boolean; warranty?: boolean;  avoid_group?: any[]} = {}) {
    for (const itemGroupKey in this.formGroup.controls) {
      if (!options.avoid_group || options.avoid_group.indexOf(itemGroupKey) < 0) {
        if (this.formGroup.get(itemGroupKey)) {
          this.formGroup.get(itemGroupKey).patchValue(extra);
          if (this.formGroup.get(itemGroupKey).get('recall') && options['recall']) {
            this.formGroup.get(itemGroupKey).get('recall').setValue('yes');
          }
          if (this.formGroup.get(itemGroupKey).get('warranty') && options['warranty']) {
            this.formGroup.get(itemGroupKey).get('warranty').setValue('yes');
          }
        }
      }
    }
  }

  all() {
    let result = {};
    for (const i in this.formGroup.value) {
      if (this.formGroup.value.hasOwnProperty(i)) {
        const element = this.formGroup.value[i];
        result = {...result, ...element};
      }
    }
    return result;
  }

  clear() {
    this.formGroup.reset();
  }
}
