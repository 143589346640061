import {Component, OnInit, Injector, Input} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {InterfaceBaseTable} from '@shared/interfaces/interface-base-table';
import {AssetService} from '@shared/services/api/asset.service';
import {tableConfigs} from '@shared/config';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

@Component({
  selector: 'app-asset-table',
  templateUrl: './asset-table.component.html',
  animations: egretAnimations
})
export class AssetTableComponent extends BaseTableComponent
  implements OnInit, InterfaceBaseTable {
  tableConfig = tableConfigs.asset;
  @Input() inPopup = false;

  public get assetConditions() {
    return this._service.conditions;
  }

  public items: any[];

  constructor(
    public _service: AssetService,
    protected injector: Injector,
    private globalSettingService: GlobalSettingService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        if (this.globalSettingService.config.useCompanyId && this.globalSettingService.config.contractors.length === 1) {
          this.refresh();
        }
      });
    this.globalSettingService.config$.subscribe((config: GlobalSettingConfig) => {
      if (config.useCompanyId) {
        this.refresh();
      }
    });
  }
}
