import { BuildingModel } from "./../../../../models/building.model";
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModelAttributeOptionService } from "@shared/services/api/model-attribute-option.service";
import { CompanyService } from "@shared/services/api/company.service";
import { BaseCreateComponent } from "@shared/components/base/base-create.component";
import { TableConfigModel } from "@shared/models/table-config.model";
import { tableConfigs } from "@shared/config";
import { AssetService } from "@shared/services/api/asset.service";
import { CompanyModel } from "@app/shared/models/company.model";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { PpmService } from "@app/shared/services/api/ppm.service";

@Component({
  selector: "app-asset-create",
  templateUrl: "./asset-create.component.html",
})
export class AssetCreateComponent
  extends BaseCreateComponent
  implements OnInit {
  public ppms = [];
  public rooms = [];
  public filteredCategories: Observable<any[]>;
  tableConfig: TableConfigModel = tableConfigs.asset;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Input() data: { companyId?: number; buildingId?: number; category?: string, ppm_id?: string };
  public itemForm = new FormGroup({
    company_id: new FormControl(null, [Validators.required]),
    building_id: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    category: new FormControl(""),
    description: new FormControl(""),
    service_interval: new FormControl(""),
    last_service_time: new FormControl(""),
    next_service_time: new FormControl(""),
  });
  public dataForm = new FormGroup({
    ppm_id: new FormControl(null),
    notification_period: new FormControl(0),
    // vehicle_make: new FormControl(''),
    asset_condition: new FormControl(""),
    compliant_status: new FormControl(""),
    installation_date: new FormControl(""),
    warranty_end_date: new FormControl(""),
    asset_tag: new FormControl(""),
    model_number: new FormControl(""),
    serial_number: new FormControl(""),
    life_span: new FormControl(""),
    room_id: new FormControl(null),
  });

  public get companies(): CompanyModel[] {
    return this.companyService.managementCompanies;
  }

  public buildings: BuildingModel[] = [];

  public get options() {
    return this._service.options;
  }

  constructor(
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private _service: AssetService,
    private ppmService: PpmService,
    private translate: TranslateService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fullItem();
  }

  fullItem() {
    this.itemForm
      .get("company_id")
      .valueChanges.subscribe((company_id: any) => {
        this.buildings = [];
        this.itemForm.controls['building_id'].setValue(null);
        if (company_id) {
          this.getBuildings(company_id);
        }
      });
    this.itemForm
      .get("building_id")
      .valueChanges.subscribe((building_id: any) => {
        this.ppms = [];
        this.rooms = [];
        if (building_id) {
          this.getPPMs(building_id);
          this.buildings.some((building) => {
            if (building.id == building_id) {
              this.rooms = _.get(building, 'data.rooms', []);
              return true;
            }
            return false;
          });
        }
      });
    if (_.get(this.data, "category")) {
      this.itemForm.patchValue({
        category: _.get(this.data, "category"),
      });
    }
    if (_.get(this.data, "ppm_id")) {
      this.dataForm.patchValue({
        ppm_id: parseInt(_.get(this.data, "ppm_id")),
      });
    }
    console.log(this.dataForm.value);
    if (_.get(this.data, "companyId")) {
      this.itemForm.patchValue({
        company_id: _.get(this.data, "companyId"),
      });
    }
    if (_.get(this.data, "buildingId")) {
      this.itemForm.patchValue({
        building_id: _.get(this.data, "buildingId"),
      });
    }

    this.filteredCategories = this.itemForm.controls[
      "category"
    ].valueChanges.pipe(
      startWith(""),
      map((value) => {
        if (this.options) {
          const filterValue = value.toLowerCase();
          return this.options.category.filter((option) =>
            option.option_value
              .replace("_", " ")
              .toLowerCase()
              .includes(filterValue)
          );
        } else {
          return [];
        }
      })
    );
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService
      .getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
      });
  }

  getPPMs(building_id) {
    let loader = this.loader.open();
    this.ppmService
      .readByBuilding(building_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.ppms = data.ppms;
      });
  }

  submit() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    let dataFormValue = this.dataForm.value;
    itemFormValue["last_service_time"] = this.timeConvertService.convert(
      itemFormValue["last_service_time"]
    );
    itemFormValue["next_service_time"] = this.timeConvertService.convert(
      itemFormValue["next_service_time"]
    );
    dataFormValue["installation_date"] = this.timeConvertService.convert(
      dataFormValue["installation_date"]
    );
    dataFormValue["warranty_end_date"] = this.timeConvertService.convert(
      dataFormValue["warranty_end_date"]
    );
    this._service
      .store({ ...itemFormValue, data: dataFormValue })
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit(true);
        this._service.refreshOptions();
        this.toast.show("Asset Added!");
      });
  }

  displayWith() {
    let that = this;
    return (value) => {
      if (value) {
        return that.translate.instant(value);
      }
      return undefined;
    };
  }

  searchRoom(term, item) {
    return ("" + item.floor + "" + item.name).toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }
}
