import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-service-create-popup',
  template: `
  <div mat-dialog-title>
    <span>{{'Service Create' | translate}}</span>
    <div fxFlex></div>
    <a matTooltip="Close">
      <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </a>
  </div>
    <app-service-create mat-dialog-content [service_type]="service_type" (onSubmitted)="dialogRef.close(true)"
                      (onBack)="dialogRef.close()"></app-service-create>`
})
export class ServiceCreatePopupComponent implements OnInit {
  public service_type = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { service_type: any },
    public dialogRef: MatDialogRef<ServiceCreatePopupComponent>,
  ) {
    this.service_type = data.service_type;
  }

  ngOnInit() {
  }
}
