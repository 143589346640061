import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {DashboardService} from '@shared/services/api/dashboard.service';
import {LayoutService} from '@shared/services/system/layout.service';
import {dashboardConfigs} from '@shared/config';
import * as _ from 'lodash';
import {ThemeService} from '@shared/services/system/theme.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';

@Component({
  selector: 'app-quotation-dashboard',
  templateUrl: './quotation-dashboard.component.html',
  animations: egretAnimations
})
export class QuotationDashboardComponent implements OnInit, OnDestroy {
  _ = _;
  public loading = false;
  public dashboard;
  public displays = dashboardConfigs.quotation;
  @Output() onClickDashboard = new EventEmitter();
  private destroy = false;

  constructor(
    private dashboardService: DashboardService,
    private layout: LayoutService,
    public theme: ThemeService,
    private globalSettingService: GlobalSettingService,
  ) {
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.loading = true;
    this.dashboardService.getQuotationDashboard()
      .finally(() => this.loading = false)
      .subscribe((data) => {
        this.dashboard = data;
      });
  }

  ngOnDestroy() {
    this.destroy = true;
  }

  openTable(dashboardGroup, dashboardType) {
    this.onClickDashboard.emit({group: dashboardGroup, item: dashboardType});
  }
}
