import {Component, OnInit, Injector, Input} from '@angular/core';
import {egretAnimations} from '@shared/animations/egret-animations';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {QuotationService} from '@shared/services/api/quotation.service';
import {InterfaceBaseTable} from '@shared/interfaces/interface-base-table';
import {tableConfigs} from '@shared/config';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {LayoutService} from '@shared/services/system/layout.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';

@Component({
  selector: 'app-quotation-table',
  templateUrl: './quotation-table.component.html',
  animations: egretAnimations
})
export class QuotationTableComponent extends BaseTableComponent implements OnInit, InterfaceBaseTable {
  @Input() dashboardType = '';
  @Input() dashboardGroup = '';
  @Input() inPopup = false;
  conditions = this._service.conditions;
  tableConfig = tableConfigs.quotation;

  public items: any[];

  constructor(
    public injector: Injector,
    public _service: QuotationService,
    private globalSettingService: GlobalSettingService,
    public layout: LayoutService,
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.dashboardType && this.dashboardGroup) {
      this.conditions.dashboard_type.value = {
        item: this.dashboardType,
        group: this.dashboardGroup
      };
    }
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.useCompanyId) {
          this.refresh();
        }
      });
  }

  
  changeCondition() {    
    if (this.conditions.client_id.value) {
      this._service.buildingsByClient(this.conditions.client_id.value)
        .subscribe((data: any) => {
          this.conditions.building_id.items = data.filter((item) => {
            return item.status == 'active';
          });
        })
    } else {
      this.conditions.building_id.items = [];
      this.conditions.building_id.value = null;
    }

    this.refresh();
  }
}
