import {Injectable} from '@angular/core';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {HttpClient} from '@angular/common/http';
import {Conditions} from '@shared/models/conditions.model';
import {ModelAttributeOptions, ServiceOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import { CompanyService } from './company.service';
import * as _ from "lodash";
import { GlobalSettingService } from '../system/global-setting.service';

@Injectable({
  providedIn: 'root'
})
export class PpmService {
  route_prefix = 'ppms';
  public conditions: Conditions = {
    client: {
      value: "",
      nullable: true,
      items: [],
      display_key: "name",
      select_key: "id",
      display_name: "Client",
    },
    building: {
      value: '',
      nullable: false,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Building',
      multi: true
    },
    category: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Category',
      hidden: true
    },
    status: {
      value: '',
      nullable: true,
      items: [
        { option_value: 'normal', option_name: 'normal' },
        { option_value: 'due', option_name: 'due' },
        { option_value: 'over_due', option_name: 'over_due' }
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status',
      hidden: true
    },
    dashboard_type: {
      value: '',
      nullable: true,
      items: [
        {option_value: 'ppm_to_log', option_name: 'PPM to Log'},
        {option_value: 'upcoming_ppms', option_name: 'Upcoming PPMs'},
        {option_value: 'over_due_ppms_not_logged', option_name: 'Over Due PPMs Not Logged'}
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Dashboard Type',
      hidden: true
    }
  };

  public options: ServiceOptions;

  constructor(
    public http: HttpClient,
    private global: GlobalSettingService,
    private companyService: CompanyService,
    private mao: ModelAttributeOptionService,
  ) {
    this.getOptions();
  }

  getOptions() {
    this.mao.all().subscribe((data: ModelAttributeOptions) => {
      this.options = data.service;
      this.conditions.category.items = data.asset.category;
    });
    this.companyService.getClients().subscribe((data: any) => {
      this.conditions.client.items = data;
      const clientId = _.get(this.global, "config.client", null);
      if (clientId) {
        this.conditions.client.value = clientId;
        this.buildingsByClient(this.global.config.client).subscribe(
          (data: any) => {
            this.conditions.building.items = data.filter((item) => {
              return item.status == "active";
            });
          }
        );
      } else {
        this.conditions.building.items = [];
        this.conditions.building.value = null;
      }
    });
  }

  index(params: any | LaravelPageRequest) {
    return this.http.post('ppm/assets/index', params.request());
  }

  search(param: any) {
    return this.http.post('ppm/assets/search', param);
  }

  searchPPMServices(param: any) {
    return this.http.post('ppm/assets/search-ppm-services', param);
  }

  store(param: any) {
    return this.http.post(this.route_prefix, param);
  }

  bulkPMMServiceCreate(param: any) {
    return this.http.post('ppm/bulk/service-create', param);
  }

  assetDashboard() {
    return this.http.post('ppm/asset_dashboard', null);
  }

  buildings() {
    return this.http.get('ppm/buildings');
  }

  buildingsByClient(clientId) {
    return this.http.get(`services/${clientId}/buildings`);
  }

  readByBuilding(building_id) {
    return this.http.get('ppm/read-by-building', {params: {building_id: building_id}});
  }
}
