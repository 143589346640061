import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Conditions} from '@shared/models/conditions.model';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';

export class User {
  id: number;
  name: string;
  email: string;
  company: string;
}

@Injectable()
export class UserService {
  resource_prefix = 'users';
  public users: User[] = [];
  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [
        {option_value: 'active'},
        {option_value: 'inactive'},
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    }
  };

  constructor(private http: HttpClient) {
  }

  accountantsByCompanyId(companyId = '') {
    return this.http.get(this.resource_prefix + '/accountants-by-company-id/' + companyId);
  }

  accountantsByServiceId(serviceId = '') {
    return this.http.get(this.resource_prefix + '/accountants-by-service-id/' + serviceId);
  }

  suppliersByCompanyId(companyId = '') {
    return this.http.get(this.resource_prefix + '/suppliers-by-company-id/' + companyId);
  }

  engineersByCompanyId(companyId = '') {
    return this.http.get(this.resource_prefix + '/engineers-by-company-id/' + companyId);
  }

  all({ company_ids } = { company_ids: [] }): Observable<User[] | any> {
    return this.http.post(`${this.resource_prefix}/all`, { company_ids });
  }

  index(params: LaravelPageRequest) {
    return this.http.get(this.resource_prefix, { params: params.request() });
  }

  store(data) {
    return this.http.post(this.resource_prefix, data);
  }

  show(id) {
    return this.http.get(this.resource_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.resource_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.resource_prefix + '/' + id, data);
  }

  getUserCompanyRelation(id) {
    return this.http.get(this.resource_prefix + '/' + id + '/user_company');
  }

  updateUserCompanyRelation(id, params) {
    return this.http.post(this.resource_prefix + '/' + id + '/user_company', params);
  }

  readRoles() {
    return this.http.get(this.resource_prefix + '/read-roles');
  }

  readEmailEvents() {
    return this.http.get(this.resource_prefix + '/read-email-events');
  }

  getUserHolidays(id, params: LaravelPageRequest) {
    return this.http.get(this.resource_prefix + '/' + id + '/user-holidays', {params: params.request()});
  }

  createHoliday(data) {
    return this.http.post('user-holidays/create', data);
  }

  updateHoliday(data) {
    return this.http.post('user-holidays/update', data);
  }

  deleteHoliday(data) {
    return this.http.post('user-holidays/delete', data);
  }

  approvalHoliday(data) {
    return this.http.post('user-holidays/approval', data);
  }

  readHoliday(data) {
    return this.http.post('user-holidays/read', data);
  }
}
