import { Component, Injector, Input, OnInit } from '@angular/core';
import { ThemeService } from '@shared/services/system/theme.service';
import { egretAnimations } from '@shared/animations/egret-animations';
import { Router } from '@angular/router';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import 'rxjs-compat/add/operator/finally';
import { LayoutService } from '@shared/services/system/layout.service';
import { NotifyService } from '@app/shared/services/notify.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-base-create',
  template: ``,
  animations: egretAnimations
})
export class BaseCreateComponent implements OnInit {
  @Input() public inPopup: false;
  // providers

  protected theme: ThemeService;
  protected router: Router;
  protected confirmService: AppConfirmService;
  protected loader: AppLoaderService;
  protected toast: NotifyService;
  protected layout: LayoutService;
  protected timeConvertService: TimeConvertService;

  constructor(protected injector: Injector) { }

  ngOnInit() {
    this.theme = this.injector.get(ThemeService);
    this.router = this.injector.get(Router);
    this.confirmService = this.injector.get(AppConfirmService);
    this.loader = this.injector.get(AppLoaderService);
    this.toast = this.injector.get(NotifyService);
    this.layout = this.injector.get(LayoutService);
    this.timeConvertService = this.injector.get(TimeConvertService);
  }

  gotoTable() {
    this.router.navigate([`/${this['tableConfig'].urlPrefix}`]);
  }
}
