import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { DocumentService } from '@app/shared/services/api/document.service';

@Component({
  selector: 'app-purchase-order-edit-popup',
  template: `
    <div mat-dialog-title>
      <span class="pr-1">{{'Purchase Order Edit' | translate}} {{id}}</span>
      <a class="pr-1">
        <mat-chip mat-sm-chip color="accent" selected (click)="viewPdf()" matTooltip="View PDF">
          {{'View'|translate}}
        </mat-chip>
      </a>
      <div fxFlex></div>
      <a matTooltip="Close">
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
      </a>
    </div>
    <app-purchase-order-edit mat-dialog-content [id]="id" [inPopup]="true" (onSubmitted)="dialogRef.close(true)"></app-purchase-order-edit>`
})
export class PurchaseOrderEditPopupComponent implements OnInit {
  public id;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private documentService: DocumentService,
    public dialogRef: MatDialogRef<PurchaseOrderEditPopupComponent>,
  ) {
    if (this.data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
  }

  viewPdf() {
    this.documentService.viewPdf('purchase_order', this.id);
  }
}
