import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { egretAnimations } from "@shared/animations/egret-animations";
import { DashboardService } from "@shared/services/api/dashboard.service";
import { ThemeService } from "@shared/services/system/theme.service";
import { ServiceDashboardModel } from "@shared/models/service-dashboard.model";
import * as _ from "lodash";
import { LayoutService } from "@shared/services/system/layout.service";
import { dashboardConfigs } from "@shared/config";
import { GlobalSettingService } from "@app/shared/services/system/global-setting.service";
import { MatDialog } from "@angular/material";
import { FinanceManagementTableComponent } from "./finance-management-table/finance-management-table.component";
import { ServiceService } from "@shared/services/api/service.service";
import { GlobalSettingConfig } from "@shared/interfaces/global-setting-config";

@Component({
  selector: "app-service-dashboard",
  templateUrl: "./service-dashboard.component.html",
  animations: egretAnimations,
})
export class ServiceDashboardComponent implements OnInit, OnDestroy {
  _ = _;
  subOnRefresh;
  subOnSwitchView;
  private destroy = false;
  private un_init = true;

  constructor(
    public dashboardService: DashboardService,
    public serviceService: ServiceService,
    public theme: ThemeService,
    private layout: LayoutService,
    private dialog: MatDialog,
    private globalSettingService: GlobalSettingService
  ) {}

  ngOnInit() {
    this.subOnRefresh = this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        if (this.layout.layoutConf.switchTable == "panel") {
          this.dashboardService.refreshServiceDashboard();
        }
      });
    this.subOnSwitchView = this.layout.onSwitchView
      .takeWhile(() => !this.destroy)
      .subscribe((view) => {
        if (view == "panel") {
          if (this.un_init) {
            this.dashboardService.refreshServiceDashboard();
          }
        } else {
          this.dashboardService.cancelServiceDashboard();
        }
      });
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (
          config.useCompanyId &&
          this.layout.layoutConf.switchTable == "panel"
        ) {
          this.dashboardService.refreshServiceDashboard();
        }
      });
  }

  openTableWithFinanceManagement(type) {
    this.dialog.open(FinanceManagementTableComponent, {
      data: { type },
      width: "80%",
      panelClass: ["no-padding-dialog"],
    });
  }

  ngOnDestroy() {
    if (this.subOnRefresh) {
      this.subOnRefresh.unsubscribe();
    }
    this.dashboardService.cancelServiceDashboard();
    this.destroy = true;
  }

  getTabSum(displayKey) {
    let total = 0;
    for (const key in _.get(
      this.dashboardService.serviceDashboard,
      displayKey,
      []
    )) {
      if (
        this.dashboardService.serviceDashboard[displayKey].hasOwnProperty(key)
      ) {
        const element = this.dashboardService.serviceDashboard[displayKey][key];
        total += element;
      }
    }
    return total;
  }
}
