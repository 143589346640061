import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Conditions } from "@shared/models/conditions.model";
import { isArray } from "lodash";
import { isObject } from "lodash";
import { isNumber } from "lodash";
import { isString } from "lodash";
import * as _ from 'lodash';

@Component({
  selector: "app-table-condition",
  templateUrl: "./condition.component.html",
})
export class AppTableConditionComponent implements OnInit {
  @Input() conditions: Conditions;
  @Output() changeConditionEvent = new EventEmitter<any>();
  isArray = isArray;
  isObject = isObject;
  isNumber = isNumber;
  isString = isString;

  objectKeys = Object.keys;

  constructor(private _translate: TranslateService) { }

  ngOnInit() { }

  changeCondition() {
    this.changeConditionEvent.emit(this.conditions);
  }

  removeValue(conditionKey, value) {
    if (isArray(value)) {
      this.conditions[conditionKey].value.filter(
        (condition) => condition[conditionKey].value != value
      );
    } else {
      this.conditions[conditionKey].value = "";
    }
    this.changeCondition();
  }

  filterFn() {
    return true;
  }

  compareFn(a, b) {
    return a == b || (a.item && b.item && a.item == b.item && a.group == b.group)
    || (isObject(a) && isNumber(b) && (a.id==b));
  }

  compare(object, base) {
    const that = this;
    return _.transform(
      object,
      function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? that.compare(value, base[key])
              : value;
        }
      }.bind(that)
    );
  }

  filter(conditionKey, $event) {
    let keywords = $event.term.toLowerCase();
    this.conditions[conditionKey].filter_items = this.conditions[
      conditionKey
    ].items.filter((item) => {
      return this.conditions[conditionKey].filter_keys.some((key) => {
        let str = '' + _.get(item, key, '');
        return (
          str.toLowerCase().indexOf(keywords) >= 0 ||
          (str.length > 0 && this._translate.instant(str).indexOf(keywords) > 0)
        );
      });
    });
  }
}
