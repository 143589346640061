import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { RoutePartsService } from '@shared/services/system/route-parts.service';
import { ThemeService } from '@shared/services/system/theme.service';

import { environment } from '@env/environment';
import { Logger } from '@app/shared/services/system/logger.service';

import { filter } from 'rxjs/operators';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { AppSelectCompanyService } from '@shared/services/system/app-select-company/app-select-company.service';
import { AuthGuard } from '@shared/services/system/auth/auth.guard';
import { NavigationService } from './shared/services/system/navigation.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { RoleService } from './shared/services/role.service';
import { SettingsService } from './shared/services/system/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Aims';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private globalSettingService: GlobalSettingService,
    private navService: NavigationService,
    private selectCompanyService: AppSelectCompanyService,
    private auth: AuthGuard,
    private config: NgSelectConfig,
    private rule: RoleService,
    private settings: SettingsService,
  ) {
    this.config.notFoundText = 'Not found';
  }

  ngOnInit() {
    this.onPageChange();
    if (environment.production) {
      Logger.enableProductionMode();
    }
    if (this.auth.isLogin()) {
      this.rule.initRoles();
      this.settings.init();
    }
    this.navService.menuItems$.subscribe((menus) => {
      this.globalSettingService.firstPageOptions = menus
        .filter((item) => {
          return item['state'] && item['name']
        })
        .map((item) => {
          return {
            name: item.name,
            value: item.state
          };
        })
    });
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
    setTimeout(() => {
      this.settings.initTranslation();
    }, 618 * 2);
  }

  onPageChange() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange: NavigationEnd) => {
      if (routeChange.url === '/') {
        this.router.navigate(['/' + this.globalSettingService.getConfig('first_page')]);
      }
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
      if (this.auth.isLogin()) {
        this.checkUseCompanyIdIsSet();
      }
    });
  }

  checkUseCompanyIdIsSet() {
    if (!this.globalSettingService.getConfig('useCompanyId')) {
      this.selectCompanyService.show();
    }
  }
}
