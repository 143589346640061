import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {InvoiceItemOptions, ServiceOptions} from '@shared/models/options';
import {CompanyService} from '@shared/services/api/company.service';
import {BaseEditComponent} from '@shared/components/base/base-edit.component';
import {InterfaceBaseEdit} from '@shared/interfaces/interface-base-edit';
import {tableConfigs} from '@shared/config';
import {InvoiceItemService} from '@shared/services/api/invoice-item.service';

@Component({
  selector: 'app-invoice-item-create',
  templateUrl: './invoice-item-create.component.html',
  styleUrls: ['./invoice-item-create.component.scss']
})
export class InvoiceItemCreatePopupComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.invoice_item;
  @Input() id;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemFormGroup: FormGroup;
  public buildings;
  public options: InvoiceItemOptions;
  public lineTotal = 0;
  public serviceOptions: ServiceOptions;

  public get clients() {
    return this.companyService.clients;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<InvoiceItemCreatePopupComponent>,
    private fb: FormBuilder,
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public injector: Injector,
    public _service: InvoiceItemService
  ) {
    super(injector);
    if (data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildItemForm();
    this.getOptions();
  }

  buildItemForm() {
    this.itemFormGroup = this.fb.group({
      item_type: ['', Validators.required],
      item_name: ['', [Validators.required, Validators.maxLength(255)]],
      item_description: [''],
      item_unit_price: [
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ],
      item_quantity: [
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ],
      item_status: ['', Validators.required]
    });

    this.itemFormGroup
      .get('item_unit_price')
      .valueChanges.subscribe(item_unit_price => {
      let item_quantity = this.itemFormGroup.value.item_quantity || 0;
      let price = item_unit_price || 0;
      this.lineTotal = item_quantity * price;
    });
    this.itemFormGroup
      .get('item_quantity')
      .valueChanges.subscribe(item_quantity => {
      let q = item_quantity || 0;
      let price = this.itemFormGroup.value.item_unit_price || 0;
      this.lineTotal = q * price;
    });
  }

  getOptions() {
    this.maoService
      .all()
      .subscribe((data: any) => {
        this.serviceOptions = data.service;
        this.options = data.invoice_item;
      });
  }

  submit() {
    let data = {...this.itemFormGroup.value, invoice_id: this.id};
    let loader = this.loader.open();
    this._service
      .store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Invoice Item Added!');
        if (this.data) {
          this.dialogRef.close(true);
        }
      });
  }

  close() {
    if (this.data) {
      this.dialogRef.close();
    }
  }
}
