import {Component, OnInit, Inject} from '@angular/core';
import {ModelAttributeOptions, QuotationItemOptions} from '@app/shared/models/options';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {ModelAttributeOptionService} from '@app/shared/services/api/model-attribute-option.service';
import {ApplicationItemService} from '@app/shared/services/api/application-item.service';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {AppConfirmService} from '@app/shared/services/system/app-confirm/app-confirm.service';
import {ServiceModel} from '@shared/models/service.model';
import {ApplicationService, KPIItem} from '@shared/services/api/application.service';
import {SaleModel} from '@shared/models/sale.model';
import {ApplicationItemModel} from '@shared/models/application-item.model';
import {ServiceVisitModel} from '@shared/models/service-visit.model';
import {ServiceEditPopupComponent} from '@shared/components/business/service/service-edit/service-edit-popup.component';
import {ServiceVisitEditPopupComponent} from '@shared/components/business/service-visit/service-visit-edit/service-visit-edit-popup.component';

@Component({
  selector: 'app-application-item-edit',
  templateUrl: './application-item-edit.component.html',
  styleUrls: ['./application-item-edit.component.scss']
})
export class ApplicationItemEditComponent implements OnInit {
  public id;
  public hasEdit = false;
  public itemForm: FormGroup = new FormGroup({
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_price: new FormControl(0),
    item_total_price: new FormControl(0),
    item_quantity: new FormControl(0),
    item_status: new FormControl(''),
    operator: new FormControl(''),
  });
  public item: ApplicationItemModel;
  public options: QuotationItemOptions;

  public serviceDetail: {
    service: ServiceModel,
    kpi: {
      attendance_sla: KPIItem;
      aims_app: KPIItem;
      compliance_certificates: KPIItem;
      emergency_job_with_2_hours_response_time: KPIItem;
      emergency_job_with_4_hours_response_time: KPIItem;
      first_time_completion: KPIItem;
      job_acceptance: KPIItem;
      multiple_visits: KPIItem
      no_use_app: KPIItem;
      ppm_service_sla: KPIItem;
      recall_and_warranty_services: KPIItem;
      service_sla: KPIItem;
    };
  };

  public saleEditing = {};
  public response: {
    application_item: ApplicationItemModel;
    KPI_total: number;
    labours: {}[],
    labours_total: number;
    materials_total: number;
    others_total: number;
    plants_total: number;
    sales: SaleModel[];
    sales_total: number;
    service: ServiceModel;
    service_visits: ServiceVisitModel[];
  };

  constructor(
    public dialogRef: MatDialogRef<ApplicationItemEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public maoService: ModelAttributeOptionService,
    public applicationItemService: ApplicationItemService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private confirm: AppConfirmService,
    private applicationService: ApplicationService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.id = this.data.id;
    this.refresh();
    this.getOptions();
  }

  openEditVisit(visit: ServiceVisitModel) {
    this.dialog.open(ServiceVisitEditPopupComponent, {
      width: '80%',
      disableClose: false,
      data: {id: visit.id, service: this.response.service}
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.refresh();
      }
    });
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  openServiceEdit(serviceId) {
    this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      data: {id: serviceId}
    });
  }

  showComments(comments: string[]) {
    return comments.join('\n');
  }

  detail() {
    let loader = this.loader.open();
    this.applicationItemService.detail(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data['application_item'];
        this.response = data;
        this.itemForm.patchValue(this.item);
        if (data['application_item']['item_type'] === 'service') {
          this.doIfIsService();
        }
      });
  }

  doIfIsService() {
    let loader = this.loader.open();
    this.applicationService.getServiceKPIByApplicationItemId(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.serviceDetail = data;
      });
  }

  refresh() {
    this.detail();
  };

  onChangeKPI() {
    this.hasEdit = true;
    this.refresh();
  }

  submit() {
    let data = {...this.itemForm.value};
    let loader = this.loader.open();
    this.applicationItemService.update(this.id, data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Application item updated!');
        this.dialogRef.close(true);
      });
  }

  review(type) {
    const item = this.item;
    this.dialog.open(ApplicationItemReviewComponent, {
      width: '400px',
      data: {
        type: type,
        item_unit_price: item.item_unit_price,
        item_quantity: item.item_quantity,
      },
    }).afterClosed().subscribe((res) => {
      if (res) {
        const loader = this.loader.open();
        this.applicationItemService.review(this.id, res)
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show(`${type} success`);
            this.hasEdit = true;
            this.refresh();
          });
      }
    });
  }

  updateSaleValue(event, row, rowIndex) {
    const value = event.target.value;
    this.saleEditing[rowIndex + '-' + 'name'] = false;
    this.confirm.confirm({message: `Sure change unit price to ${value}? `}).subscribe((res) => {
      if (res) {
        this.applicationItemService.editSale(row.id, value, this.id).subscribe(() => {
          this.toast.show('Update sale success');
          this.refresh();
          this.hasEdit = true;
        });
      }
    });
  }
}


@Component({
  selector: 'app-application-item-reject',
  template: `
    <h1 mat-dialog-title fxLayout="column">
      <span fxFlexAlign="center">Confirm</span>
    </h1>
    <div mat-dialog-content>
      <div fxLayout="column">
        <span fxFlexAlign="center">{{data?.type | translate}}</span>
      </div>
      <form [formGroup]="formGroup" class="full-width">
        <mat-form-field class="full-width" *ngIf="data.type==='reduce'">
          <input matInput type="number" formControlName="item_unit_price" [placeholder]="'Unit price' | translate">
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="data.type==='reduce'">
          <input matInput type="number" formControlName="item_quantity" [placeholder]="'Quantity' | translate" disabled>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="data.type==='reduce'">
          <input matInput type="number" formControlName="item_total" [placeholder]="'Total' | translate" disabled>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="data.type==='reduce'">
          <input matInput type="number" formControlName="previous_total" [placeholder]="'Previous total' | translate" disabled>
        </mat-form-field>
        <mat-form-field class="full-width">
          <textarea matInput rows="2" [placeholder]="'Comment (optional)' | translate" formControlName="comment"></textarea>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions fxLayout="column">
      <div fxFlexAlign="center">
        <button mat-button (click)="dialogRef.close()">{{'Cancel' | translate}}</button>
        <button mat-raised-button color="primary" (click)="submit()">Ok</button>
      </div>
    </div>
  `
})
export class ApplicationItemReviewComponent implements OnInit {
  public formGroup = new FormGroup({
    item_unit_price: new FormControl(0),
    item_quantity: new FormControl({value: 0, disabled: true}),
    item_total: new FormControl({value: 0, disabled: true}),
    previous_total: new FormControl({value: 0, disabled: true}),
    comment: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ApplicationItemReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string; // reject, defer, approve, reduce
      item_unit_price?: number;
      item_quantity?: number;
    }
  ) {
    if (data.type === 'reduce') {
      this.formGroup.valueChanges.subscribe((change) => {
        this.formGroup.get('item_total').patchValue(change.item_unit_price * data.item_quantity, {emitEvent: false});
      });
      this.formGroup.patchValue({
        item_unit_price: data.item_unit_price,
        item_quantity: data.item_quantity,
        previous_total: data.item_quantity * data.item_unit_price,
      });
    }
  }

  ngOnInit() {
  }

  submit() {
    this.dialogRef.close({...this.formGroup.value, type: this.data.type});
  }
}
