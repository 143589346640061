import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ServiceCustomizeService } from '@app/shared/components/common/service-customize.service';
import { ServiceExtraService } from '@app/shared/components/common/service-extra.service';
import { AppTableComponent } from '@app/shared/components/system/table/table.component';
import { ServiceOptions, ModelAttributeOptions } from '@app/shared/models/options';
import { CompanyService } from '@app/shared/services/api/company.service';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { PpmService } from '@app/shared/services/api/ppm.service';
import { ServiceService } from '@app/shared/services/api/service.service';
import { AppConfirmService } from '@app/shared/services/system/app-confirm/app-confirm.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { TimeConvertService } from '@app/shared/services/time-convert.service';
import { addHours, addDays, addMonths } from 'date-fns';

@Component({
  selector: 'app-bulk-ppm-create',
  templateUrl: './bulk-ppm-create.component.html',
  styleUrls: ['./bulk-ppm-create.component.scss']
})
export class BulkPpmCreateComponent implements OnInit {
  ppms = [];
  formGroup: FormGroup = new FormGroup({
    discipline: new FormControl(null),
    subject: new FormControl(null),
    problem: new FormControl(null),
    severity: new FormControl(null),
    budget: new FormControl(0, Validators.required),
    expect_start_time: new FormControl(null),
    supplier_company: new FormControl([]),
    engineers: new FormControl([]),
  });
  engineers = [];
  serviceOptions: ServiceOptions;

  public get supplierCompanies() {
    return this.companyService.contractors;
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public _service: PpmService,
              public serviceService: ServiceService,
              public loader: AppLoaderService,
              public _confirm: AppConfirmService,
              public dialog_ref: MatDialogRef<BulkPpmCreateComponent>,
              private mao: ModelAttributeOptionService,
              private companyService: CompanyService,
              public customizeService: ServiceCustomizeService,
              public extraService: ServiceExtraService,
              public timeConvertService: TimeConvertService
  ) {
    this.ppms = this.data.ppms;
  }

  ngOnInit() {
    this.getOptions();
    this.formGroup.get('severity').valueChanges.subscribe((severity: string) => {
      let now = new Date();
      let retDate;
      if (severity.indexOf('hours') !== -1) {
        retDate = addHours(now, parseInt(severity.slice(0, severity.length - 6)))
      }
      if (severity.indexOf('days') !== -1) {
        retDate = addDays(now, parseInt(severity.slice(0, severity.length - 5)))
      }
      if (severity.indexOf('months') !== -1) {
        retDate = addMonths(now, parseInt(severity.slice(0, severity.length - 7)))
      }
      /*if (retDate) {
        this.formGroup.patchValue({
          expect_start_time: retDate.toISOString()
        })
      }*/
    });

    this.formGroup.get('supplier_company').valueChanges.subscribe(supplier_company_ids => {
      if (supplier_company_ids.length > 0) {
        this.getEngineers(supplier_company_ids);
      }
    });
  }

  resetForm() {
    this.formGroup.reset();
  }

  save() {
    this._confirm.confirm({message: `Adding these PPM service,confirm please.`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          let formGroupValue = this.formGroup.value;
          // formGroupValue['expect_start_time'] = this.timeConvertService.convert(formGroupValue['expect_start_time']);
          this._service.bulkPMMServiceCreate({
            ppms: this.ppms,
            ...formGroupValue,
            extra: this.extraService.all(),
            customize: this.customizeService.allObject()
          })
            .finally(() => loader.close())
            .subscribe((data: any) => {
              this.dialog_ref.close(true);
            });
        }
      });
  }

  getOptions() {
    this.mao.all()
      .subscribe((result: ModelAttributeOptions) => {
        this.serviceOptions = result.service;
      });
  }

  close() {
    this.dialog_ref.close(false);
  }

  getEngineers(supplierCompanyIds) {
    let loader = this.loader.open();
    this.serviceService
      .readSupplierEngineers(supplierCompanyIds)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.engineers = data;
        const oldAssignEngineers = this.formGroup.get('engineers').value;
        const newAssignEngineers = this.engineers.map((engineer) => {
          return oldAssignEngineers.indexOf(engineer.id) > -1 ? engineer.id : false;
        }).filter((id) => {
          return id;
        });
        this.formGroup.get('engineers').setValue(newAssignEngineers);
      });
  }

}
