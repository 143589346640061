import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material';
import {AppLoaderComponent} from './app-loader.component';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  public loaderIds = [];
  dialogRef: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  public open(title: string = 'Please wait', config: MatDialogConfig = {}) {
    if (!this.dialogRef) {
      let dialogRef = this.dialog.open(AppLoaderComponent, {
        disableClose: false,
        backdropClass: 'light-backdrop'
      });
      dialogRef.updateSize('200px');
      dialogRef.afterClosed().subscribe(() => {
        this.loaderIds = [];
        this.dialogRef = undefined;
      });
      this.dialogRef = dialogRef;
    }
    let str = new Date().getTime().toString();
    let loaderId = this.generateLoaderId(str, 0);
    this.loaderIds.push(loaderId);
    if (this.dialogRef) {
      this.dialogRef.componentInstance.loaderIds = this.loaderIds;
      this.dialogRef.componentInstance.title = title;
    }

    return {
      close: () => {
        this.loaderIds.splice(this.loaderIds.indexOf(loaderId), 1);
        this.loaderIds = [...this.loaderIds];
        if (this.dialogRef) {
          this.dialogRef.componentInstance.loaderIds = this.loaderIds;
          if (this.loaderIds.length === 0) {
            this.dialogRef.close();
          }
        }
      }
    };
  }

  private generateLoaderId(str, index) {
    if (this.loaderIds.indexOf(str + '-' + index) !== -1) {
      index += 1;
      return this.generateLoaderId(str, index);
    } else {
      return str + '-' + index;
    }
  }
}
