import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ApplicationItemService} from '@app/shared/services/api/application-item.service';
import {AppConfirmService} from '@app/shared/services/system/app-confirm/app-confirm.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {MatDialog} from '@angular/material';
import {ApplicationItemEditComponent} from '../application-item-edit/application-item-edit.component';
import {ApplicationService} from '@shared/services/api/application.service';

@Component({
  selector: 'app-application-item-table',
  templateUrl: './application-item-table.component.html',
})
export class ApplicationItemTableComponent implements OnInit {
  @Input() public items = [];
  @Output() needRefresh = new EventEmitter;

  constructor(
    public applicationItemService: ApplicationItemService,
    public applicationService: ApplicationService,
    private confirm: AppConfirmService,
    private toast: NotifyService,
    private loader: AppLoaderService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  getItemKPICount(sales) {
    if (sales) {
      return sales.filter((sale) => sale.item_type === 'kpi').length;
    } else {
      return 0;
    }
  }

  deleteItem(row) {
    this.confirm.confirm().subscribe((res) => {
      if (res) {
        let loader = this.loader.open();
        this.applicationItemService.destroy(row.id)
          .finally(() => loader.close())
          .subscribe(() => {
            this.toast.show('Delete success');
            this.needRefresh.emit({
              type: 'delete_item',
              application_id: row.id
            });
          });
      }
    });
  }

  editItem(id) {
    this.dialog.open(ApplicationItemEditComponent, {
      width: '80%',
      data: {id}
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.needRefresh.emit({
          type: 'edit_item',
          application_id: id,
        });
      }
    });
  }

  deleteServiceAllKPI(row) {
    let loader = this.loader.open();
    this.applicationService.deleteServiceAllKPI(row.item_type_id)
      .finally(() => loader.close())
      .subscribe(() => {
        this.needRefresh.emit({
          type: 'delete_all_kpi',
          application_id: row.id,
        });
        this.toast.show('Delete all kpi success');
      });
  }

  createItemKPI(applicationItemId) {
    let loader = this.loader.open();
    this.applicationService.createApplicationItemKPI(applicationItemId)
      .finally(() => loader.close())
      .subscribe(() => {
        this.needRefresh.emit({
          type: 'create_item_kpi',
          application_id: applicationItemId
        });
        this.toast.show('Auto create kpi success');
      });
  }

  showSaleDetailMessage(sale) {
    this.toast.show(sale.item_name + '\n' + sale.item_description);
  }
}
