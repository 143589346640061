import {Injectable} from '@angular/core';
import {ApiBaseService} from '@shared/services/api/api-base.service';
import {Conditions} from '@shared/models/conditions.model';
import {dashboardConfigs, tableConfigs} from '@shared/config';
import {TableConfigModel} from '@shared/models/table-config.model';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ModelAttributeOptionService} from './model-attribute-option.service';
import {ModelAttributeOptions, QuotationItemOptions, QuotationOptions} from '@app/shared/models/options';
import * as _ from 'lodash';
import { GlobalSettingService } from '../system/global-setting.service';
import { CompanyService } from './company.service';
import { LayoutService } from '../system/layout.service';

@Injectable()
export class QuotationService extends ApiBaseService {
  route_prefix = 'quotations';
  public quotationOptions: QuotationOptions;
  public quotationItemOptions: QuotationItemOptions;
  public defaultMargin = 0;
  public useMargin = 0;
  tableConfig: TableConfigModel = tableConfigs.quotation;

  public conditions: Conditions = {
    client_id: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Client'
    },
    building_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      can_filter: true,
      filter_items: null,
      filter_keys: ['name'],
      display_name: 'Building'
    },
    supplier_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Contractor',
      can_filter: true,
      filter_items: null,
      filter_keys: ['name'],
    },
    type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Type'
    },
    status: {
      value: '',
      nullable: true,
      items: [
        {option_value: 'pending'},
        {option_value: 'approved'},
        {option_value: 'declined'},
      ],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    dashboard_type: {
      value: {item: '', group: ''},
      nullable: true,
      useGroup: true,
      groups: dashboardConfigs.quotation,
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
      display_name: 'Dashboard Type'
    }
  };

  constructor(
    public http: HttpClient,
    public router: Router,
    private companyService: CompanyService,
    private global: GlobalSettingService,
    private layout: LayoutService,
    private maoService: ModelAttributeOptionService,
  ) {
    super(http, router);
    this.getOptions();

  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.quotation.status;
        this.conditions.type.items = data.quotation.type;
        this.quotationOptions = data.quotation;
        this.quotationItemOptions = data.quotation_item
      });
      
    this.companyService.getContractor().subscribe((data: any) => {
      this.conditions.supplier_id.items = data;
    });
    
    this.companyService.getClients().subscribe((data: any) => {
      this.conditions.client_id.items = data;
      const clientId = _.get(this.global, 'config.client', null);
      if (clientId) {
        this.conditions.client_id.value = clientId;
        this.buildingsByClient(this.global.config.client)
          .subscribe((data: any) => {
            this.conditions.building_id.items = data.filter((item) => {
              return item.status == 'active';
            });
          })
      } else {
        this.conditions.building_id.items = [];
      }
    });
  }

  approve(quotationId, body: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/approve`, body);
  }

  reject(quotationId, body: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/reject`, body);
  }

  generateService(quotationId, body) {
    return this.http.post(`${this.route_prefix}/${quotationId}/generate-service`, body);
  }

  clientReview(quotationId, {note, user_ids}) {
    return this.http.post(`${this.route_prefix}/${quotationId}/client-review`, {description: note, user_ids});
  }

  mailClientReview(quotationId, {note, emails}) {
    return this.http.post(`${this.route_prefix}/${quotationId}/mail-client-review`, {description: note, emails});
  }

  follow(id, follow) {
    return this.http.post(this.route_prefix + '/' + id + '/follow', {follow: follow});
  }

  buildingsByClient(clientId) {
    return this.http.get(`services/${clientId}/buildings`);
  }

  updateVat(id, vat) {
    return this.http.post(this.route_prefix + '/' + id +'/update-vat', {vat: vat});
  }

  clickDashboard({ group, item }) {
    this.changeConditionValue('dashboard_type', { item, group });
    this.layout.publishLayoutChange({ switchTable: 'table' });
    this.layout.onSwitchView.emit('table');
  }
}
