import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LaravelPageRequest } from '../../models/laravel-page-request.model';

@Injectable({
  providedIn: 'root'
})
export class PaperService {

  constructor(private http: HttpClient) { }

  index(params: LaravelPageRequest) {
    return this.http.get('papers/index', { params: params.request() });
  }

  viewPdfs(paperId) {
    return this.http.get(`papers/${paperId}/view-pdfs`);
  }

  savePdfs(paperId) {
    return this.http.post(`papers/${paperId}/save-pdfs`, {});
  }
}
