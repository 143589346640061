import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { Conditions } from '@shared/models/conditions.model';
import { ServiceService } from '@shared/services/api/service.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { AppConfirmService } from '@shared/services/system/app-confirm/app-confirm.service';
import { NotifyService } from '@shared/services/notify.service';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { GlobalSettingService } from '@shared/services/system/global-setting.service';
import { GlobalSettingConfig } from '@shared/interfaces/global-setting-config';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  animations: egretAnimations
})
export class ServiceTableComponent implements OnInit, OnDestroy {
  private destroy = false;

  public get services() {
    return this.serviceService.items.map((item: any) => {
      if (item.need_due_from) {
        let hours = moment().diff(item.expect_start_time, 'hours');
        if (hours < 0) {
          item.due_from_type = 'primary';
          hours = -1 * hours;
        } else {
          item.due_from_type = 'warn';
        }
        let day =  Math.floor(hours / 24);
        let hour = Math.floor(hours % 24);
        if (day) {
          if (day > 1) {
            item.due_from = day + ' Day ' + hour +' Hour';
          } else {
            item.due_from = day + ' Days ' + hour +' Hour';
          }
          if (hour >1) {
            item.due_from+='s'
          }
        } else {
          item.due_from = hour +' Hour';
          if (hour >1) {
            item.due_from+='s'
          }
        }
      }
      return item;
    });
  }

  @Input() inPopup = false;

  public conditions: Conditions = this.serviceService.conditions;

  constructor(
    public serviceService: ServiceService,
    private layout: LayoutService,
    private confirm: AppConfirmService,
    private toast: NotifyService,
    private loader: AppLoaderService,
    private globalSettingService: GlobalSettingService,
  ) {
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => this.serviceService.refresh());
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        if (config.client) {
          this.conditions.client_id.value = config.client;
        }
        if (config.useCompanyId) {
          this.serviceService.getOptions();
          this.serviceService.refresh();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }

  followItem(row) {
    let message = !row.follow ? `Follow` : `Cancel follow`;
    this.confirm.confirm({ message: message + ` ${row.id}?` })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.serviceService.follow(row.id, !row.follow)
            .finally(() => loader.close())
            .subscribe((data: any) => {
              message = !row.follow ? `followed` : `canceled`;
              row.follow = !row.follow;
              this.toast.show(`Job ${row.id} ` + message);
            });
        }
      });
  }

  changeCondition() {
    let roomId = null;
    if (this.conditions.building_id.value) {
      this.conditions.building_id.items.forEach((item: any) => {
        if (this.conditions.building_id.value == item.id) {
          this.conditions.room_id.items = _.get(item, 'data.rooms', []);
          this.conditions.room_id.items.forEach((room: any) => {
            if (room.id == this.conditions.room_id.value) {
              roomId = room.id;
            }
          })
        }
      });
    } else {
      this.conditions.room_id.items = [];
      this.conditions.room_id.value = null;
    }

    if (this.conditions.client_id.value) {
      this.serviceService.buildingsByClient(this.conditions.client_id.value)
        .subscribe((data: any) => {
          this.conditions.building_id.items = data.filter((item) => {
            return item.status == 'active';
          });
        })
    } else {
      this.conditions.building_id.items = [];
      this.conditions.building_id.value = null;
    }
    this.conditions.room_id.value = roomId;

    if (this.conditions.supplier_id.value) {
      this.getEngineers([this.conditions.supplier_id.value]);
    } else {
      this.conditions.supplier_id.filter_items = [
        ...this.conditions.supplier_id.items
      ];
      this.conditions.engineer_id.items = [];
      this.conditions.engineer_id.filter_items = [];
      this.conditions.supplier_id.value = null;
      this.conditions.engineer_id.value = null;
    }
    this.serviceService.refresh();
  }

  export() {
   this.serviceService.export()
      .subscribe((res: any) => {
        let blob = new Blob([res])
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', 'service.xlsx');
        a.click();
        URL.revokeObjectURL(objectUrl);
      })

  }

  getEngineers(supplierCompanyIds) {
    let loader = this.loader.open();
    this.serviceService
      .readSupplierEngineers(supplierCompanyIds)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.conditions.engineer_id.items = data;
        this.conditions.engineer_id.filter_items = data;
      });
  }

  onSort($event) {
    console.log($event);
    this.conditions.sort_by.value.item = $event.sorts[0].dir;
    this.conditions.sort_by.value.group = $event.sorts[0].prop;
    this.serviceService.refresh();
  }
}
