import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuotationService } from '@app/shared/services/api/quotation.service';
import { ServiceService } from '@app/shared/services/api/service.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { GlobalSettingService } from '@app/shared/services/system/global-setting.service';
import { LayoutService } from '@app/shared/services/system/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { egretAnimations } from '@shared/animations/egret-animations';
import { Chart } from 'angular-highcharts';
import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard-company1455',
  templateUrl: './dashboard-company1455.component.html',
  styleUrls: ['./dashboard-company1455.component.scss'],
  animations: egretAnimations
})
export class DashboardCompany1455Component implements OnInit, OnDestroy {
  public company_id: any;
  public cards: any = {};
  public bottom_cards = [];
  public engineers = [];
  public monthly_revenue_chart: Chart;
  public cumulative_revenue_chart: Chart;
  public task_this_month_chart: Chart;
  public task_logged_per_client_ytd_chart: Chart;
  public tasks_completed_per_engineer_ytd_chart: Chart;
  public tasks_completed_per_engineer_monthly_chart: Chart;
  public total_quotes_awaiting_approval_chart: Chart;
  public top_3_displine_approved_value_for_month_chart: Chart;
  public destroy = false;
  public open_compliance_ppms_percent = '50%';
  public open_non_compliance_ppms_percent = '50%';

  constructor(
    private router: Router,
    private http: HttpClient,
    private layout: LayoutService,
    private traslate: TranslateService,
    private serviceService: ServiceService,
    private quotationService: QuotationService,
    private loader: AppLoaderService,
    private global: GlobalSettingService) { }

  ngOnInit() {
    this.global.useCompanyId$.subscribe((id) => {
      this.company_id = id;
      if (this.company_id == 1455 || this.company_id == 837) {
        this.readData();
      }
    });

    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        if (this.company_id == 1455 || this.company_id == 837) {
          this.readData();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy = true;
  }

  readData() {
    let loader = this.loader.open();
    this.http.get('dashboard/read-1455-data')
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.cards = data.cards;
        let total_open_ppms = this.cards.open_compliance_ppms.value + this.cards.open_non_compliance_ppms.value;
        if (total_open_ppms > 0) {
          this.open_compliance_ppms_percent = ((this.cards.open_compliance_ppms.value / total_open_ppms) * 100).toFixed(0) + '%';
          this.open_non_compliance_ppms_percent = ((this.cards.open_non_compliance_ppms.value / total_open_ppms) * 100).toFixed(0) + '%';
        }

        let that = this;
        this.task_this_month_chart = new Chart({
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: ''
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              events: {
                click(event) {
                  if (event.type == 'click') {
                    let dashboard_type = _.get(event, 'point.custom.item');
                    that.clickCard({title: dashboard_type});
                  }
                }
              }
            }
          },
          series: [
            {
              name: 'Tasks',
              data: data.task_this_month_pie.data,
            }
          ]
        });

        this.task_logged_per_client_ytd_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.task_logged_per_client_ytd_chart.date],
            title: {
              text: 'Clients'
            }
          },
          yAxis: {
            title: {
              text: 'Tasks'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              events: {
                click(event) {
                  if (event.type == 'click') {
                    let dashboard_type = _.get(event, 'point.custom.item');
                    that.clickCard({title: dashboard_type});
                  }
                }
              }
            }
          },
          series: data.task_logged_per_client_ytd_chart.series
        });

        this.tasks_completed_per_engineer_ytd_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.tasks_completed_per_engineer_ytd_chart.date],
            title: {
              text: ''
            }
          },
          yAxis: {
            title: {
              text: 'Tasks'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            },
            series: {
              events: {
                click(event) {
                  if (event.type == 'click') {
                    let dashboard_type = _.get(event, 'point.custom.item');
                    that.clickCard({title: dashboard_type});
                  }
                }
              }
            }
          },
          series: data.tasks_completed_per_engineer_ytd_chart.series
        });

        this.tasks_completed_per_engineer_monthly_chart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.tasks_completed_per_engineer_monthly_chart.date],
            title: {
              text: 'Clients'
            }
          },
          yAxis: {
            title: {
              text: 'Tasks'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            }
          },
          series: data.tasks_completed_per_engineer_monthly_chart.series
        });

        this.top_3_displine_approved_value_for_month_chart = new Chart({
          chart: {
            type: 'bar'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: [data.top_3_displine_approved_value_for_month_chart.date],
            title: {
              text: 'Disciplines'
            }
          },
          yAxis: {
            title: {
              text: 'Tasks'
            }
          },
          plotOptions: {
            column: {
              borderWidth: 0
            }
          },
          series: data.top_3_displine_approved_value_for_month_chart.series
        });
      });
  }

  clickCard(item, type = 'service') {
    if (type == 'service') {
      this.serviceService.clickDashboard({ group: 'main_dashboard_card', item: item.title });
      this.router.navigate([`/service`]);
    } else if (type == 'quotation') {
      this.quotationService.clickDashboard({ group: 'main_dashboard_card', item: item.title });
      this.router.navigate([`/quotation`]);
    }
  }

  clickEngineer(row, type) {
    let title = row.engineer_name + ", " + this.traslate.instant(type);
    this.serviceService.clickDashboard({ group: 'main_dashboard_engineer', item: { key: title, engineer_id: row.engineer_id, type: type } });
    this.router.navigate([`/service`]);
  }
}
