import {Injectable} from '@angular/core';
import {Conditions} from '@shared/models/conditions.model';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LaravelPageRequest} from '@shared/models/laravel-page-request.model';
import {User} from '@shared/services/api/user.service';
import {CompanyService} from '@shared/services/api/company.service';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {ModelAttributeOptions, PurchaseOrderItemOptions, PurchaseOrderOptions} from '@shared/models/options';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';

@Injectable()
export class PurchaseOrderService {
  route_prefix = 'purchase-orders';

  public conditions: Conditions = {    
    supplier_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Service supplier',
      can_filter: true,
      filter_items: null,
      filter_keys: ['name'],
    },
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };
  public purchaseOrderOptions: PurchaseOrderOptions;
  public purchaseOrderItemOptions: PurchaseOrderItemOptions;
  public deliveryToOptions: { option_name: string; option_value: string; address: string }[] = [];
  public users: User[] = [];
  public userIdentities: { id: number; name: string; user_identity: string; email: string }[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private companyService: CompanyService,
    private globalSetting: GlobalSettingService,
    private mao: ModelAttributeOptionService,
  ) {
    this.globalSetting.useCompanyId$.subscribe(id => {
      this.getUsers(id);
    });
    this.getUserIdentities();
    this.getOptions();
    this.getDeliveryToOptions();
  }

  getDeliveryToOptions() {
    this.http.get(this.route_prefix + '/delivery-to-options')
      .subscribe((data: any) => {
        this.deliveryToOptions = data;
      });
  }

  getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.purchaseOrderOptions = data.purchase_order;
        this.purchaseOrderItemOptions = data.purchase_order_item;
        this.conditions.status.items = data.purchase_order.status;
      });
    this.companyService.getContractor().subscribe((data: any) => {
      this.conditions.supplier_id.items = data;
    });
  }


  getUsers(companyId) {
    this.companyService.users(companyId)
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  clients() {
    return this.http.get(this.route_prefix + '/clients');
  }

  serviceSuppliers(clientId = '') {
    return this.http.get(this.route_prefix + '/service-suppliers', {params: {client_id: clientId}});
  }

  getUserIdentities() {
    return this.http.get(this.route_prefix + '/user-identities').subscribe((data: any) => {
      this.userIdentities = data;
    });
  }

  index(params: LaravelPageRequest) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  gotoTable() {
    this.router.navigate(['/purchase-order']);
  }

  follow(id, follow) {
    return this.http.post(this.route_prefix + '/' + id + '/follow', {follow: follow});
  }

  sendEmails(body: { purchase_order_id: number, emails: string[] }) {
    return this.http.post(this.route_prefix + '/send-assigned-email', body)
  }
  
  updateVat(id, vat) {
    return this.http.post(this.route_prefix + '/' + id +'/update-vat', {vat: vat});
  }
}
