import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentService} from '@shared/services/api/document.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@shared/services/notify.service';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {
  @Input() name: string;
  @Input() pdf: { id: number; type: string; };
  @Output() onClose = new EventEmitter();

  constructor(
    private documentService: DocumentService,
    private loader: AppLoaderService,
    private toast: NotifyService,
  ) {
  }

  ngOnInit() {
  }

  close() {
    this.onClose.emit();
  }

  viewPdf() {
    if (this.pdf) {
      this.documentService.viewPdf(this.pdf['type'], this.pdf['id']);
    }
  }

  storePdf() {
    if (this.pdf) {
      let loader = this.loader.open();
      this.documentService.storePdf(this.pdf['type'], this.pdf['id'])
        .finally(() => loader.close())
        .subscribe(() => {
          this.toast.show('Store success');
        });
    }
  }

}
