import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompanyModel} from '@shared/models/company.model';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {CompanyService} from '@shared/services/api/company.service';
import {CompanyRateOptions, ModelAttributeOptions} from '@shared/models/options';
import {NotifyService} from '@shared/services/notify.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';

@Component({
  selector: 'app-company-rate-card',
  templateUrl: './company-rate-card.component.html',
  styleUrls: ['./company-rate-card.component.scss']
})
export class CompanyRateCardComponent implements OnInit {

  @Input() source_company_id;

  public base_form: FormGroup = new FormGroup({
    target_company_id: new FormControl(null, [Validators.required]),
    discipline: new FormControl(null, [Validators.required]),
    standard_rate_end_time: new FormControl(null, [Validators.required]),
    standard_rate_start_time: new FormControl(null, [Validators.required]),
    rate_start_point: new FormControl(null, [Validators.required]),
    material: new FormGroup({
      margin: new FormControl(null),
      margin_unit: new FormControl(null)
    })
  });

  public choice_rate_config = new FormControl(null);
  public disciplines = [];
  public editing_rate_config_types = [];

  public get clients() {
    return this.company_service.clients;
  }

  companyRateOptions: CompanyRateOptions;
  companyRates = [];


  constructor(public mao: ModelAttributeOptionService,
              public company_service: CompanyService,
              private confirm: AppConfirmService,
              private loader: AppLoaderService,
              private toast: NotifyService) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data['company_rate']) {
          this.companyRateOptions = data.company_rate;
        }

        if (data['service']) {
          this.disciplines = data.service.discipline;
        }
      });
  }

  choiceRateConfigType() {
    let rate = this.choice_rate_config.value;
    if (rate) {
      if (rate.data.type === 'hourly_rate') {
        this.base_form.addControl(rate.data.value, new FormGroup({
          standard_rate: new FormControl(null, [Validators.required]),
          overtime_rate: new FormControl(null, [Validators.required]),
          object_unit: new FormControl(null, [Validators.required]),
          object_quantity: new FormControl(null, [Validators.required]),
          time_unit: new FormControl(null, [Validators.required]),
          time_quantity: new FormControl(null, [Validators.required])
        }));
      } else {
        this.base_form.addControl(rate.data.value, new FormGroup({
          standard_rate: new FormControl(null, [Validators.required]),
          overtime_rate: new FormControl(null, [Validators.required]),
          object_unit: new FormControl(null, [Validators.required]),
          object_quantity: new FormControl(null, [Validators.required]),
          inclusive: new FormGroup({
            inclusive_type: new FormControl(null, [Validators.required]),
            inclusive_quantity: new FormControl(null, [Validators.required])
          })
        }));
      }
      this.editing_rate_config_types.push(rate);
      this.choice_rate_config.setValue(null);
    } else {
      this.toast.show('Required a rate type');
    }
  }

  removeRateConfigType(rate, i) {
    this.base_form.removeControl(rate.data.value);
    this.editing_rate_config_types.splice(i, 1);
  }

  submit() {
    this.confirm.confirm({}).subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.company_service.saveCompanyRate({
          source_company_id: this.source_company_id,
          ...this.base_form.value
        })
          .finally(() => {
            loader.close();
          })
          .subscribe((data) => {
            this.toast.show('Rates updated!');
            let target_company_id = this.base_form.controls['target_company_id'].value;
            this.resetBaseForm();
            this.readCompanyRates(target_company_id);
          });
      }
    });
  }

  cancel() {
    this.confirm.confirm({}).subscribe(res => {
      if (res) {
        let target_company_id = this.base_form.controls['target_company_id'].value;
        this.resetBaseForm();
        this.readCompanyRates(target_company_id);
      }
    });
  }

  resetBaseForm() {
    let target_company_id = this.base_form.controls['target_company_id'].value;
    this.editing_rate_config_types = [];
    this.choice_rate_config.setValue(null);
    this.companyRateOptions.data_groups.rate_config_type.forEach((type) => {
      this.base_form.removeControl(type.data.value);
    });
    this.base_form.reset();
    this.base_form.controls['target_company_id'].setValue(target_company_id);

  }

  readCompanyRates(event) {
    if (event && event.id) {
      let loader = this.loader.open();
      this.company_service.getCompanyRate(this.source_company_id, event.id)
        .finally(() => {
          loader.close();
        })
        .subscribe((data: any) => {
          this.companyRates = data;
        });
    }
  }

  deleteCompanyRate(rate) {
    this.confirm.confirm({}).subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this.company_service
          .deleteCompanyRate(rate)
          .finally(() => {
            loader.close();
          })
          .subscribe(() => {
            this.toast.show('Company Rate Deleted!');
            this.readCompanyRates(rate.target_compoany_id);
          });
      }
    });
  }

  editCompanyRate(rate, index) {
    this.resetBaseForm();
    this.companyRateOptions.data_groups.rate_config_type.forEach((config_type) => {
      if (rate.hasOwnProperty(config_type.data.value)) {
        if (config_type.data.type === 'hourly_rate') {
          this.base_form.addControl(config_type.data.value, new FormGroup({
            standard_rate: new FormControl(null, [Validators.required]),
            overtime_rate: new FormControl(null, [Validators.required]),
            object_unit: new FormControl(null, [Validators.required]),
            object_quantity: new FormControl(null, [Validators.required]),
            time_unit: new FormControl(null, [Validators.required]),
            time_quantity: new FormControl(null, [Validators.required])
          }));
        } else {
          this.base_form.addControl(config_type.data.value, new FormGroup({
            standard_rate: new FormControl(null, [Validators.required]),
            overtime_rate: new FormControl(null, [Validators.required]),
            object_unit: new FormControl(null, [Validators.required]),
            object_quantity: new FormControl(null, [Validators.required]),
            inclusive: new FormGroup({
              inclusive_type: new FormControl(null, [Validators.required]),
              inclusive_quantity: new FormControl(null, [Validators.required])
            })
          }));
        }
        this.editing_rate_config_types.push(config_type);
        this.choice_rate_config.setValue(null);
      }
    });
    this.base_form.patchValue(rate);
  }

  changeDiscipline($event) {
    if ($event && $event.option_value) {
      this.companyRates.forEach((rate, index) => {
        if (rate.discipline === $event.option_value) {
          this.editCompanyRate(rate, index);
          return;
        }
      });
    }
  }
}
