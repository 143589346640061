import {LaravelPageRequest} from './../../../../models/laravel-page-request.model';
import {AssetEditPopupComponent} from './../../asset/asset-edit/asset-edit-popup.component';
import {ModelAttributeOptionService} from './../../../../services/api/model-attribute-option.service';
import {ModelAttributeOptions, ServiceOptions} from './../../../../models/options';
import {BaseTableComponent} from '@shared/components/base/base-table.component';
import {MatDialog} from '@angular/material';
import {Component, OnInit, Injector} from '@angular/core';
import {tableConfigs} from '@shared/config';
import {egretAnimations} from '@shared/animations/egret-animations';
import {PpmCreateComponent} from '@shared/components/business/ppm/ppm-create/ppm-create.component';
import {PpmService} from '@app/shared/services/api/ppm.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {GlobalSettingConfig} from '@shared/interfaces/global-setting-config';
import { Conditions } from '@app/shared/models/conditions.model';
import { AssetCreatePopupComponent } from '../../asset/asset-create/asset-create-popup.component';

@Component({
  selector: 'app-ppm-list',
  templateUrl: './ppm-list.component.html',
  animations: egretAnimations
})
export class PpmListComponent extends BaseTableComponent implements OnInit {
  public items: any[];
  public table_config = tableConfigs.ppm;


  public conditions: Conditions = this._service.conditions;
  public serviceOptions = this._service.options;

  constructor(protected injector: Injector,
              private _dialog: MatDialog,
              private _service: PpmService,
              public toast: NotifyService,
              private globalSettingService: GlobalSettingService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.layout.onRefresh
      .takeWhile(() => !this.destroy)
      .subscribe(() => {
        this.refresh();
        this._service.getOptions();
      });
    this.globalSettingService.config$
      .takeWhile(() => !this.destroy)
      .subscribe((config: GlobalSettingConfig) => {
        this.conditions.client.value = config.client;
        if (config.useCompanyId) {
          this._service.getOptions();
          this.refresh();
        }
      });
  }

  openAssetEditPage(row) {
    this._dialog.open(AssetEditPopupComponent, {
      width: '960px',
      disableClose: false,
      data: {id: row.id, title: 'Asset Edit(' + row.id + ')'}
    }).afterClosed()
      .subscribe((data: any) => {
        if (data) {
          this.refresh();
        }
      });
  }

  openAssetCreate() {
    this._dialog.open(AssetCreatePopupComponent, {
      width: '960px',
      disableClose: true,
      data: {companyId: this.globalSettingService.config.client, buildingId: null}
    }).afterClosed()
      .subscribe((data: any) => {
        if (data) {
          this.refresh();
          this.toast.show('Asset Add success!');
        }
      });
  }

  changeCondition() {
    if (this.conditions.client.value) {
      this._service.buildingsByClient(this.conditions.client.value)
        .subscribe((data: any) => {
          this.conditions.building.items = data.filter((item) => {
            return item.status == 'active';
          });
        })
    } else {
      this.conditions.building.items = [];
      this.conditions.building.value = null;
    }
    this.refresh();
  }
}
