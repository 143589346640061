export class Conditions {
  [key: string]: {
    type?: string;
    nullable: boolean;
    items?: object[];
    display_key?: string;
    select_key?: string;
    group_key?: string;
    item_key?: string;
    display_name?: string;
    hidden?: boolean;
    value: string & { item: string; group: string } & any;
    useGroup?: boolean;
    can_filter?: boolean;
    filter_items?: object[],
    filter_keys?: any[],
    groups?: {
      [group_name: string]: {
        display: string;
        items: {
          key: string;
          display: string;
        }[]
      }
    } | any;
    multi?: boolean;
  }
}
