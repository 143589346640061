import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FollowService {

  route_prefix = 'follows';

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
  }

  public index() {
    return this.http.get(this.route_prefix);
  }

  public follow(data) {
    return this.http.post(`follows/toggle`, data);
  }
}
