import {GlobalSettingConfig} from '@app/shared/interfaces/global-setting-config';
import {Component, OnInit, Input} from '@angular/core';
import {GlobalSettingService} from '@shared/services/system/global-setting.service';
import {FormControl, FormGroup} from '@angular/forms';
import {CompanyModel} from '@shared/models/company.model';
import {CompanyService} from '@shared/services/api/company.service';
import {NavigationEnd, Router} from '@angular/router';
import {NotifyService} from '@app/shared/services/notify.service';

@Component({
  selector: 'app-global-setting',
  templateUrl: './global-setting.component.html',
  styleUrls: ['./global-setting.component.scss']
})
export class GlobalSettingComponent implements OnInit {
  @Input() globalSettingPanel;
  public companies: CompanyModel[] = [];

  public get clients() {
    return this.companyService.clients;
  }

  public get contractors() {
    return this.companyService.contractors;
  }

  public settings = new FormGroup({
    client: new FormControl(null),
    contractors: new FormControl([]),
    pageStartTimeText: new FormControl(null),
    pageEndTimeText: new FormControl(null),
    useCompanyId: new FormControl(null),
  });

  constructor(
    private globalSettingService: GlobalSettingService,
    private toast: NotifyService,
    private companyService: CompanyService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.globalSettingPanel.close();
      }
    });
    this.initConfig();
  }

  initConfig() {

    // listen config change
    this.globalSettingService.config$.subscribe((config: GlobalSettingConfig) => {
      this.companies = config.companies;
      this.settings.patchValue({
        client: config.client,
        contractors: config.contractors,
        pageStartTimeText: new Date(config.pageStartTimestamp),
        pageEndTimeText: new Date(config.pageEndTimestamp),
        useCompanyId: +config.useCompanyId
      }, {emitEvent: false});
    });

    this.settings.valueChanges.subscribe((change) => {
      let client = change.client;
      if (change.useCompanyId !== this.globalSettingService.config.useCompanyId) {
        client = null;
        this.settings.get('client').patchValue(null, {emitEvent: false});
      }
      this.globalSettingService.publishConfig({
        pageStartTimestamp: change.pageStartTimeText.getTime(),
        pageEndTimestamp: change.pageEndTimeText.getTime(),
        contractors: change.contractors,
        client: client,
        useCompanyId: change.useCompanyId
      });
      this.toast.show('Save success.');
    });
  }
}
