import { Injectable, Inject, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { getQueryParam } from "@shared/helpers/url.helper";
import * as _ from "lodash";

interface ITheme {
  name: string;
  baseColor?: string;
  isActive?: boolean;
}

@Injectable()
export class ThemeService {
  public egretThemes: ITheme[] = [
    {
      name: "egret-dark-purple",
      baseColor: "#9c27b0",
      isActive: false,
    },
    {
      name: "egret-dark-pink",
      baseColor: "#e91e63",
      isActive: false,
    },
    {
      name: "egret-blue",
      baseColor: "#247ba0",
      isActive: true,
    },
    {
      name: "egret-indigo",
      baseColor: "#3f51b5",
      isActive: false,
    },
  ];
  public activatedTheme: ITheme;
  private dataColors = {
    pending: "#3C7D26",
    accepted: "#FFC038",
    cancelled: "#5F4CA1",
    completed: "#1A55A4",
    approved: "#407DC5",
    active: "#64A24D",
    inactive: "#380000",
    paid: "#EF6C00",
    delivered: "#4E342E",

    quote_required: "#64A24D",
    compliance_to_sign_off: "#5F4CA1",
    audit_service: "#247ba0",

    ppm_to_log: "#3C7D26",
    due_in_n_days: "#FFC038",
    non_compliant: "#247ba0",
    ppm_overdue: "#e91e63",
    cert_missing: "#1A55A4",
    compliance_sign_off: "#5F4CA1",

    not_trading: "#3C7D26",
    to_be_assigned: "#407DC5",

    uf: "#3e56a0",
    capex: "#247ba0",

    logged: "#3C7D26",
    assigned: "#247ba0",
    in_progress: "#07a029",
    store_jobs: "#FFC038",

    client: "#FFC038",
    supplier: "#247ba0",
    engineer: "#380000",
    branch: "#3C7D26",
    service_supplier: "#3e56a0",
    product_supplier: "#07a029",
    no_company: "#75a011",
    agent: "#3C7D26",

    open_health_and_safety: "#3C7D26",
    open_non_compliant: "#247ba0",
    open_business_critical: "#1A55A4",
    out_standing_quote: "#FFC038",

    // quotation status
    client_review: "#3C7D26",
    declined: "#e91e63",
    declined_with_message: "#EF6C00",
    awaiting: "#3e56a0",
    quoted: "#75a011",
    failed_benchmarking: "#1A55A4",
    holding_pool: "#3C7D26",
    more_info: "#FFC038",
    collected: "#67de",
    invoiced: "#21de",
    issued: "#3d8",

    certificate_missing: "#EF6C00",

    show_data: "#75a011",
    apply: "#3d8",
    deferred: "#e9555d",
    reduced: "#EF6C00",
    rejected: "#e91e63",

    // ppm table

    over_due: "#f44336",
    due: "#ffd97d",

    access_control: "#3C7D26",
    access_control_ppm: "#1A55A4",
    air_conditioning: "#5F4CA1",
    air_conditioning_ppm: "#FF3030",
    air_handling_unit: "#3C7D26",
    air_handling_unit_ppm: "#1A55A4",
    auto_doors: "#5F4CA1",
    auto_doors_ppm: "#FF3030",
    barrier_ppm: "#3C7D26",
    barriers: "#1A55A4",
    bms: "#5F4CA1",
    bms_ppm: "#FF3030",
    cctv: "#3C7D26",
    cctv_and_security_systems_ppm: "#1A55A4",
    drainage: "#5F4CA1",
    drainage_ppm: "#FF3030",
    dry_riser_ppm: "#3C7D26",
    electrical: "#1A55A4",
    emergency_lighting: "#5F4CA1",
    emergency_lighting_ppm: "#FF3030",
    equipment: "#3C7D26",
    equipment_ppm: "#1A55A4",
    extract_fans: "#5F4CA1",
    extract_fans_ppm: "#FF3030",
    fire_alarm: "#3C7D26",
    fire_alarm_ppm: "#1A55A4",
    fire_damper_ppm: "#5F4CA1",
    fire_extinguisher: "#FF3030",
    fire_extinguisher_ppm: "#3C7D26",
    fire_rig_ppm: "#1A55A4",
    fire_suppression_ppm: "#5F4CA1",
    fixed_wire_test_ppm: "#FF3030",
    flooring: "#3C7D26",
    fra_ppm: "#1A55A4",
    fra_ppm_remedial_works: "#5F4CA1",
    general_building: "#FF3030",
    general_building_ppm: "#3C7D26",
    generator_ppm: "#1A55A4",
    groundwork_civils: "#5F4CA1",
    gutter_clearance_ppm: "#FF3030",
    hangar_door_ppm: "#3C7D26",
    heating_and_gas: "#1A55A4",
    heating_and_gas_ppm: "#5F4CA1",
    intruder_alarm: "#FF3030",
    intruder_alarm_ppm: "#3C7D26",
    lift_ppm: "#1A55A4",
    lifts: "#5F4CA1",
    lighting: "#FF3030",
    lightning_protection_ppm: "#3C7D26",
    pat_test_ppm: "#1A55A4",
    pest_control: "#5F4CA1",
    pressure_vessel_ppm: "#FF3030",
    pumps_ppm: "#3C7D26",
    "rcd/rcbo_ppm": "#1A55A4",
    refrigeration_ppm: "#5F4CA1",
    roofing: "#FF3030",
    smoke_vent_ppm: "#3C7D26",
    ups_ppm: "#1A55A4",
    valve_inspection_ppm: "#5F4CA1",
    water_regulation_ppm: "#FF3030",
    total_quotes_pending: "#3C7D26",
    health_and_safety_quotes: "#1A55A4",
    compliance_quotes: "#5F4CA1",
    trading_critical_quotes: "#FF3030",
    remedial_quotes: "#3C7D26",
    holding_pool_quotes: "#1A55A4",
    failed_benchmarking_quotes: "#5F4CA1",
    upcoming_ppms: "#1A55A4",
    over_due_ppms_not_logged: "#5F4CA1",
    outstanding_uplifts: "#FF3030",
    urgent_p1: "#3C7D26",
    recall: "#1A55A4",
    warranty: "#5F4CA1",
    jobs_not_accepted: "#FF3030",
    jobs_not_assigned: "#3C7D26",
    holding_pool_jobs: "#1A55A4",
    over_due_ppm_services: "#5F4CA1",
    certificates_missing: "#FF3030",
    reactive: "#1A55A4",
    quote_approval: "#5F4CA1",
    ppm: "#FF3030",
    quote_request: "#3C7D26",
    temp_fix: "#1A55A4",
    part_required: "#5F4CA1",
    another_visit: "#FF3030",
    ahu_eab_3000_tw_hp_ppm: "#3C7D26",
    ahu_general_ppm: "#3C7D26",
    ahu_mecury_filter_change_ppm: "#3C7D26",
    ahu_swegon_ppm: "#3C7D26",
    ahu_trane_ppm: "#3C7D26",
    ahu_extract_service_ppm: "#3C7D26",
    ahu_supply_service_ppm: "#3C7D26",
    airside_heaters_ppm: "#3C7D26",
    "anpr_&_barriers_ppm": "#3C7D26",
    boiler_maintenance_ppm: "#3C7D26",
    "boilers_&_plant_ppm": "#3C7D26",
    booster_set_ppm: "#3C7D26",
    building_controls_ppm: "#3C7D26",
    car_park_drainage_service_ppm: "#3C7D26",
    cctv_ppm: "#3C7D26",
    chiller_ppm: "#3C7D26",
    chimey_inspection_ppm: "#3C7D26",
    "chlorination_(tank_clean)_ppm": "#3C7D26",
    cold_water_storage_tanks_ppm: "#3C7D26",
    "comfort_cooling_&_ventilation_ppm": "#3C7D26",
    comfort_cooling_maintenance_liverpool_ppm: "#3C7D26",
    comfort_cooling_system_ppm: "#3C7D26",
    comms_room_cooling_ej3_ppm: "#3C7D26",
    comms_room_cooling_h89_ppm: "#3C7D26",
    compressor_ppm: "#3C7D26",
    contingency_plan_ppm: "#3C7D26",
    controls_ppm: "#3C7D26",
    door_maintenance_ppm: "#3C7D26",
    electric_water_heater_service_ppm: "#3C7D26",
    entrance_doors_ppm: "#3C7D26",
    external_drainage_service_ppm: "#3C7D26",
    extracting_cleaning_ppm: "#3C7D26",
    "fan_coil/dx_split_system_service_ppm": "#3C7D26",
    fire_doors_ppm: "#3C7D26",
    floor_scrubber_ppm: "#3C7D26",
    "gas_fired_boilers/water_heaters_ppm": "#3C7D26",
    gas_inspection_ppm: "#3C7D26",
    gas_safety_ppm: "#3C7D26",
    gas_suppression_system_ppm: "#3C7D26",
    "kitchen_extract/duct_work_clean_ppm": "#3C7D26",
    l8_water_treatment_ppm: "#3C7D26",
    legionella_service_ppm: "#3C7D26",
    legionella_water_risk_assessments_ppm: "#3C7D26",
    lifting_equipment_ppm: "#3C7D26",
    "monthly_water_testing_&_treatment_ppm": "#3C7D26",
    overdoor_heaters_ppm: "#3C7D26",
    passenger_platform_lift_ppm: "#3C7D26",
    platform_lift_ppm: "#3C7D26",
    pressurisaiton_units_ppm: "#3C7D26",
    quarterly_drainage_inspection_service_ppm: "#3C7D26",
    roof_guttering_and_cleaning_ppm: "#3C7D26",
    l8_water_regulations_ppm: "#3C7D26",
    staircase_inspection_ppm: "#3C7D26",
    surface_water_ppm: "#3C7D26",
    tank_clean_ppm: "#3C7D26",
    temperature_monitoring_ppm: "#3C7D26",
    tmv_servicing_ppm: "#3C7D26",
    under_cupboard_water_heaters_ppm: "#3C7D26",
    "water_boiler/chiller_tap_service_ppm": "#3C7D26",
    water_cisterns_ppm: "#3C7D26",
    water_heater_ppm: "#3C7D26",
    water_temperture_monitoring_ppm: "#3C7D26",
    weekly_water_testing_ppm: "#3C7D26",
    weekly_check_list: "#3C7D26",
    project: "#3C7D26",
    mechanical: "#3C7D26",
    "fuel/gas": "#3C7D26",
    flushing: "#3C7D26",
    escorting: "#3C7D26",
    water_temperture: "#3C7D26",

    material: "#247BA0",
    electronics: "#3C7D26",
    appliance: "#1A55A4",
    consumables: "#5F4CA1",
    plant_and_equipment: "#FF3030",
    fleet: "#EF6C00",
    other: "#FFC038"
  };

  dashboardColor = {};

  constructor(@Inject(DOCUMENT) private document: Document) {}

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme(r: Renderer2) {
    /*
     ****** (SET YOUR DEFAULT THEME HERE) *******
     * Assign new Theme to activatedTheme
     */
    // this.activatedTheme = this.egretThemes[0];
    // this.activatedTheme = this.egretThemes[1];
    this.activatedTheme = this.egretThemes[2];
    // this.activatedTheme = this.egretThemes[3];
    this.initDashboardCorlors();

    // *********** ONLY FOR DEMO **********
    this.setThemeFromQuery();
    // ************************************

    this.changeTheme(r, this.activatedTheme);
  }

  changeTheme(r: Renderer2, theme: ITheme) {
    r.removeClass(this.document.body, this.activatedTheme.name);
    r.addClass(this.document.body, theme.name);
    this.flipActiveFlag(theme);
  }

  flipActiveFlag(theme: ITheme) {
    this.egretThemes.forEach((t) => {
      t.isActive = false;
      if (t.name === theme.name) {
        t.isActive = true;
        this.activatedTheme = theme;
      }
    });
  }

  // *********** ONLY FOR DEMO **********
  setThemeFromQuery() {
    let themeStr = getQueryParam("theme");
    try {
      this.activatedTheme = JSON.parse(themeStr);
      this.flipActiveFlag(this.activatedTheme);
    } catch (e) {}
  }

  getStrColor(str) {
    if (this.dataColors.hasOwnProperty(str)) {
      return this.dataColors[str];
    } else {
      return "#032965";
    }
  }

  initDashboardCorlors() {
    const colors = [
      "#1A55A4",
      "#380000",
      "#3C7D26",
      "#407DC5",
      "#4E342E",
      "#5F4CA1",
      "#64A24D",
      "#EF6C00",
      "#FFC038",
      "#247ba0",
      "#e91e63",
      "#3e56a0",
      "#07a029",
      "#75a011",
      "#e9555d",
      "#f44336",
      "#FF3030",
    ];
    Object.keys(this.dataColors).forEach((key) => {
      _.set(this.dashboardColor, key, colors[_.random(0, colors.length - 1)]);
    });
  }

  getDashboardChipColor(str) {
    if (this.dashboardColor.hasOwnProperty(str)) {
      return this.dashboardColor[str];
    } else {
      return "#032965";
    }
  }
}
