import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-holiday-approval',
  templateUrl: './holiday-approval.component.html',
  styleUrls: ['./holiday-approval.component.scss']
})
export class HolidayApprovalComponent {

  constructor(
    public dialogRef: MatDialogRef<HolidayApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; holiday?: any; },
  ) {
  }

  submit(status) {
    this.dialogRef.close({ status: status });
  }

}
