import {Injectable} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {CompanyModel} from '@app/shared/models/company.model';
import {AppLoaderService} from '@app/shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@app/shared/services/notify.service';
import {MatDialog} from '@angular/material';
import {ServiceEditPopupComponent} from '@app/shared/components/business/service/service-edit/service-edit-popup.component';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApplicationServiceDetailComponent} from '@app/views/application/application-service-detail/application-service-detail.component';
import {CacheKeys, CacheService} from '@shared/services/cache.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationCreateService {

  public formGroup: FormGroup = new FormGroup({
    supplier_id: new FormControl(null, Validators.required),
    client_id: new FormControl(null, Validators.required)
  });
  public suppliers: CompanyModel[] = [];
  public suppliersLoading = false;
  public clients: CompanyModel[] = [];
  public clientsLoading = false;
  public selected = [];
  private subGetPendingSubmitServices;
  public pendingSubmitServices = [];

  constructor(
    private loader: AppLoaderService,
    private toast: NotifyService,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private cache: CacheService,
    private  confirmService: AppConfirmService,
  ) {
    this.formGroup.get('supplier_id').valueChanges.subscribe((supplier_id: any) => {
      this.getClients(supplier_id);
    });
    this.formGroup.valueChanges.subscribe((change) => {
      this.cache.set(CacheKeys.application_create, change);
      if (change['client_id']) {
        this.refresh();
      }
    });
    this.getSuppliers();
    let cacheObj = this.cache.get(CacheKeys.application_create);
    if (cacheObj) {
      this.formGroup.patchValue({
        supplier_id: cacheObj['supplier_id'],
        client_id: cacheObj['client_id'],
      });
    }
  }

  refresh() {
    this.selected = [];
    if (this.subGetPendingSubmitServices) {
      this.subGetPendingSubmitServices.unsubscribe();
    }
    let loader = this.loader.open();
    this.subGetPendingSubmitServices = this.http.get(`applications/pending-submission`,
      {
        params:
          {
            supplier_id: this.formGroup.value.supplier_id,
            client_id: this.formGroup.value.client_id
          }
      }
    )
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.pendingSubmitServices = data;
      });
  }

  submit() {
    let ids = this.selected.map(select => select.id);
    if (ids.length === 0) {
      this.toast.show('Please select services');
    } else {
      this.confirmService.confirm().subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.http.post(`applications/manually-submit`, {
            supplier_id: this.formGroup.value.supplier_id,
            client_id: this.formGroup.value.client_id,
            service_ids: this.selected.map((item) => item.id),
          })
            .finally(() => loader.close())
            .subscribe((data: any) => {
              this.refresh();
              this.toast.show('Manually submit success.');
            });
        }
      });
    }
  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  openEditPage(row) {
    this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      data: {id: row.id}
    });
  }

  openApplicationServiceDetail(row) {
    this.dialog.open(ApplicationServiceDetailComponent, {
      width: '80%',
      data: {service_id: row.id, contractor_id: this.formGroup.value.supplier_id}
    });
  }

  getClients(supplier_id) {
    this.clientsLoading = true;
    this.http.get('applications/clients', {params: {supplier_id}})
      .finally(() => this.clientsLoading = false)
      .subscribe((data: any) => {
        this.clients = data;
      });
  }

  getSuppliers() {
    this.suppliersLoading = true;
    return this.http.get('applications/suppliers', {})
      .finally(() => this.suppliersLoading = false)
      .subscribe((data: any) => {
        this.suppliers = data;
      });
  }
}
