import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { CompanyService } from "@shared/services/api/company.service";
import { AssetOptions, ModelAttributeOptions } from "@shared/models/options";
import { ModelAttributeOptionService } from "@shared/services/api/model-attribute-option.service";
import { AssetModel } from "@shared/models/asset.model";
import { parseDate } from "@shared/helpers/utils";
import { BaseEditComponent } from "@shared/components/base/base-edit.component";
import { TableConfigModel } from "@shared/models/table-config.model";
import { tableConfigs } from "@shared/config";
import { AssetService } from "@shared/services/api/asset.service";
import { get } from "lodash";
import { CompanyModel } from "@shared/models/company.model";
import { PopupService } from "@app/shared/services/popup.service";
import { PpmService } from "@app/shared/services/api/ppm.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UploaderService } from "@app/shared/services/uploader.service";
import { FileUploader } from "ng2-file-upload";
import { DocumentService } from "@app/shared/services/api/document.service";
import * as _ from "lodash";

@Component({
  selector: "app-asset-edit",
  templateUrl: "./asset-edit.component.html",
})
export class AssetEditComponent extends BaseEditComponent implements OnInit {
  tableConfig: TableConfigModel = tableConfigs.asset;
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted = new EventEmitter();
  @Output() onBack = new EventEmitter();
  public get companies(): CompanyModel[] {
    return this.companyService.managementCompanies;
  }
  public buildings = [];
  public options: AssetOptions;
  public item: AssetModel;
  public services = [];
  public ppms = [];
  public rooms = [];
  public uploader: FileUploader;
  public show_uploader = true;
  public filteredCategories: Observable<any[]>;
  public itemForm = new FormGroup({
    company_id: new FormControl("", [Validators.required]),
    building_id: new FormControl("", [Validators.required]),
    name: new FormControl(""),
    category: new FormControl(""),
    description: new FormControl(""),
    service_interval: new FormControl(""),
    last_service_time: new FormControl(""),
    next_service_time: new FormControl(""),
  });
  public dataForm = new FormGroup({
    ppm_id: new FormControl(null),
    notification_period: new FormControl(0),
    // vehicle_make: new FormControl(''),
    asset_condition: new FormControl(""),
    compliant_status: new FormControl(""),
    installation_date: new FormControl(""),
    warranty_end_date: new FormControl(""),
    asset_tag: new FormControl(""),
    model_number: new FormControl(""),
    serial_number: new FormControl(""),
    life_span: new FormControl(""),
    room_id: new FormControl(null),
  });
  constructor(
    private companyService: CompanyService,
    private popup: PopupService,
    private maoService: ModelAttributeOptionService,
    private ppmService: PpmService,
    private translate: TranslateService,
    private _service: AssetService,
    private documentService: DocumentService,
    private uploaderService: UploaderService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
    this.itemForm
      .get("company_id")
      .valueChanges.subscribe((company_id: any) => {
        if (company_id) {
          this.getBuildings(company_id);
        }
      });

    this.itemForm
      .get("building_id")
      .valueChanges.subscribe((building_id: any) => {
        this.ppms = [];
        this.rooms = [];
        if (building_id) {
          this.getPPMs(building_id);
          this.buildings.some((building) => {
            if (building.id == building_id) {
              this.rooms = _.get(building, 'data.rooms', []);
              return true;
            }
            return false;
          });
        }
      });
    this.filteredCategories = this.itemForm.controls[
      "category"
    ].valueChanges.pipe(
      startWith(""),
      map((value) => {
        if (this.options) {
          const filterValue = value.toLowerCase();
          return this.options.category.filter((option) =>
            option.option_value
              .replace("_", " ")
              .toLowerCase()
              .includes(filterValue)
          );
        } else {
          return [];
        }
      })
    );
    this.show();
    this.setUploadEvent();
  }

  buildItemForm(item) {
    this.itemForm.patchValue({
      company_id: item.company_id || "",
      building_id: parseInt(item.building_id, 0) || "",
      name: item.name || "",
      category: item.category || "",
      description: item.description || "",
      service_interval: item.service_interval || "",
      last_service_time: parseDate(item, "last_service_time"),
      next_service_time: parseDate(item, "next_service_time"),
    });
    this.dataForm.patchValue({
      ppm_id: get(item, "data.ppm_id", null),
      notification_period: get(item, "data.notification_period", 0),
      // vehicle_make: get(item, 'data.vehicle_make', ''),
      asset_condition: get(item, "data.asset_condition", ""),
      compliant_status: get(item, "data.compliant_status", ""),
      installation_date: get(item, "data.installation_date", ""),
      warranty_end_date: get(item, "data.warranty_end_date", ""),
      asset_tag: get(item, "data.asset_tag", ""),
      model_number: get(item, "data.model_number", ""),
      serial_number: get(item, "data.serial_number", ""),
      life_span: get(item, "data.life_span", ""),
      room_id: get(item, "data.room_id", null),
    });
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: AssetModel) => {
        this.item = data;
        this.services = data.services;
        this.buildItemForm(data);
      });
  }

  openEditServicePopup(row) {
    this.popup.openServiceEditPage(row.id);
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.options = data.asset;
    });
  }

  getBuildings(company_id) {
    let loader = this.loader.open();
    this.companyService
      .getBuildingsByClientId(company_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.buildings = data;
        this.rooms = [];
        let building_id = this.itemForm.get('building_id').value;
        if (building_id) {
          this.getPPMs(building_id);
          this.buildings.some((building) => {
            if (building.id == building_id) {
              this.rooms = _.get(building, 'data.rooms', []);
              return true;
            }
            return false;
          });
        }
      });
  }

  getPPMs(building_id) {
    let loader = this.loader.open();
    this.ppmService
      .readByBuilding(building_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.ppms = data.ppms;
      });
  }
  submit() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    let dataFormValue = this.dataForm.value;
    itemFormValue["last_service_time"] = this.timeConvertService.convert(
      itemFormValue["last_service_time"]
    );
    itemFormValue["next_service_time"] = this.timeConvertService.convert(
      itemFormValue["next_service_time"]
    );
    dataFormValue["installation_date"] = this.timeConvertService.convert(
      dataFormValue["installation_date"]
    );
    dataFormValue["warranty_end_date"] = this.timeConvertService.convert(
      dataFormValue["warranty_end_date"]
    );
    this._service
      .update(this.id, { ...itemFormValue, data: dataFormValue })
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this._service.refreshOptions();
        this.toast.show("Asset Updated!");
      });
  }

  displayWith() {
    let that = this;
    return (value) => {
      if (value) {
        return that.translate.instant(value);
      }
      return undefined;
    };
  }

  setUploadEvent() {
    this.uploader = this.uploaderService.get({
      url: this.documentService.uploadUrl(),
      autoUpload: true,
    });
    this.uploader.onBuildItemForm = (fileItem, form) => {
      this.uploader.options.additionalParameter = {
        type: "thumb",
        subject: "asset_thumb",
        model: "asset",
        model_id: this.id,
      };
    };
    let loader;
    this.uploader.onBeforeUploadItem = (fileItem) => {
      loader = this.loader.open("Uploading...");
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      if (status === 200) {
        this.toast.show("Add success!");
        this.item.thumb = JSON.parse(response).full_url;
        this.uploader.clearQueue();
      } else {
        this.toast.show("Add failed!");
      }
      this.uploader.destroy();
      this.show_uploader = false;
      setTimeout(() => {
        this.show_uploader = true;
        this.setUploadEvent();
        loader.close();
      }, 618 * 2);
    };
  }

  searchRoom(term, item) {
    return ("" + item.floor + "" + item.name).toString().toLowerCase().indexOf(term.toString().toLowerCase()) >= 0;
  }
}
