import { Component, OnInit, Inject } from '@angular/core';
import { ModelAttributeOptions, QuotationItemOptions } from '@app/shared/models/options';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ModelAttributeOptionService } from '@app/shared/services/api/model-attribute-option.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuotationItemService } from '@app/shared/services/api/quotation-item.service';
import { AppLoaderService } from '@app/shared/services/system/app-loader/app-loader.service';
import { ApplicationItemService } from '@app/shared/services/api/application-item.service';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-application-item-create',
  templateUrl: './application-item-create.component.html',
  styleUrls: ['./application-item-create.component.scss']
})
export class ApplicationItemCreateComponent implements OnInit {
  public id;
  public itemFormGroup: FormGroup = new FormGroup({
    item_type: new FormControl('', [Validators.required]),
    item_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    item_description: new FormControl(''),
    item_unit_price: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_quantity: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    item_status: new FormControl('', [Validators.required]),
    operator: new FormControl('+', Validators.required),
  });
  public lineTotal = 0;
  public options: QuotationItemOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; },
    public dialogRef: MatDialogRef<ApplicationItemCreateComponent>,
    private maoService: ModelAttributeOptionService,
    public quotationItemService: QuotationItemService,
    public loader: AppLoaderService,
    public applicationItemService: ApplicationItemService,
    public toast: NotifyService,
  ) {
    this.id = data.id;
  }

  ngOnInit() {
    this.getOptions();
    this.itemFormGroup
      .get('item_unit_price')
      .valueChanges.subscribe(item_unit_price => {
        let item_quantity = this.itemFormGroup.value.item_quantity || 0;
        let price = item_unit_price || 0;
        this.lineTotal = item_quantity * price;
      });
    this.itemFormGroup
      .get('item_quantity')
      .valueChanges.subscribe(item_quantity => {
        let q = item_quantity || 0;
        let price = this.itemFormGroup.value.item_unit_price || 0;
        this.lineTotal = q * price;
      });
    this.lineTotal =
      this.itemFormGroup.value.item_quantity *
      this.itemFormGroup.value.item_unit_price;
  }

  getOptions() {
    this.maoService
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.options = data.quotation_item;
      });
  }

  submit() {
    let data = { ...this.itemFormGroup.value, quotation_id: this.id };
    let loader = this.loader.open();
    this.applicationItemService
      .store(data)
      .finally(() => loader.close())
      .subscribe(() => {
        this.toast.show('Item Added!');
        if (this.data) {
          this.dialogRef.close(true);
        }
      });
  }

  close() {
    if (this.data) {
      this.dialogRef.close();
    }
  }
}
