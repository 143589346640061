import {Injectable} from '@angular/core';
import {Conditions} from '@shared/models/conditions.model';
import {TableConfigModel} from '@shared/models/table-config.model';
import {tableConfigs} from '@shared/config';
import {LaravelPageRequest} from '@app/shared/models/laravel-page-request.model';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ModelAttributeOptionService} from './model-attribute-option.service';
import {ModelAttributeOptions, QuotationOptions} from '@app/shared/models/options';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {NotifyService} from '@shared/services/notify.service';

@Injectable()
export class ApplicationService {

  route_prefix = 'applications';
  public options: QuotationOptions;

  tableConfig: TableConfigModel = tableConfigs.application;

  public conditions: Conditions = {
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private mao: ModelAttributeOptionService,
    private confirm: AppConfirmService,
    private loader: AppLoaderService,
    private toast: NotifyService,
  ) {
    this.getOptions();
  }

  private getOptions() {
    this.mao.all()
      .subscribe((data: ModelAttributeOptions) => {
        this.conditions.status.items = data.quotation.status;
        this.options = data.quotation;
      });
  }

  generateInvoice(quotationId, body) {
    return this.http.post(`${this.route_prefix}/${quotationId}/generate-invoice`, body);
  }

  approve(quotationId, body?: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/approve`, body);
  }

  decline(quotationId, body?: { note: string; }) {
    return this.http.post(`${this.route_prefix}/${quotationId}/decline`, body);
  }

  getKPI(supplierId, clientId, serviceIds) {
    return this.http.post('applications/kpi', {supplier_id: supplierId, client_id: clientId, service_ids: serviceIds});
  }

  index(params: LaravelPageRequest) {
    return this.http.get(this.route_prefix, {params: params.request()});
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  gotoTable() {
    this.router.navigate([this.tableConfig.urlPrefix]);
  }

  getServiceKPI(serviceId, contractorId) {
    return this.http.get('applications/service-kpi', {params: {service_id: serviceId, contractor_id: contractorId}});
  }

  getServiceKPIByApplicationItemId(itemId) {
    return this.http.get('applications/service-kpi', {params: {application_item_id: itemId}});
  }

  getApplicationPeriod(applicationId) {
    return this.http.get('applications/period', {params: {application_id: applicationId}});
  }

  deleteServiceAllKPI(serviceId) {
    return this.http.post('applications/delete-service-all-kpi', {service_id: serviceId});
  }

  deleteApplicationAllKPI(applicationId) {
    return this.http.post('applications/delete-all-kpi', {application_id: applicationId});
  }

  createApplicationKPI(applicationId) {
    return this.http.post('applications/create-all-kpi', {application_id: applicationId});
  }

  createApplicationItemKPI(applicationItemId) {
    return this.http.post('applications/create-item-kpi', {application_item_id: applicationItemId});
  }
}

export interface KPIItem {
  level: number;
  penalty_percentage?: number;
  penalty_unit_price?: number;
  penalty: number;
  name: string;
  description: string;
  other: any;
  compliance: boolean;
}
