import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-asset-edit-popup',
  template: `
    <app-dialog-header name="Asset Edit {{id}}" (onClose)="dialogRef.close()"></app-dialog-header>
    <app-asset-edit mat-dialog-content [id]="id" [inPopup]="true" (onSubmitted)="dialogRef.close(true)" (onBack)="dialogRef.close()"></app-asset-edit>`
})
export class AssetEditPopupComponent implements OnInit {
  public id;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    public dialogRef: MatDialogRef<AssetEditPopupComponent>,
  ) {
    if (this.data.id) {
      this.id = data.id;
    }
  }

  ngOnInit() {
  }
}
