import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BuildingModel} from '@app/shared/models/building.model';

@Component({
  selector: 'app-building-detail-card',
  templateUrl: './building-detail-card.component.html',
  styleUrls: ['./building-detail-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingDetailCardComponent implements OnInit {

  @Input() building: BuildingModel;

  constructor() {
  }

  ngOnInit() {
  }

}
