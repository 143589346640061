import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppLoaderService } from '@shared/services/system/app-loader/app-loader.service';
import { DocumentService } from '@shared/services/api/document.service';
import { DocumentModel } from '@shared/models/document.model';
import * as _ from 'lodash';
import { parseDate } from '@shared/helpers/utils';
import { NotifyService } from '@app/shared/services/notify.service';
import {TimeConvertService} from '@shared/services/time-convert.service';

@Component({
  selector: 'app-service-certificate-verify',
  templateUrl: './service-certificate-verify.component.html',
  styleUrls: ['./service-certificate-verify.component.scss']
})
export class ServiceCertificateVerifyComponent implements OnInit {
  @Input() id = null;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();

  public itemForm: FormGroup;
  public item: DocumentModel;

  constructor(
    private fb: FormBuilder,
    private _service: DocumentService,
    private loader: AppLoaderService,
    private toast: NotifyService,
    private timeConvertService: TimeConvertService
  ) {
  }

  ngOnInit() {
    this.buildItemForm();
    this.show();
  }

  buildItemForm(item = {}) {
    this.itemForm = this.fb.group({
      description: [_.get(item, 'approval.description', '')],
      compliance_sign_off: [_.get(item, 'service.data.assets.0.compliance_sign_off', false)],
      next_service_date: [parseDate(item, 'service.data.assets.0.next_service_date', '')],
      compliance_completion_date: [parseDate(item, 'service.data.assets.0.compliance_completion_date', '')]
    });
  }

  show() {
    let loader = this.loader.open();
    this._service.show(this.id)
      .finally(() => loader.close())
      .subscribe((data: DocumentModel) => {
        this.item = data;
        this.buildItemForm(data);
      });
  }

  update() {
    let loader = this.loader.open();
    let itemFormValue = this.itemForm.value;
    itemFormValue['next_service_date'] = this.timeConvertService.convert(itemFormValue['next_service_date']);
    itemFormValue['compliance_completion_date'] = this.timeConvertService.convert(itemFormValue['compliance_completion_date']);
    this._service.verifyCertificate(this.id, { ...itemFormValue })
      .finally(() => loader.close())
      .subscribe((data: DocumentModel) => {
        this.show();
        this.toast.show('Update success.');
        this.onSubmitted.emit();
      });
  }
}
