import {Component, OnDestroy, OnInit} from '@angular/core';
import {find, get, keys} from 'lodash';
import {ServiceExtraService} from '../service-extra.service';

@Component({
  selector: 'app-service-extra-create',
  templateUrl: './service-extra-create.component.html',
  styleUrls: ['./service-extra-create.component.scss'],
})
export class ServiceExtraCreateComponent implements OnInit, OnDestroy {
  public keys = keys;

  public get serviceOptions() {
    return this.serviceExtraService.serviceOptions;
  };

  constructor(
    public serviceExtraService: ServiceExtraService,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.serviceExtraService.clear();
  }

  getExtraFormValueData(key) {
    return get(find(this.serviceExtraService.extraForm, ['option_value', key]), 'data');
  }
}
