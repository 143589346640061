export const dashboardConfigs = {
  service: {
    open_services: {
      display: "Open Services",
      items: [
        { key: "reactive" },
        { key: "quote_approval" },
        { key: "ppm" },
        { key: "quote_request" },
        { key: "temp_fix" },
        { key: "part_required" },
        { key: "another_visit" },
      ],
    },
    management_actions: {
      display: "Management Actions",
      items: [
        { key: "outstanding_uplifts" },
        { key: "urgent_p1" },
        { key: "recall" },
        { key: "warranty" },
        { key: "jobs_not_accepted" },
        { key: "jobs_not_assigned" },
        { key: "holding_pool_jobs" },
        { key: "over_due_ppm_services" },
        { key: "certificates_missing" },
        { key: "compliance_sign_off" },
      ],
    },
    reactive_disciplines: {
      display: "Reactive Disciplines",
      items: [
        { key: "access_control" },
        { key: "air_conditioning" },
        { key: "air_handling_unit" },
        { key: "auto_doors" },
        { key: "barriers" },
        { key: "bms" },
        { key: "cctv" },
        { key: "drainage" },
        { key: "electrical" },
        { key: "emergency_lighting" },
        { key: "equipment" },
        { key: "extract_fans" },
        { key: "fire_alarm" },
        { key: "fire_extinguisher" },
        { key: "flooring" },
        { key: "fra_ppm_remedial_works" },
        { key: "general_building" },
        { key: "groundwork_civils" },
        { key: "heating_and_gas" },
        { key: "intruder_alarm" },
        { key: "lifts" },
        { key: "lighting" },
        { key: "pest_control" },
        { key: "roofing" },
        { key: "weekly_check_list" },
        { key: "project" },
        { key: "mechanical" },
        { key: "fuel/gas" },
        { key: "flushing" },
        { key: "escorting" },
        { key: "water_temperture" },
      ],
    },
    quote_approval_disciplines: {
      display: "Quote Approval Disciplines",
      items: [
        { key: "access_control" },
        { key: "air_conditioning" },
        { key: "air_handling_unit" },
        { key: "auto_doors" },
        { key: "barriers" },
        { key: "bms" },
        { key: "cctv" },
        { key: "drainage" },
        { key: "electrical" },
        { key: "emergency_lighting" },
        { key: "equipment" },
        { key: "extract_fans" },
        { key: "fire_alarm" },
        { key: "fire_extinguisher" },
        { key: "flooring" },
        { key: "general_building" },
        { key: "groundwork_civils" },
        { key: "heating_and_gas" },
        { key: "intruder_alarm" },
        { key: "lifts" },
        { key: "lighting" },
        { key: "pest_control" },
        { key: "roofing" },
      ],
    },
    ppm_disciplines: {
      display: "PPM Disciplines",
      items: [
        { key: "access_control_ppm" },
        { key: "air_conditioning_ppm" },
        { key: "air_handling_unit_ppm" },
        { key: "auto_doors_ppm" },
        { key: "barrier_ppm" },
        { key: "bms_ppm" },
        { key: "cctv_and_security_systems_ppm" },
        { key: "drainage_ppm" },
        { key: "dry_riser_ppm" },
        { key: "emergency_lighting_ppm" },
        { key: "equipment_ppm" },
        { key: "extract_fans_ppm" },
        { key: "fire_alarm_ppm" },
        { key: "fire_damper_ppm" },
        { key: "fire_extinguisher_ppm" },
        { key: "fire_rig_ppm" },
        { key: "fire_suppression_ppm" },
        { key: "fixed_wire_test_ppm" },
        { key: "fra_ppm" },
        { key: "general_building_ppm" },
        { key: "generator_ppm" },
        { key: "gutter_clearance_ppm" },
        { key: "hangar_door_ppm" },
        { key: "heating_and_gas_ppm" },
        { key: "intruder_alarm_ppm" },
        { key: "lift_ppm" },
        { key: "lightning_protection_ppm" },
        { key: "pat_test_ppm" },
        { key: "pressure_vessel_ppm" },
        { key: "pumps_ppm" },
        { key: "rcd/rcbo_ppm" },
        { key: "refrigeration_ppm" },
        { key: "smoke_vent_ppm" },
        { key: "ups_ppm" },
        { key: "valve_inspection_ppm" },
        { key: "water_regulation_ppm" },
        { key: "ahu_eab_3000_tw_hp_ppm" },
        { key: "ahu_general_ppm" },
        { key: "ahu_mecury_filter_change_ppm" },
        { key: "ahu_swegon_ppm" },
        { key: "ahu_trane_ppm" },
        { key: "ahu_extract_service_ppm" },
        { key: "ahu_supply_service_ppm" },
        { key: "airside_heaters_ppm" },
        { key: "anpr_&_barriers_ppm" },
        { key: "boiler_maintenance_ppm" },
        { key: "boilers_&_plant_ppm" },
        { key: "booster_set_ppm" },
        { key: "building_controls_ppm" },
        { key: "car_park_drainage_service_ppm" },
        { key: "cctv_ppm" },
        { key: "chiller_ppm" },
        { key: "lightning_protection_ppm" },
        { key: "chimey_inspection_ppm" },
        { key: "chlorination_(tank_clean)_ppm" },
        { key: "cold_water_storage_tanks_ppm" },
        { key: "comfort_cooling_&_ventilation_ppm" },
        { key: "comfort_cooling_maintenance_liverpool_ppm" },
        { key: "comfort_cooling_system_ppm" },
        { key: "comms_room_cooling_ej3_ppm" },
        { key: "comms_room_cooling_h89_ppm" },
        { key: "compressor_ppm" },
        { key: "contingency_plan_ppm" },
        { key: "controls_ppm" },
        { key: "door_maintenance_ppm" },
        { key: "electric_water_heater_service_ppm" },
        { key: "entrance_doors_ppm" },
        { key: "external_drainage_service_ppm" },
        { key: "extracting_cleaning_ppm" },
        { key: "fan_coil/dx_split_system_service_ppm" },
        { key: "fire_doors_ppm" },
        { key: "floor_scrubber_ppm" },
        { key: "gas_fired_boilers/water_heaters_ppm" },
        { key: "gas_inspection_ppm" },
        { key: "gas_safety_ppm" },
        { key: "gas_suppression_system_ppm" },
        { key: "kitchen_extract/duct_work_clean_ppm" },
        { key: "l8_water_treatment_ppm" },
        { key: "legionella_service_ppm" },
        { key: "legionella_water_risk_assessments_ppm" },
        { key: "lifting_equipment_ppm" },
        { key: "monthly_water_testing_&_treatment_ppm" },
        { key: "overdoor_heaters_ppm" },
        { key: "passenger_platform_lift_ppm" },
        { key: "platform_lift_ppm" },
        { key: "pressurisaiton_units_ppm" },
        { key: "quarterly_drainage_inspection_service_ppm" },
        { key: "roof_guttering_and_cleaning_ppm" },
        { key: "l8_water_regulations_ppm" },
        { key: "staircase_inspection_ppm" },
        { key: "surface_water_ppm" },
        { key: "tank_clean_ppm" },
        { key: "temperature_monitoring_ppm" },
        { key: "tmv_servicing_ppm" },
        { key: "under_cupboard_water_heaters_ppm" },
        { key: "water_boiler/chiller_tap_service_ppm" },
        { key: "water_cisterns_ppm" },
        { key: "water_heater_ppm" },
        { key: "water_temperture_monitoring_ppm" },
        { key: "weekly_water_testing_ppm" },
      ],
    },
  },
  ppm: {
    ppm: {
      display: "PPM",
      items: [
        { key: "ppm_to_log" },
        { key: "upcoming_ppms" },
        { key: "over_due_ppms_not_logged" },
      ],
    },
  },
  quotation: {
    quoted_works: {
      display: "Quoted Works",
      items: [
        { key: "total_quotes_pending" },
        { key: "health_and_safety_quotes" },
        { key: "compliance_quotes" },
        { key: "trading_critical_quotes" },
        { key: "remedial_quotes" },
        { key: "holding_pool_quotes" },
        { key: "failed_benchmarking_quotes" },
        { key: "client_review" },
      ],
    },
  },
};

export const tableConfigs = {
  service: {
    displayName: "Service",
    key: "service",
    urlPrefix: "service",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Client", key: "client_name" },
      { display: "Building", key: "building_name" },
      { display: "Type", key: "service_type", width: 100 },
      { display: "Discipline", key: "discipline" },
      { display: "Contractors", key: "contractors", flexGrow: 2 },
      { display: "Creator", key: "create_user_name" },
      { display: "Status", key: "status", useColor: true, width: 100 },
      { display: "Priority", key: "severity" },
    ],
  },
  sale: {
    displayName: "Sale",
    key: "sale",
    urlPrefix: "",
    displayedColumns: [
      { display: "Service", key: "service_id" },
      { display: "Type", key: "item_type" },
      { display: "Name", key: "item_name" },
      { display: "Desc", key: "item_description" },
      { display: "Total", key: "item_total_price" },
      { display: "Unit Price", key: "item_unit_price" },
      { display: "Unit Duration", key: "item_unit_duration" },
      { display: "Quantity", key: "item_quantity" },
      { display: "Operator", key: "engineer.name" },
    ],
  },
  quotation: {
    displayName: "Quotation",
    key: "quotation",
    urlPrefix: "quotation",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Client", key: "target_company_name" },
      { display: "Building", key: "data.building_name" },
      { display: "Total", key: "total", width: 100 },
      { display: "Type", key: "type" },
      { display: "Contractor", key: "source_company_name", flexGrow: 2 },
      { display: "Creator", key: "create_user_name", flexGrow: 2 },
      { display: "Date", key: "created_at", dateFormat: "short" },
      { display: "Status", key: "status", useColor: true },
    ],
  },
  purchase_order: {
    displayName: "Purchase Order",
    key: "purchase_order",
    urlPrefix: "purchase-order",
    displayedColumns: [
      { display: "PO Ref", key: "reference", width: 100 },
      { display: "Building", key: "building_name" },
      { display: "Total", key: "total", width: 100 },
      { display: "Client", key: "source_company_name" },
      { display: "Issued to", key: "target_company_name" },
      { display: "Status", key: "status", useColor: true, width: 100 },
      { display: "Created at", key: "created_at", dateFormat: "short"},
    ],
  },
  invoice: {
    displayName: "Certificate",
    key: "invoice",
    urlPrefix: "invoice",
    displayedColumns: [
      { display: "Reference", key: "reference", prefix: "C" },
      // { display: 'Type', key: 'type', width: 100 },
      { display: "Contractor", key: "source_company_name" },
      { display: "Client", key: "target_company_name" },
      { display: "Subtotal", key: "subtotal", width: 100 },
      { display: "Vat", key: "vat", width: 100 },
      { display: "Total", key: "total", width: 100 },
      { display: "Status", key: "status", useColor: true, width: 100 },
      {
        display: "Issued At",
        key: "created_at",
        width: 100,
        dateFormat: "short",
      },
    ],
  },
  company: {
    displayName: "Company",
    key: "company",
    urlPrefix: "settings/company",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Name", key: "name" },
      { display: "Email", key: "email" },
      { display: "Types", key: "types_show", useColor: true, isArray: true },
      { display: "Phone", key: "phone" },
      { display: "Industry", key: "industry" },
      { display: "Status", key: "status", useColor: true, width: 100 },
    ],
  },
  user: {
    display_name: "User",
    key: "user",
    urlPrefix: "settings/user",
    displayedColumns: [
      { display: "Id", key: "id", useColor: false },
      { display: "Name", key: "name", useColor: false },
      // {display: 'Email', key: 'email', useColor: false},
      { display: "Identify", key: "user_identity", useColor: false },
      { display: "Phone", key: "phone", useColor: false },
      { display: "Status", key: "status", useColor: true },
    ],
  },
  building: {
    displayName: "Building",
    key: "building",
    urlPrefix: "settings/building",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Name", key: "name" },
      { display: "Company", key: "company_name" },
      { display: "Status", key: "status", useColor: true, width: 100 },
      { display: "Email", key: "email" },
      { display: "Phone", key: "phone" },
      { display: "Code", key: "code", width: 100 },
    ],
  },
  application: {
    displayName: "Application",
    key: "application",
    urlPrefix: "application",
    displayedColumns: [
      { display: "Id", key: "id", width: 100, prefix: "A" },
      { display: "Total", key: "total", width: 100 },
      { display: "Contractor", key: "source_company_name" },
      { display: "Client", key: "target_company_name" },
      { display: "Status", key: "status", useColor: true, width: 100 },
      { display: "Submitted At", key: "created_at", dateFormat: "short" },
    ],
  },
  purchase_order_item: {
    displayName: "Purchase Order Item",
    key: "purchase_order_item",
    urlPrefix: "",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Type", key: "item_type", width: 100 },
      { display: "Quantity", key: "item_quantity", width: 100 },
      { display: "Name", key: "item_name" },
      { display: "Unit Price", key: "item_unit_price", width: 100 },
      { display: "Total Price", key: "item_total_price", width: 100 },
      { display: "Status", key: "item_status", useColor: true, width: 100 },
    ],
  },
  quotation_item: {
    displayName: "Quotation Item",
    key: "quotation_item",
    urlPrefix: "",
    displayedColumns: [
      { display: "Id", key: "id" },
      { display: "Service Id", key: "item_type_id" },
      { display: "Type", key: "item_type" },
      { display: "Quantity", key: "item_quantity" },
      { display: "Name", key: "item_name" },
      { display: "Unit Price", key: "item_unit_price" },
      { display: "Total Price", key: "item_total_price" },
      { display: "Status", key: "item_status", useColor: true },
    ],
  },
  invoice_item: {
    displayName: "Invoice Item",
    key: "invoice_item",
    urlPrefix: "",
    displayedColumns: [
      { display: "Id", key: "id" },
      { display: "Service Id", key: "item_type_id" },
      { display: "Type", key: "item_type" },
      { display: "Quantity", key: "item_quantity" },
      { display: "Name", key: "item_name" },
      { display: "Unit Price", key: "item_unit_price" },
      { display: "Total Price", key: "item_total_price" },
      { display: "Status", key: "item_status", useColor: true },
    ],
  },
  service_visit: {
    displayName: "Service Visit",
    key: "service_visit",
    urlPrefix: "",
    displayedColumns: [
      { display: "Id", key: "id" },
      { display: "User Name", key: "user_name" },
      { display: "Check In Time", key: "check_in_time", dateFormat: "short" },
      { display: "Check Out Time", key: "check_out_time", dateFormat: "short" },
      { display: "Service Status", key: "service_status", useColor: true },
      { display: "Observer", key: "observer" },
      { display: "Service Id", key: "service_id" },
    ],
  },
  asset: {
    displayName: "Asset",
    key: "asset",
    urlPrefix: "settings/asset",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Company Name", key: "company_name" },
      { display: "Building Name", key: "building_name" },
      { display: "Category", key: "category" },
      { display: "Name", key: "name" },
      { display: "Service Interval", key: "service_interval", width: 100 },
      {
        display: "Last Service Time",
        key: "last_service_time",
        dateFormat: "short",
      },
      {
        display: "Next Service Time",
        key: "next_service_time",
        dateFormat: "short",
      },
      { display: "Status", key: "status", useColor: true, width: 100 },
    ],
  },
  ppm: {
    displayName: "Ppm",
    key: "ppm",
    urlPrefix: "ppm",
    displayedColumns: [
      { display: "Id", key: "id", width: 100 },
      { display: "Client", key: "company_name"},
      { display: "Building Name", key: "building_name" },
      { display: "Category", key: "category" },
      { display: "Name", key: "name" },
      {
        display: "Condition",
        key: "data.asset_condition",
      },
      {
        display: "Compliance Status",
        key: "data.compliant_status"
      }
    ],
  },
  approval: {
    display_name: "Approval",
    key: "approval",
    displayedColumns: [
      { display: "Id", key: "id" },
      { display: "Subject", key: "subject" },
      { display: "Create User", key: "create_user_name" },
      { display: "Create Company", key: "company_name" },
      { display: "Status", key: "status", useColor: true },
    ],
  },
};

export const tableReplaceValueColumn = {
  severity: (value) => {
    return value;
  },
  item_total_price: (value) => {
    return parseFloat(value).toFixed(2);
  },
  total: (value) => {
    return parseFloat(value).toFixed(2);
  },
};
