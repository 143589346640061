import {Component, Injector, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {InvoiceItemService} from '@shared/services/api/invoice-item.service';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {InvoiceOptions} from '@shared/models/options';
import {CompanyService} from '@shared/services/api/company.service';
import {PopupService} from '@shared/services/popup.service';
import {BaseEditComponent} from '@shared/components/base/base-edit.component';
import {InterfaceBaseEdit} from '@shared/interfaces/interface-base-edit';
import {tableConfigs} from '@shared/config';
import {InvoiceService} from '@shared/services/api/invoice.service';
import {InvoiceModel} from '@app/shared/models/invoice.model';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html'
})
export class InvoiceEditComponent extends BaseEditComponent
  implements OnInit, InterfaceBaseEdit {
  tableConfig = tableConfigs.invoice;
  tableConfigs = tableConfigs;
  public itemFormGroup: FormGroup = new FormGroup({
    target_company_id: new FormControl(''),
    source_company_id: new FormControl(''),
    vat: new FormControl(0, [Validators.min(0)]),
    status: new FormControl(''),
    subject: new FormControl(''),
    description: new FormControl(''),
  });
  @Input() id;
  @Input() inPopup = false;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() onBack = new EventEmitter();
  public item: InvoiceModel;

  public get invoiceOptions(): InvoiceOptions {
    return this.invoiceService.options;
  };

  public get clients() {
    return this.companyService.clients;
  };

  public get suppliers() {
    return this.companyService.contractors;
  };

  public sales = [];

  constructor(
    private itemService: InvoiceItemService,
    private companyService: CompanyService,
    private maoService: ModelAttributeOptionService,
    private popup: PopupService,
    public injector: Injector,
    public _service: InvoiceService,
    public invoiceService: InvoiceService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.show();
    this.getSales();
  }

  show() {
    let loader = this.loader.open();
    this._service
      .show(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.item = data;
        this.itemFormGroup.patchValue(data);
      });
  }

  submit() {
    let data = {...this.itemFormGroup.value};
    let loader = this.loader.open();
    this._service
      .update(this.id, data)
      .finally(() => {
        loader.close();
        this.show();
      })
      .subscribe(() => {
        this.toast.show('Invoice updated!');
      });
  }

  cancel() {
    this.confirmService
      .confirm({
        message:
          'After cancelling, you can generate certificate again through application.'
      })
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this._service
            .cancel(this.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.toast.show('Invoice cancel success.');
              this.show();
            });
        }
      });
  }

  openInvoiceItemCreatePopup() {
    this.popup
      .openInvoiceItemCreatePage(this.id)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  deleteItem(row) {
    this.confirmService
      .confirm({message: `Delete ${row.name}?`})
      .subscribe(res => {
        if (res) {
          let loader = this.loader.open();
          this.itemService
            .destroy(row.id)
            .finally(() => loader.close())
            .subscribe(() => {
              this.refresh();
              this.toast.show('Invoice item deleted!');
            });
        }
      });
  }

  openEditItemPopup(row) {
    this.popup
      .openInvoiceItemEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.show();
  }

  openEditServicePopup(row) {
    this.popup
      .openServiceEditPage(row.id)
      .afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.refresh();
      });
  }

  openEditApplication(row) {
    this.router.navigate(['/application/edit', row.id]);
  }

  getSales() {
    this._service.getSales(this.id).subscribe((sales: any) => {
      this.sales = sales;
    });
  }
}
