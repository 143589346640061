import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {UserService} from '@shared/services/api/user.service';
import {ModelAttributeOptions, UserCompanyOptions, UserOptions} from '@shared/models/options';
import {CompanyModel} from '@shared/models/company.model';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {CompanyService} from '@shared/services/api/company.service';
import {NotifyService} from '@shared/services/notify.service';
import * as _ from 'lodash';
import { LaravelPageRequest } from '@app/shared/models/laravel-page-request.model';
import { LaravelPageResponse } from '@app/shared/models/laravel-page-response.model';
import {tap} from 'rxjs/operators';
import { PopupService } from '@app/shared/services/popup.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  public loading$ = false;
  public userOptions: UserOptions;
  public userCompanyOptions: UserCompanyOptions;
  public companies: CompanyModel[] = [];
  public filterCompanies: CompanyModel[] = [];
  public relationTypes = {};
  public usedRelationTypes = [];
  public userCompany = [];
  public roles = [];
  public emailEvents = [];
  public user_holiday = {
    items: [],
    columns: [],
    loading$: true,
    paginator: {
      totalElements: 0,
      pageNumber: 0,
      size: 20,
    }
  };
  @Input() id;

  public itemForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    email: new FormControl({value: '', disabled: true}),
    user_identity: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    status: new FormControl(''),
    password: new FormControl(),
    roles: new FormControl([]),
  });

  public relationForm: FormGroup = new FormGroup({
    company: new FormControl(null, Validators.required),
    type: new FormControl([], Validators.required),
    emails: new FormControl([])
  });
  public item;

  constructor(
    private _service: UserService,
    private loader: AppLoaderService,
    private confirm: AppConfirmService,
    private companyService: CompanyService,
    private toast: NotifyService,
    private popup: PopupService,
    private mao: ModelAttributeOptionService
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.show(this.id);
    this.getRoles();
    this.getEmailEvents();
    this.getOptions();
    this.loadPage().subscribe();
    this.relationForm.controls['company'].valueChanges.subscribe((company) => {
      this.usedRelationTypes = [];
      if (company) {
        company.type.forEach((type) => {
          if (this.relationTypes.hasOwnProperty(type)) {
            this.usedRelationTypes =  this.usedRelationTypes.concat(this.relationTypes[type]);
          }
        });
        this.usedRelationTypes = _.uniq(this.usedRelationTypes);
      }
    });
  }

  getOptions() {
    this.mao
      .all()
      .subscribe((data: ModelAttributeOptions) => {
        this.userOptions = data.user;
        this.userCompanyOptions = data.user_company;
        data.user_company.type.forEach((type) => {
          if (type.option_name === 'user_company_type') {
            this.relationTypes = type.data;
            console.log(this.relationTypes);
            this.getRelation();
          }
        });
      });
  }

  show(id) {
    let loader = this.loader.open();
    this._service.show(id)
      ._finally(() => loader.close())
      .subscribe((data: any) => {
        data.roles = data.roles.map((role) => {
          return role.name;
        });
        this.item = data;
        this.itemForm.patchValue(data);
      });
  }

  getCompanies() {
    this.companyService.managementCompanies$
      .subscribe((data: CompanyModel[]) => {
        this.companies = data;
        this.refreshFilterCompanies();
      });
  }

  submit() {
    let loader = this.loader.open();
    this._service.update(this.id, this.itemForm.value)
      .finally(() => loader.close())
      .subscribe(() => {
        this.refresh();
        this.toast.show('User Updated!');
      });
  }

  refresh() {
    this.initData();
  }

  refreshFilterCompanies() {
    this.filterCompanies = this.companies.filter((company) => {
      return !this.userCompany.some((relation) => {
        return relation.company.id === company.id;
      });
    });
  }

  getRelation() {
    let loader = this.loader.open();
    this._service.getUserCompanyRelation(this.id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.userCompany = data.map((relation) => {
          let usedRelation = [];
          relation.company.type.forEach((type) => {
            if (this.relationTypes.hasOwnProperty(type)) {
              usedRelation = usedRelation.concat(this.relationTypes[type]);
            }
          });
          relation['usedRelationTypes'] = _.uniq(usedRelation);
          relation['emails'] = [];
          _.forIn(_.get(relation, 'data.emails', {}), (events, email) => {
            relation['emails'].push({ email: email, events: events });
          });
          return relation;
        });
        this.getCompanies();
      });
  }

  addRelation() {
    this.userCompany.push({...this.relationForm.value, usedRelationTypes: this.usedRelationTypes, status: 'active'});
    this.relationForm.reset();
    this.refreshFilterCompanies();
  }

  removeRelation(index) {
    this.confirm.confirm().subscribe(res => {
      if (res) {
        this.userCompany.splice(index, 1);
        this.refreshFilterCompanies();
      }
    });
  }

  submitUserCompany() {
    this.confirm.confirm().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this._service.updateUserCompanyRelation(this.id, {user_company: this.userCompany})
          .finally(() => loader.close())
          .subscribe((data: any) => {
            this.toast.show('User Company Relation Updated!');
            this.refresh();
          });
      }
    });
  }

  getEmailEvents() {
    this._service.readEmailEvents()
      .subscribe((data: any) => {
        this.emailEvents = data.events;
      });
  }

  getRoles() {
    this._service.readRoles()
      .subscribe((data: any) => {
        this.roles = data.roles;
      });
  }

  addEmailSubject(index) {
    this.userCompany[index].emails.push({ email: '', events: [] });
  }

  removeEmail(relationIndex, emailIndex) {
    this.userCompany[relationIndex].emails.splice(emailIndex);
  }

  clickPage({ offset }) {
    this.user_holiday.paginator.pageNumber = offset + 1;
    this.loadPage().subscribe();
  }

  loadPage({ keyword }: any = { keyword: '' }) {
    this.user_holiday.loading$ = true;
    let request = new LaravelPageRequest(this.user_holiday.paginator.size, this.user_holiday.paginator.pageNumber, {}, keyword);
    return this._service.getUserHolidays(this.id, request)
      .pipe(
        tap(
          (response: LaravelPageResponse) => {
            this.user_holiday.paginator.size = response.per_page;
            this.user_holiday.paginator.pageNumber = response.current_page;
            this.user_holiday.paginator.totalElements = response.total;
            this.user_holiday.items = response.data;
          }
        ),
      )
      .finally(() => this.user_holiday.loading$ = false);
  }

  openCreateHolidayPopup() {
    this.popup.openHolidayCreatePopup(this.id)
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.user_holiday.paginator.pageNumber = 0;
        this.loadPage().subscribe();
      }
    })
  }

  openEditHolidayPopup(id) {
    this.popup.openHolidayUpdatePopup(id)
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.loadPage().subscribe();
      }
    })
  }

  deleteHoliday(id) {
    this.confirm.confirm().subscribe(res => {
      if (res) {
        let loader = this.loader.open();
        this._service.deleteHoliday({id: id})
          ._finally(() => loader.close())
          .subscribe((data: any) => {
            this.loadPage().subscribe();
          });
      }
    });
  }
}
