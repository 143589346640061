import { Injectable } from '@angular/core';
import { Conditions } from '@shared/models/conditions.model';
import { dashboardConfigs, tableConfigs } from '@shared/config';
import { TableConfigModel } from '@shared/models/table-config.model';
import { LaravelPageRequest } from '@app/shared/models/laravel-page-request.model';
import { HttpClient } from '@angular/common/http';
import { ModelAttributeOptionService } from './model-attribute-option.service';
import { ModelAttributeOptions, ServiceOptions } from '@app/shared/models/options';
import { LaravelPageResponse } from '@app/shared/models/laravel-page-response.model';
import { tap } from 'rxjs/operators';
import { ServiceModel } from '@app/shared/models/service.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LaravelTable } from '../laravel-table';
import { LayoutService } from '@shared/services/system/layout.service';
import { PurchaseOrderModel } from '@shared/models/purchase-order.model';
import { QuotationModel } from '@shared/models/quotation.model';
import { SaleModel } from '@shared/models/sale.model';
import { InvoiceModel } from '@shared/models/invoice.model';
import { ApprovalModel } from '@shared/models/approval.model';
import { BuildingModel } from '@shared/models/building.model';
import { ServiceVisitModel } from '@shared/models/service-visit.model';
import { AssetModel } from '@shared/models/asset.model';
import { GlobalSettingService } from '../system/global-setting.service';
import * as _ from 'lodash';
import { CompanyService } from './company.service';

export class ServiceDetail {
  service: ServiceModel;
  purchase_orders: PurchaseOrderModel[];
  quotations: QuotationModel[];
  relation_services: ServiceModel[];
  sales: SaleModel[];
  invoices: InvoiceModel[];
  uplifts: ApprovalModel[];
  buildings: BuildingModel[];
  visits: ServiceVisitModel[];
  assets: AssetModel[];
  papers: any[]
}

@Injectable({
  providedIn: 'root'
})
export class ServiceService implements LaravelTable {

  route_prefix = 'services';
  tableConfig: TableConfigModel = tableConfigs.service;
  public conditions: Conditions = {
    client_id: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Client'
    },
    building_id: {
      value: null,
      nullable: true,
      items: [],
      can_filter: true,
      filter_items: null,
      filter_keys: ['name'],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Building'
    },
    room_id: {
      value: null,
      nullable: true,
      items: [],
      can_filter: true,
      filter_items: null,
      filter_keys: ['name', 'code', 'description', 'floor'],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Room'
    },
    discipline: {
      value: null,
      nullable: true,
      items: [],
      can_filter: true,
      filter_items: null,
      filter_keys: ['option_value'],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Discipline'
    },
    status: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'Status'
    },
    severity: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_name',
      select_key: 'option_value',
      display_name: 'Priority'
    },
    service_type: {
      value: '',
      nullable: true,
      items: [],
      display_key: 'option_value',
      select_key: 'option_value',
      display_name: 'ServiceType'
    },
    supplier_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Contractor',
      can_filter: true,
      filter_items: null,
      filter_keys: ['name'],
    },
    engineer_id: {
      value: null,
      nullable: true,
      items: [],
      display_key: 'name',
      select_key: 'id',
      display_name: 'Engineer',
      can_filter: true
    },
    dashboard_type: {
      value: { item: '', group: '' },
      nullable: true,
      useGroup: true,
      groups: dashboardConfigs.service,
      group_key: 'dashboard_group',
      item_key: 'dashboard_type',
      display_name: 'Dashboard Type'
    },
    sort_by: {
      value: { item: null, group: null },
      nullable: true,
      useGroup: true,
      hidden: true,
      groups: [],
      group_key: 'sort_column',
      item_key: 'sort_type',
      display_name: 'Sort by'
    },
  };
  public items: ServiceModel[] = [];
  public paginator = {
    totalElements: 0,
    pageNumber: 0,
    size: 20,
  };
  public pageLoading = false;
  public serviceOptions: ServiceOptions;

  constructor(
    public http: HttpClient,
    public maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    private router: Router,
    private global: GlobalSettingService,
    private layout: LayoutService,
  ) {
    this.getOptions();
  }

  initPage() {
    if (!this.items.length) {
      this.loadPage().subscribe();
    }
  }

  onFilter(keyword) {
    this.loadPage({ keyword }).subscribe();
  }

  refresh() {
    this.loadPage().subscribe();
  }

  loadPage({ keyword }: any = { keyword: '' }) {
    this.pageLoading = true;
    let request = new LaravelPageRequest(this.paginator.size, this.paginator.pageNumber, this.conditions, keyword);
    return this.index(request)
      .pipe(
        tap(
          (response: LaravelPageResponse) => {
            this.paginator.size = response.per_page;
            this.paginator.pageNumber = response.current_page;
            this.paginator.totalElements = response.total;
            this.items = response.data;
          }
        ),
      )
      .finally(() => this.pageLoading = false);
  }

  clickDashboard({ group, item }) {
    this.changeConditionValue('dashboard_type', { item, group });
    this.layout.publishLayoutChange({ switchTable: 'table' });
    this.layout.onSwitchView.emit('table');

    this.refresh();
  }

  clickPage({ offset }) {
    this.paginator.pageNumber = offset + 1;
    this.loadPage().subscribe();
  }

  openEditPage({ id }) {
    this.router.navigate([`/service/edit/${id}`]);
  }

  openCreatePage() {
    this.router.navigate(['/service/create']);
  }

  index(params: LaravelPageRequest): Observable<LaravelPageResponse | any> {
    return this.http.post(this.route_prefix+'/read-list', params.request());
  }

  store(data) {
    return this.http.post(this.route_prefix, data);
  }

  show(id) {
    return this.http.get(this.route_prefix + '/' + id);
  }

  detail(id): Observable<ServiceDetail | any> {
    return this.http.get(`services/${id}/detail`);
  }

  destroy(id) {
    return this.http.delete(this.route_prefix + '/' + id);
  }

  update(id, data) {
    return this.http.put(this.route_prefix + '/' + id, data);
  }

  follow(id, follow) {
    return this.http.post(this.route_prefix + '/' + id + '/follow', { follow: follow });
  }

  changeConditionValue(key, value) {
    this.conditions[key].value = value;
  }

  getOptions() {
    this.maoService.all()
      .subscribe((data: ModelAttributeOptions) => {
        if (data['service']) {
          this.conditions.discipline.items = data.service.discipline;
          this.conditions.status.items = data.service.status;
          this.conditions.severity.items = data.service.severity.map((item) => {
            item.option_name = item.option_value;
            return item;
          });
          this.conditions.service_type.items = data.service.service_type;
          this.serviceOptions = data.service;
        }
      });
    this.companyService.getContractor().subscribe((data: any) => {
      this.conditions.supplier_id.items = data;
    });
    
    this.companyService.getClients().subscribe((data: any) => {
      this.conditions.client_id.items = data;
      const clientId = _.get(this.global, 'config.client', null);
      if (clientId) {
        this.conditions.client_id.value = clientId;
        this.buildingsByClient(this.global.config.client)
          .subscribe((data: any) => {
            this.conditions.building_id.items = data.filter((item) => {
              return item.status == 'active';
            });
          })
      } else {
        if (!this.conditions.client_id.value) {
          this.conditions.building_id.items = [];
          this.conditions.building_id.value = null;
        }
        console.log(this.conditions)
      }
    });
  }

  getDefaultBudget(clientId) {
    return this.http.get(`services/${clientId}/default-budget`);
  }

  buildingsByClient(clientId) {
    return this.http.get(`services/${clientId}/buildings`);
  }

  visits(service_id) {
    return this.http.get(`services/${service_id}/visits`);
  }

  invoices(service_id) {
    return this.http.get(`services/${service_id}/invoices`);
  }

  sales(service_id) {
    return this.http.get(`services/${service_id}/sales`);
  }

  filterServicesByBuilding(buildingId, keyword = '') {
    return this.http.get(`${this.route_prefix}/filter-services/building/${buildingId}`, { params: { keyword } });
  }

  engineers(serviceId) {
    return this.http.get(`services/${serviceId}/engineers`);
  }

  readSupplierEngineers(supplierCompanyIds) {
    return this.http.post(`service/get-supplier-engineers`, { supplier_company_ids: supplierCompanyIds });
  }

  cancel(serviceId) {
    return this.http.post(`services/${serviceId}/cancel`, null);
  }

  checkValid(serviceId, type = '') {
    return this.http.post(`services/${serviceId}/valid`, { type });
  }

  approveUplift(serviceId, upliftId, body) {
    return this.http.post(`services/${serviceId}/uplifts/${upliftId}/approve`, body);
  }

  declineUplift(serviceId, upliftId, body) {
    return this.http.post(`services/${serviceId}/uplifts/${upliftId}/decline`, body);
  }

  getActionLogs(serviceId) {
    return this.http.get(`services/action-log/${serviceId}`);
  }

  updateCompanyServiceRelation(relation) {
    return this.http.post(`services/company-service-relation`, relation);
  }

  export() {
    return this.http.post(`services/export`, {}, {responseType: 'blob'});
  }

  removeAsset(serviceId, assetId) {
    return this.http.post(`services/remove-selected-asset`, {service_id: serviceId, asset_id: assetId});
  }

  appendAsset(serviceId, assetId) {
    return this.http.post(`services/remove-append-asset`, {service_id: serviceId, asset_id: assetId});
  }
}
