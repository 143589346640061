import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {ModelAttributeOptionService} from '@shared/services/api/model-attribute-option.service';
import {
  BuildingOptions,
  ModelAttributeOptions
} from '@shared/models/options';
import {CompanyService} from '@shared/services/api/company.service';
import {BaseCreateComponent} from '@shared/components/base/base-create.component';
import {BuildingService} from '@shared/services/api/building.service';
import {tableConfigs} from '@shared/config';
import {InterfaceBaseCreate} from '@shared/interfaces/interface-base-create';
import {SimpleReuseStrategy} from '@app/simple-reuse-strategy';

@Component({
  selector: 'app-building-create',
  templateUrl: './building-create.component.html',
  styleUrls: ['./building-create.component.scss'],
  providers: [SimpleReuseStrategy]
})
export class BuildingCreateComponent extends BaseCreateComponent
  implements OnInit, InterfaceBaseCreate {
  tableConfig = tableConfigs.building;
  @Output() onSubmitted: EventEmitter<any> = new EventEmitter();
  public itemFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    code: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    town: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    country: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl(''),
    company_id: new FormControl(null),
    status: new FormControl(''),
    data: new FormGroup({
      trading_hours: new FormControl(null),
      site_manager: new FormControl(null),
      area_manager: new FormControl(null)
    })
  });

  public get clients() {
    return this.companyService.clients;
  }

  public buildingOption: BuildingOptions;

  constructor(
    private maoService: ModelAttributeOptionService,
    private companyService: CompanyService,
    public _service: BuildingService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getOptions();
  }

  getOptions() {
    this.maoService.all().subscribe((data: ModelAttributeOptions) => {
      this.buildingOption = data.building;
    });
  }

  submit() {
    let loader = this.loader.open();
    this._service
      .store({...this.itemFormGroup.value})
      .finally(() => loader.close())
      .subscribe(() => {
        this.onSubmitted.emit();
        this.toast.show('Building Added!');
        SimpleReuseStrategy.deleteRouteSnapshot('///settings/building/create');
      });
  }
}
