import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ApplicationService, KPIItem} from '@shared/services/api/application.service';
import {ServiceModel} from '@shared/models/service.model';
import {ServiceEditPopupComponent} from '@shared/components/business/service/service-edit/service-edit-popup.component';
import {NotifyService} from '@shared/services/notify.service';
import {AppConfirmService} from '@shared/services/system/app-confirm/app-confirm.service';
import {AppLoaderService} from '@shared/services/system/app-loader/app-loader.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-application-service-detail',
  templateUrl: './application-service-detail.component.html',
  styleUrls: ['./application-service-detail.component.scss']
})
export class ApplicationServiceDetailComponent implements OnInit {
  public serviceDetail: {
    budget: {
      start_time: string;
      end_time: string;
    },
    sales_total: number;
    kpi_total: number;
    in_period: boolean;
    service: ServiceModel,
    kpi: {
      attendance_sla: KPIItem;
      aims_app: KPIItem;
      compliance_certificates: KPIItem;
      emergency_job_with_2_hours_response_time: KPIItem;
      emergency_job_with_4_hours_response_time: KPIItem;
      first_time_completion: KPIItem;
      job_acceptance: KPIItem;
      multiple_visits: KPIItem
      no_use_app: KPIItem;
      ppm_service_sla: KPIItem;
      recall_and_warranty_services: KPIItem;
      service_sla: KPIItem;
    };
  };
  public formGroup = new FormGroup({
    recall: new FormControl(''),
    warranty: new FormControl(''),
    compliance: new FormControl(''),
    out_of_hours_call_out: new FormControl(''),
    holding_pool: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { service_id: number; contractor_id: number },
    public dialogRef: MatDialogRef<ApplicationServiceDetailComponent>,
    public applicationService: ApplicationService,
    private dialog: MatDialog,
    private toast: NotifyService,
    private confirm: AppConfirmService,
    private loader: AppLoaderService,
  ) {
    this.refresh();
  }

  ngOnInit() {
  }

  refresh() {
    let loader = this.loader.open();
    this.applicationService.getServiceKPI(this.data.service_id, this.data.contractor_id)
      .finally(() => loader.close())
      .subscribe((data: any) => {
        this.serviceDetail = data;
        this.formGroup.patchValue(this.serviceDetail.service.data.extra);
      });
  }

  openServiceEditPopup(serviceId) {
    this.dialog.open(ServiceEditPopupComponent, {
      width: '80%',
      data: {id: serviceId}
    });
  }
}

